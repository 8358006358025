























































import { defineComponent, ref, PropType, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper.vue'
import VacationsForm from './EmployeeVacationsForm.vue'
import {
  formatToIso,
  getStartAndEndOfCurrentYear,
} from '@/utils/date-time.utils'
import type { EmployeeVacationsBase } from '../../models/employee.model'
import { getEndOfTransferDate } from '../employee.utils'
import { EMPLOYEE_ACTION_CREATE_VACATION } from '../../store/actions/employee.actions.names'

type EmployeeVacationsAdd = Pick<
  EmployeeVacationsBase,
  'title' | 'planned_days' | 'transfer_remaining' | 'expires'
> & {
  starts_at: Date
  ends_at: Date
  end_of_transfer_period: Date | null
}

export default defineComponent({
  name: 'EmployeeVacationsAdd',
  components: {
    BaseModal,
    BaseCardModalForm,
    BottomSheetFormWrapper,
    VacationsForm,
  },
  trackedActions: {
    createVacation: EMPLOYEE_ACTION_CREATE_VACATION,
  },
  props: {
    userId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const [starts_at, ends_at] = getStartAndEndOfCurrentYear()
    let endOfTransferDateHolder = getEndOfTransferDate()

    const payload = ref<EmployeeVacationsAdd>({
      title: '',
      starts_at,
      ends_at,
      planned_days: 0,
      transfer_remaining: false,
      end_of_transfer_period: getEndOfTransferDate(),
      expires: true,
    })

    const disabledFields = computed(() => ({
      transfer_remaining: !payload.value.expires,
    }))

    const handleCreate = async () => {
      const requestPayload: EmployeeVacationsBase = {
        ...payload.value,
        starts_at: formatToIso(payload.value.starts_at),
        ends_at: formatToIso(payload.value.ends_at),
        end_of_transfer_period:
          payload.value.end_of_transfer_period &&
          formatToIso(payload.value.end_of_transfer_period),
      }
      if (
        requestPayload.expires === false ||
        requestPayload.transfer_remaining === false
      )
        requestPayload.end_of_transfer_period = null

      try {
        await store.dispatch(EMPLOYEE_ACTION_CREATE_VACATION, {
          userId: props.userId,
          vacation: requestPayload,
        })

        Snackbar.open(
          i18n.t('employee.employee_details.vacations.add.success_message')
        )
        emit('success')
        emit('close')
      } catch (error) {
        Snackbar.open({
          message: i18n.t(
            'employee.employee_details.vacations.add.error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    const handleInput = ($event: EmployeeVacationsAdd) => {
      if ($event.end_of_transfer_period)
        endOfTransferDateHolder = $event.end_of_transfer_period

      payload.value = {
        ...$event,
        transfer_remaining: $event.expires ? $event.transfer_remaining : false,
      }

      if (payload.value.expires === false)
        payload.value.end_of_transfer_period = null
      else if (payload.value.expires && $event.end_of_transfer_period === null)
        payload.value.end_of_transfer_period = endOfTransferDateHolder
    }

    return { payload, disabledFields, handleCreate, handleInput }
  },
})
