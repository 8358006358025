<template>
  <div ref="actionsContent" class="h-full flex items-center justify-end">
    <base-group-selection-toolbar-action-item
      :tooltip="$t('employee.index.body.show_employee')"
      tooltip-position="is-left"
    >
      <base-group-selection-toolbar-action-button
        :data-id="`employee.table.body.show_employee.${userId}`"
        type="is-filled-grey"
        class="my-1.5 hover:text-coal-850"
        icon="eye"
        role="button"
        :aria-label="$t('employee.index.body.show_employee')"
        @click="goToEmployeeDetail()"
      />
    </base-group-selection-toolbar-action-item>

    <base-group-selection-toolbar-action-item
      v-if="canUpdate"
      :tooltip="$t('employee.index.body.edit_employee')"
      tooltip-position="is-top"
    >
      <base-group-selection-toolbar-action-button
        :data-id="`employee.table.body.edit_employee.${userId}`"
        type="is-filled-grey"
        class="my-1.5 hover:text-coal-850"
        icon="settings"
        role="button"
        :aria-label="$t('employee.index.body.edit_employee')"
        @click="$emit('edit-action-click')"
      />
    </base-group-selection-toolbar-action-item>
  </div>
</template>

<script>
import { MODULE_NAME } from '../../../employee.module'
import BaseGroupSelectionToolbarActionItem from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionItem'
import BaseGroupSelectionToolbarActionButton from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionButton'

export default {
  name: 'TableRowCellActions',
  components: {
    BaseGroupSelectionToolbarActionItem,
    BaseGroupSelectionToolbarActionButton,
  },
  props: {
    canUpdate: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToEmployeeDetail() {
      this.$router.push({
        name: `${MODULE_NAME}.details`,
        params: { userId: this.userId },
      })
    },
  },
}
</script>
