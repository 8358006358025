import { MODULE_NAME } from '../../employee.module'

export const EMPLOYEE_MUTATION_SET_RECORDS = `${MODULE_NAME}/MUTATION_SET_RECORDS`
export const EMPLOYEE_MUTATION_APPEND_RECORDS = `${MODULE_NAME}/MUTATION_APPEND_RECORDS`
export const EMPLOYEE_MUTATION_ADD_INCLUDED = `${MODULE_NAME}/MUTATION_ADD_INCLUDED`
export const EMPLOYEE_MUTATION_SET_META = `${MODULE_NAME}/MUTATION_SET_META`
export const EMPLOYEE_MUTATION_INCREMENT_PAGE = `${MODULE_NAME}/MUTATION_INCREMENT_PAGE`
export const EMPLOYEE_MUTATION_RESET_PAGE = `${MODULE_NAME}/MUTATION_RESET_PAGE`
export const EMPLOYEE_MUTATION_REPLACE_RECORD = `${MODULE_NAME}/MUTATION_REPLACE_RECORD`
export const EMPLOYEE_MUTATION_CLEAR_RECORDS = `${MODULE_NAME}/MUTATION_CLEAR_RECORDS`
export const EMPLOYEE_MUTATION_SET_SORTING_FIELD = `${MODULE_NAME}/MUTATION_SET_SORTING_FIELD`
export const EMPLOYEE_MUTATION_ADD_FILTER = `${MODULE_NAME}/MUTATION_ADD_FILTER`
export const EMPLOYEE_MUTATION_REMOVE_FILTER = `${MODULE_NAME}/MUTATION_REMOVE_FILTER`
export const EMPLOYEE_MUTATION_RESET_FILTERS = `${MODULE_NAME}/MUTATION_RESET_FILTERS`
export const EMPLOYEE_MUTATION_ATTACH_RELATIONSHIPS = `${MODULE_NAME}/MUTATION_ATTACH_RELATIONSHIPS`
export const EMPLOYEE_MUTATION_DETACH_RELATIONSHIPS = `${MODULE_NAME}/MUTATION_DETACH_RELATIONSHIPS`

export const EMPLOYEE_TARGET_HOURS_MUTATION_ADD_FILTER = `${MODULE_NAME}/TARGET_HOURS_MUTATION_ADD_FILTER`
export const EMPLOYEE_TARGET_HOURS_MUTATION_REMOVE_FILTER = `${MODULE_NAME}/TARGET_HOURS_MUTATION_REMOVE_FILTER`
export const EMPLOYEE_MUTATION_SET_TARGET_HOURS_META = `${MODULE_NAME}/TARGET_HOURS_MUTATION_SET_META`
export const EMPLOYEE_MUTATION_INCREMENT_TARGET_HOURS_PAGE = `${MODULE_NAME}/TARGET_HOURS_MUTATION_INCREMENT_PAGE`
export const EMPLOYEE_MUTATION_RESET_TARGET_HOURS_PAGE = `${MODULE_NAME}/TARGET_HOURS_MUTATION_RESET_PAGE`
export const EMPLOYEE_MUTATION_SET_CURRENT_TAB = `${MODULE_NAME}/MUTATION_SET_CURRENT_TAB`

export const EMPLOYEE_RECORDS_MUTATION_SET_SORTING_FIELD = `${MODULE_NAME}/RECORDS_MUTATION_SET_SORTING_FIELD`
export const EMPLOYEE_RECORDS_MUTATION_SET_META = `${MODULE_NAME}/RECORDS_MUTATION_SET_META`
export const EMPLOYEE_RECORDS_MUTATION_INCREMENT_PAGE = `${MODULE_NAME}/RECORDS_MUTATION_INCREMENT_PAGE`
export const EMPLOYEE_RECORDS_MUTATION_RESET_PAGE = `${MODULE_NAME}/RECORDS_MUTATION_RESET_PAGE`
export const EMPLOYEE_RECORDS_MUTATION_SET_DATE_RANGE = `${MODULE_NAME}/RECORDS_MUTATION_SET_DATE_RANGE`
export const EMPLOYEE_RECORDS_MUTATION_SWITCH_TIME_FORMAT = `${MODULE_NAME}/RECORDS_MUTATION_SWITCH_TIME_FORMAT`

export const EMPLOYEE_MUTATION_SET_VACATIONS_META = `${MODULE_NAME}/VACATIONS_MUTATION_SET_META`
export const EMPLOYEE_MUTATION_INCREMENT_VACATIONS_PAGE = `${MODULE_NAME}/VACATIONS_MUTATION_INCREMENT_PAGE`
export const EMPLOYEE_MUTATION_RESET_VACATIONS_PAGE = `${MODULE_NAME}/VACATIONS_MUTATION_RESET_PAGE`
