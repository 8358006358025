<template>
  <div>
    <template v-if="totalCount">
      <invite-info :total-count="totalCount" :with-email="true" />

      <treeselect
        ref="content"
        v-model="passedValue"
        class="max-h-64 overflow-y-auto hidden-scrollbar treeselect-wrapper"
        classes-item="text-lg md:text-base py-4 md:py-3 border-t md:border-0 border-coal-150"
        field="username"
        :size-checkbox="$isSmallDevice ? 'is-medium' : 'is-small'"
        :items="items"
        data-id-prefix="employee.invite_modal"
      >
        <template v-slot:label="{ item }">
          {{ item.username }}
          <span v-if="item.email" class="pl-1 text-coal-550">
            ({{ item.email }})
          </span>
        </template>
      </treeselect>
    </template>

    <p v-else class="text-center text-coal-450 p-3 mb-6">
      {{ $t('employee.invite_modal.no_items_with_email') }}
    </p>

    <slot />

    <invite-link
      class="py-6 border-t border-gray-300"
      @text-click="$emit('show-no-email-list')"
      @link-click="$emit('copy-invitation')"
    />
  </div>
</template>

<script>
import { isEndOfScroll } from '@/utils/helpers'
import Treeselect from '@/app/util-modules/ui/treeselect/Treeselect'
import InviteInfo from './EmployeeInviteInfo.vue'
import InviteLink from './EmployeeInviteLink.vue'

export default {
  name: 'EmployeeInviteForm',
  components: {
    Treeselect,
    InviteInfo,
    InviteLink,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
  },
  mounted() {
    if (this.$listeners['scroll-end'] && this.totalCount) {
      this.$refs.content.$el.addEventListener('scroll', this.notifyScrollEnd)
    }
  },
  beforeDestroy() {
    if (this.$listeners['scroll-end'] && this.totalCount) {
      this.$refs.content.$el.removeEventListener('scroll', this.notifyScrollEnd)
    }
  },
  methods: {
    notifyScrollEnd(e) {
      if (isEndOfScroll(e.target)) {
        this.$emit('scroll-end')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .treeselect-wrapper {
  min-height: 10rem;

  .list > .item > .item {
    @apply border-t-0;
  }
}
</style>
