









































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper.vue'
import VacationsForm from './EmployeeVacationsForm.vue'
import VacationsDelete from './EmployeeVacationsDelete.vue'
import { formatToIso } from '@/utils/date-time.utils'
import type { EmployeeVacations } from '../../models/employee.model'
import { getEndOfTransferDate } from '../employee.utils'
import {
  EMPLOYEE_ACTION_GET_VACATION,
  EMPLOYEE_ACTION_UPDATE_VACATION,
} from '../../store/actions/employee.actions.names'
import { EMPLOYEE_VACATIONS_GETTER_IS_TRANSFERRED } from '../../store/getters/employee.getters.names'

type EmployeeVacationsPayload = Omit<
  EmployeeVacations,
  'starts_at' | 'ends_at' | 'end_of_transfer_period'
> & {
  starts_at: Date
  ends_at: Date
  end_of_transfer_period: Date | null
}

export default defineComponent({
  name: 'EmployeeVacationsEdit',
  components: {
    BaseModal,
    BaseCardModalForm,
    BottomSheetFormWrapper,
    VacationsForm,
    VacationsDelete,
  },
  props: {
    userId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
    vacationId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
  },
  trackedActions: {
    updateVacation: EMPLOYEE_ACTION_UPDATE_VACATION,
    loadVacation: EMPLOYEE_ACTION_GET_VACATION,
  },
  setup(props, { emit }) {
    const isDeleteEmployeeConfirmOpen = ref(false)
    let endOfTransferDateHolder = getEndOfTransferDate()
    const payload = ref<EmployeeVacationsPayload>({
      end_of_transfer_period: null,
      ends_at: new Date(),
      starts_at: new Date(),
      expires: true,
      planned_days: 0,
      remaining_days: 0,
      spent_days: 0,
      status: 'used',
      title: '',
      transfer_remaining: false,
      transferred_days: 0,
      transferred_to: null,
    })

    const handleEdit = async () => {
      let requestPayload: EmployeeVacations = {
        ...payload.value,
        starts_at: formatToIso(payload.value.starts_at),
        ends_at: formatToIso(payload.value.ends_at),
        end_of_transfer_period:
          payload.value.end_of_transfer_period &&
          formatToIso(payload.value.end_of_transfer_period),
      }
      if (
        requestPayload.expires === false ||
        requestPayload.transfer_remaining === false
      )
        requestPayload.end_of_transfer_period = null

      try {
        await store.dispatch(EMPLOYEE_ACTION_UPDATE_VACATION, {
          userId: props.userId,
          vacation: requestPayload,
        })
        Snackbar.open(
          i18n.t('employee.employee_details.vacations.edit.success_message')
        )
        emit('success')
        emit('close')
      } catch (error) {
        Snackbar.open({
          message: i18n.t(
            'employee.employee_details.vacations.edit.error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    const loadVacation = () => {
      store
        .dispatch(EMPLOYEE_ACTION_GET_VACATION, {
          userId: props.userId,
          vacationId: props.vacationId,
        })
        .then((response) => {
          let endOfTransfer: Date | null = response.end_of_transfer_period
            ? new Date(response.end_of_transfer_period)
            : getEndOfTransferDate()

          if (response.expires === false) endOfTransfer = null

          payload.value = {
            ...response,
            starts_at: new Date(response.starts_at),
            ends_at: new Date(response.ends_at),
            end_of_transfer_period: endOfTransfer,
          }
        })
    }

    loadVacation()

    const handleInput = ({ expires, ...$event }: EmployeeVacationsPayload) => {
      if ($event.end_of_transfer_period)
        endOfTransferDateHolder = $event.end_of_transfer_period

      payload.value = {
        ...$event,
        expires,
        transfer_remaining: expires ? $event.transfer_remaining : false,
      }

      payload.value.end_of_transfer_period = payload.value.expires
        ? $event.end_of_transfer_period
        : null

      if (payload.value.expires && $event.end_of_transfer_period === null)
        payload.value.end_of_transfer_period = endOfTransferDateHolder
    }

    // Original vacation eg. 'Annual Leave' that the remaining_days was
    // transferred to a new vacation input
    const wasTransferredVacation = computed(
      () => payload.value.transferred_days > 0
    )
    // Transferred from another vacation eg. 'Transfer Annual Leave'
    const isTransferredVacation = computed(() =>
      store.getters[EMPLOYEE_VACATIONS_GETTER_IS_TRANSFERRED](payload.value.id)
    )

    const disabledFields = computed(() => ({
      starts_at: payload.value.spent_days > 0 || wasTransferredVacation.value,
      ends_at: payload.value.spent_days > 0 || wasTransferredVacation.value,
      planned_days: isTransferredVacation.value,
      expires: isTransferredVacation.value || wasTransferredVacation.value,
      end_of_transfer_period: isTransferredVacation.value,
      transfer_remaining:
        isTransferredVacation.value ||
        wasTransferredVacation.value ||
        !payload.value.expires,
    }))

    const handleSelection = async (option: any) => {
      if (option.id === 'delete') isDeleteEmployeeConfirmOpen.value = true
    }

    const moreOptions = computed(() => {
      if (payload.value.spent_days > 0 || wasTransferredVacation.value)
        return []
      else
        return [
          {
            id: 'delete',
            label: i18n.t('employee.edit_form.option_label_delete'),
            icon: 'delete/delete-16',
            classes: 'text-error-400',
          },
        ]
    })

    const handleSuccessDelete = () => {
      isDeleteEmployeeConfirmOpen.value = false
      emit('success')
      emit('close')
    }

    const hideRemainingDaysGroup = computed(() => {
      return (
        isTransferredVacation.value ||
        (payload.value.status === `doesn't expire` && !payload.value.expires)
      )
    })

    return {
      isDeleteEmployeeConfirmOpen,
      payload,
      disabledFields,
      moreOptions,
      hideRemainingDaysGroup,
      handleSelection,
      handleEdit,
      handleInput,
      handleSuccessDelete,
    }
  },
})
