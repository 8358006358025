var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bottom-sheet-form-wrapper',{attrs:{"header-right-actions":[
    {
      title: _vm.submitButtonText,
      event: 'submit',
      loading: _vm.actionStatus.isLoading,
      class: 'justify-self-end text-green',
    } ],"header-title":_vm.title,"header-more-options":_vm.moreOptions,"action-status":_vm.actionStatus,"validation-messages-class":"mx-4 mt-4 mb-2"},on:{"handle-selection":function($event){return _vm.$emit('selected', $event)},"close":function($event){return _vm.$emit('close')},"done":function($event){return _vm.$emit('submit')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var handleInputChange = ref.handleInputChange;
return [_c('form-body',{attrs:{"value":_vm.payload,"preload-action-status":_vm.preloadActionStatus,"edit-mode":_vm.editMode},on:{"input":function($event){return _vm.$emit('input', $event)},"updated":function($event){return handleInputChange(true)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }