import { computed, ComputedRef } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import useDeviceSize from '@/utils/composables/use-ui-size'
import { EmployeeRecords } from '../../models/employee.model'
import type { BaseTableColumn } from '@/app/util-modules/ui/table/BaseTable.types'
import { MODULE_NAME } from '../../employee.module'
import TableRowCellDate from '../../components/employee-records/table-row/TableRowCellDate.vue'
import TableRowCellDefault from '../../components/employee-records/table-row/TableRowCellDefault.vue'
import BaseTableHeaderCell from '@/app/util-modules/ui/table/BaseTableHeaderCell.vue'
import EmployeeRecordsTableHeader from '../../components/employee-records/EmployeeRecordsTableHeader.vue'
import TableRowCellActions from '../../components/employee-records/table-row/TableRowCellActions.vue'
import { DATE_FORMAT, formatDate } from '@/utils/date-time.utils'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import {
  getFormattedTime,
  getFormattedCompensationTime,
} from '../../utils/employee.utils'

interface ActionHandler {
  handler: (actionName: string, params?: any) => void // eslint-disable-line no-unused-vars
}

interface RecordTableColumn extends BaseTableColumn<EmployeeRecords> {
  size: 'small' | 'medium'
}

const { $isSmallDevice } = useDeviceSize()

export default function useRecordsTableColumns() {
  const action: ActionHandler = {
    handler: () => {},
  }

  const canEditRecord = computed(
    () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
  )

  const columns = getColumns(action, canEditRecord.value)

  return {
    columns,
    onAction: (callback: ActionHandler['handler']) =>
      (action.handler = callback),
  }
}

const smallDeviceCellsWidth = { small: '120px', medium: '180px' }
const withActionsCellsWidth = computed(() => {
  if ($isSmallDevice.value) return smallDeviceCellsWidth
  else return { small: '10%', medium: '15%' }
})
const withoutActionsCellsWidth = computed(() => {
  if ($isSmallDevice.value) return smallDeviceCellsWidth
  else return { small: '10%', medium: '15%' }
})
const getColWidth = (colsLength: number, size: RecordTableColumn['size']) =>
  colsLength === 9
    ? withActionsCellsWidth.value[size]
    : withoutActionsCellsWidth.value[size]

function getColumns(
  action: ActionHandler,
  canEditRecord: boolean
): ComputedRef<RecordTableColumn[]> {
  return computed(() => {
    const cols: RecordTableColumn[] = [
      dateColumn(action),
      targetColumn(),
      planColumn(),
      actualColumn(),
      absentColumn(),
      differenceColumn(),
      compensationColumn(),
      currentBalanceColumn(canEditRecord),
      actionsColumn(action, canEditRecord),
    ]

    const allVisibleColumns = cols.filter((c) => !c.hidden)

    const allColumns = allVisibleColumns.map((c, index) => {
      if (index !== 0) c.footerClass = 'flex justify-end'
      if (c.size) c.width = getColWidth(allVisibleColumns.length, c.size)

      return c
    })

    return allColumns
  })
}

function dateColumn(action: ActionHandler): RecordTableColumn {
  return {
    smallDevice: true,
    size: 'medium',
    header: {
      element: BaseTableHeaderCell,
      class: 'md:pr-4 pl-6 pt-1 font-bold items-start',
      props: {
        value: () => i18n.t('employee.employee_details.records.row_cells.date'),
        dataIdAttr: 'employee.employee_details.records.row_cells.date',
        sorting: () => store.state[MODULE_NAME].recordsSorting.day,
      },
      events: {
        sort: (direction: number) =>
          action.handler('sort', { field: 'day', direction }),
      },
    },
    cell: {
      element: TableRowCellDate,
      class:
        'text-coal pr-2 pl-6 md:pr-4 font-medium md:text-base text-lg whitespace-nowrap',
      props: {
        value: (row: EmployeeRecords) =>
          formatDate(new Date(row.day), DATE_FORMAT, true),
        isPublicHoliday: (row: EmployeeRecords) => row.public_holiday,
      },
    },
    footer: () => {
      const dateRange = store.state[MODULE_NAME].recordsFilterDateRange
      if (!dateRange)
        return i18n.t(
          'employee.employee_details.records.row_footer.date_all_time'
        )

      const date1 = new Date(dateRange[0])
      const date2 = new Date(dateRange[1])
      return `${formatDate(date1, 'dd.MM', true)} - ${formatDate(
        date2,
        'dd.MM.yy',
        true
      )}`
    },
  }
}

function targetColumn(): RecordTableColumn {
  return {
    size: 'small',
    smallDevice: true,
    header: {
      element: EmployeeRecordsTableHeader,
      props: {
        value: () =>
          i18n.t('employee.employee_details.records.row_cells.target'),
        subText: () => i18n.t('employee.employee_details.records.daily'),
        tooltipLabel: () =>
          i18n.t('employee.employee_details.records.records_tooltip.target'),
        dataIdAttr: 'employee.employee_details.records.row_cells.target',
      },
    },
    cell: {
      element: TableRowCellDefault,
      props: {
        value: (row: EmployeeRecords) => getFormattedTime(row.target),
      },
    },
    footer: () =>
      getFormattedTime(store.state[MODULE_NAME].recordsMeta.total_target),
  }
}

function planColumn(): RecordTableColumn {
  return {
    size: 'small',
    smallDevice: true,
    header: {
      element: EmployeeRecordsTableHeader,
      props: {
        value: () => i18n.t('employee.employee_details.records.row_cells.plan'),
        subText: () => i18n.t('employee.employee_details.records.daily'),
        tooltipLabel: () =>
          i18n.t('employee.employee_details.records.records_tooltip.plan'),
        dataIdAttr: 'employee.employee_details.records.row_cells.plan',
      },
    },
    cell: {
      element: TableRowCellDefault,
      props: {
        value: (row: EmployeeRecords) => getFormattedTime(row.plan),
      },
    },
    footer: () =>
      getFormattedTime(store.state[MODULE_NAME].recordsMeta.total_plan),
  }
}

function actualColumn(): RecordTableColumn {
  return {
    size: 'small',
    smallDevice: true,
    header: {
      element: EmployeeRecordsTableHeader,
      props: {
        value: () =>
          i18n.t('employee.employee_details.records.row_cells.actual'),
        subText: () => i18n.t('employee.employee_details.records.daily'),
        tooltipLabel: () =>
          i18n.t('employee.employee_details.records.records_tooltip.actual'),
        dataIdAttr: 'employee.employee_details.records.row_cells.plan',
      },
    },
    cell: {
      element: TableRowCellDefault,
      props: {
        value: (row: EmployeeRecords) => getFormattedTime(row.actual),
      },
    },
    footer: () =>
      getFormattedTime(store.state[MODULE_NAME].recordsMeta.total_actual),
  }
}

function absentColumn(): RecordTableColumn {
  return {
    size: 'small',
    smallDevice: true,
    header: {
      element: EmployeeRecordsTableHeader,
      props: {
        value: () =>
          i18n.t('employee.employee_details.records.row_cells.absent'),
        subText: () => i18n.t('employee.employee_details.records.daily'),
        tooltipLabel: () =>
          i18n.t('employee.employee_details.records.records_tooltip.absent'),
        dataIdAttr: 'employee.employee_details.records.row_cells.absent',
      },
    },
    cell: {
      element: TableRowCellDefault,
      props: {
        value: (row: EmployeeRecords) => getFormattedTime(row.absent),
        tooltipLabel: (row: EmployeeRecords) => row?.vacation_title,
      },
    },
    footer: () =>
      getFormattedTime(store.state[MODULE_NAME].recordsMeta.total_absent),
  }
}

function differenceColumn(): RecordTableColumn {
  return {
    size: 'medium',
    smallDevice: true,
    header: {
      element: EmployeeRecordsTableHeader,
      class: 'pr-2',
      props: {
        value: () =>
          i18n.t('employee.employee_details.records.row_cells.difference'),
        subText: () => i18n.t('employee.employee_details.records.daily'),
        tooltipLabel: () =>
          i18n.t(
            'employee.employee_details.records.records_tooltip.difference'
          ),
        dataIdAttr: 'employee.employee_details.records.row_cells.difference',
      },
    },
    cell: {
      element: TableRowCellDefault,
      class: 'pr-2',
      props: {
        value: (row: EmployeeRecords) => getFormattedTime(row.difference, true),
      },
    },
    footer: () =>
      getFormattedTime(
        store.state[MODULE_NAME].recordsMeta.total_difference,
        true
      ),
  }
}

function compensationColumn(): RecordTableColumn {
  return {
    size: 'medium',
    smallDevice: true,
    header: {
      element: EmployeeRecordsTableHeader,
      class: 'pr-2',
      props: {
        value: () =>
          i18n.t('employee.employee_details.records.row_cells.compensation'),
        subText: () => i18n.t('employee.employee_details.records.daily'),
        tooltipLabel: () =>
          i18n.t(
            'employee.employee_details.records.records_tooltip.compensation'
          ),
        dataIdAttr: 'employee.employee_details.records.row_cells.compensation',
      },
    },
    cell: {
      element: TableRowCellDefault,
      props: {
        overrideClass: 'truncate w-full text-right pr-2',
        value: (row: EmployeeRecords) =>
          getFormattedCompensationTime(row.compensation),
      },
    },
    footer: () =>
      getFormattedTime(store.state[MODULE_NAME].recordsMeta.total_compensation),
  }
}

function currentBalanceColumn(canEditRecord: boolean): RecordTableColumn {
  return {
    smallDevice: true,
    size: 'medium',
    header: {
      class: `${canEditRecord ? '' : 'pr-6'}`,
      element: EmployeeRecordsTableHeader,
      props: {
        value: () =>
          i18n.t('employee.employee_details.records.row_cells.current_balance'),
        subText: () => i18n.t('employee.employee_details.records.consolidated'),
        tooltipLabel: () =>
          i18n.t(
            'employee.employee_details.records.records_tooltip.current_balance'
          ),
        dataIdAttr:
          'employee.employee_details.records.row_cells.current_balance',
      },
    },
    cell: {
      element: TableRowCellDefault,
      props: {
        overrideClass: `truncate font-bold text-right ${
          canEditRecord ? '' : 'pr-6'
        }`,
        value: (row: EmployeeRecords) =>
          getFormattedTime(row.current_balance, true),
      },
    },
  }
}
function actionsColumn(
  action: ActionHandler,
  canEditRecord: boolean
): RecordTableColumn {
  return {
    smallDevice: false,
    hidden: !canEditRecord,
    size: 'medium',
    header: {
      element: EmployeeRecordsTableHeader,
      class: 'pr-6 pt-1',
      props: {
        value: () =>
          i18n.t('employee.employee_details.records.row_cells.actions'),
        dataIdAttr: 'employee.employee_details.records.row_cells.actions',
      },
    },
    cell: {
      element: TableRowCellActions,
      class: 'pr-4',
      events: {
        'edit-action-click': (row) => action.handler('edit', { id: row.id }),
        'notes-action-click': (row) => action.handler('notes', { id: row.id }),
      },
    },
  }
}
