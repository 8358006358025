







































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  nextTick,
} from '@vue/composition-api'
import debounce from 'lodash/debounce'
import { isElementInViewport } from '@/utils/helpers'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import ListCircleColor from '@/app/util-modules/ui/list-circle-color/ListCircleColor.vue'
import useDeviceSize from '@/utils/composables/use-ui-size'

export default defineComponent({
  name: 'EmployeeListTooltipList',
  components: { BaseTooltip, BaseBadge, ListCircleColor },

  props: {
    value: {
      type: Array,
      required: true,
    },
    tableView: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { refs }) {
    const showTooltip = ref(false)
    const position = ref('is-top')
    const isTouchScreen = ref(false)

    const debouncedMouseLeaveFunc = ref<ReturnType<typeof debounce> | null>(
      null
    )

    const initialShownItems = ref(0)

    const { $isLargeDevice } = useDeviceSize()

    const moreThanOneItem = computed(() => props.value.length > 1)

    const getTextWidth = (text: string, font: string) => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      if (!context) return 0
      context.font = font
      const metrics = context.measureText(text)
      return metrics.width
    }

    const shownItems = computed(() => {
      if (!props.tableView) {
        return props.value.slice(0, initialShownItems.value)
      }
      return props.value.slice(0, 1)
    })

    const hiddenItems = computed(() => {
      if (!props.tableView) {
        return moreThanOneItem.value
          ? props.value.slice(initialShownItems.value)
          : []
      }
      return moreThanOneItem.value ? props.value.slice(1) : []
    })

    const hiddenItemsCount = computed(() =>
      moreThanOneItem.value && hiddenItems.value.length > 0
        ? `+${hiddenItems.value.length}`
        : ''
    )

    const tooltipTriggers = computed(() =>
      isTouchScreen.value ? ['click'] : ['hover']
    )

    const clickHandler = (showTooltip: boolean) => {
      if (isTouchScreen.value) toggleTooltip(showTooltip)
    }

    const onMouseOverHandler = () => {
      if (debouncedMouseLeaveFunc.value) {
        debouncedMouseLeaveFunc.value.cancel()
      }

      if (!showTooltip.value || !isTouchScreen.value) {
        toggleTooltip(true)
      }
    }

    const onMouseLeaveHandler = () => {
      debouncedMouseLeaveFunc.value = debounce(function () {
        toggleTooltip(false)
      }, 150)

      debouncedMouseLeaveFunc.value()
    }

    const toggleTooltip = (displayTooltip: boolean) => {
      if (showTooltip.value === displayTooltip) return
      showTooltip.value = displayTooltip

      nextTick(() => {
        if (showTooltip.value === true) {
          const tooltipRef = refs.tooltip as HTMLElement
          if (tooltipRef) {
            position.value = isElementInViewport(tooltipRef.parentNode)
              ? 'is-top'
              : 'is-bottom'
          }
        } else position.value = 'is-top'
      })
    }

    const shortenText = (text: string) =>
      text.length < 30 ? text : `${text.substring(0, 30)}...`

    const handleResize = () => {
      const containerWidth = $isLargeDevice.value
        ? document.documentElement.offsetWidth * 0.6
        : document.documentElement.offsetWidth * 0.4

      let totalWidth = 0
      let itemIndex = 0

      for (let i = 0; i < props.value.length; i++) {
        const text = shortenText((props.value as { label: string }[])[i].label)
        const textWidth = getTextWidth(text, '12px sans-serif')
        totalWidth += textWidth
        if (totalWidth <= containerWidth) {
          itemIndex = i + 1
        } else {
          break
        }
      }

      initialShownItems.value = itemIndex
    }

    onMounted(() => {
      if (!props.tableView) {
        handleResize()
        window.addEventListener('resize', handleResize)
      }

      if ('ontouchstart' in document.documentElement) {
        isTouchScreen.value = true
      }
    })

    onBeforeUnmount(() => {
      if (!props.tableView) window.removeEventListener('resize', handleResize)
      if (debouncedMouseLeaveFunc.value) {
        debouncedMouseLeaveFunc.value.cancel()
      }
    })

    return {
      showTooltip,
      position,
      isTouchScreen,
      shownItems,
      hiddenItems,
      hiddenItemsCount,
      tooltipTriggers,
      clickHandler,
      onMouseOverHandler,
      onMouseLeaveHandler,
      shortenText,
      debouncedMouseLeaveFunc,
      initialShownItems,
      BaseTooltip,
    }
  },
})
