import state, { makeEmptyState } from './employee.state'
import getters from './getters/employee.getters'
import actions from './actions/employee.actions'
import mutations from './mutations/employee.mutations'
import { subscribeActions } from './employee.action-subscriber'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

subscribeActions()

export { makeEmptyState }
