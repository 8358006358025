




































import { defineComponent } from '@vue/composition-api'
import BaseCardModal from '@/app/util-modules/ui/card-modal/BaseCardModal.vue'
import BaseFormValidation from '@/app/util-modules/ui/form/BaseFormValidation.vue'
import BaseFormValidationMessages from '@/app/util-modules/ui/form/BaseFormValidationMessages.vue'

export default defineComponent({
  name: 'DataSectionCardModalForm',
  components: {
    BaseCardModal,
    BaseFormValidation,
    BaseFormValidationMessages,
  },
  inheritAttrs: false,
  props: {
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    hideScrollbar: {
      type: Boolean,
      default: false,
    },
  },

  setup(_props, { emit }) {
    const validate = async (handleSubmit: () => Promise<void>) => {
      try {
        await handleSubmit()
        emit('submit')
      } catch (e) {} /* eslint-disable-line no-empty */
    }

    return {
      validate,
    }
  },
})
