




























import { defineComponent, computed, ref } from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n/index'
import store from '@/store'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownItem from '@/app/util-modules/ui/dropdown/BaseDropdownItem.vue'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import { USER_GETTER_FEATURE_PERMISSIONS } from '@/app/core/user/store/getters/user.getters.names'

export default defineComponent({
  name: 'EmployeeTargetHoursRhythm',
  components: {
    BaseDropdownItem,
    BaseDropdown,
    BaseInputField,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const options = ref([
      {
        label: i18n.t('employee.target_hours.form.weekly'),
        value: 'weekly',
        permission: true,
      },
      {
        label: i18n.t('employee.target_hours.form.monthly'),
        value: 'monthly',
        permission: true,
      },
      {
        label: i18n.t('employee.target_hours.form.custom'),
        value: 'custom',
        permission:
          store.getters[USER_GETTER_FEATURE_PERMISSIONS]('TargetHour')
            .create_custom_target_hours,
      },
    ])

    const permittedOptions = computed(() => {
      return options.value.filter((option) => option.permission)
    })

    const selectedLabel = computed(() => {
      return (
        options.value.find((option) => option.value === props.value)?.label ||
        ''
      )
    })

    return {
      options,
      permittedOptions,
      selectedLabel,
    }
  },
})
