<template>
  <base-field :name="name" :label="label" v-bind="$attrs">
    <base-date-picker
      v-model="date"
      icon-right="calendar/calendar-32"
      append-to-body
      data-id-prefix="employee_target_hours_date"
      :editable="true"
    />
  </base-field>
</template>

<script>
import { formatToIso } from '@/utils/date-time.utils'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseDatePicker from '@/app/util-modules/ui/date-picker/BaseDatePicker.vue'

export default {
  name: 'EmployeeTargetHoursDate',
  components: {
    BaseField,
    BaseDatePicker,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    date: {
      get() {
        if (this.value) {
          return new Date(this.value)
        }
        return null
      },
      set(val) {
        this.$emit('input', formatToIso(val))
      },
    },
  },
}
</script>
