



































































import { defineComponent, ref } from '@vue/composition-api'
import store from '@/store'
import BaseUpload from '@/app/util-modules/ui/upload/BaseUpload.vue'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'
import UpdateModal from './EmployeeAvatarUpdateModal.vue'
import UpdateBottomSheet from './EmployeeAvatarUpdateBottomSheet.vue'
import {
  EMPLOYEE_ACTION_UPDATE_EMPLOYEE_AVATAR,
  EMPLOYEE_ACTION_DELETE_EMPLOYEE_AVATAR,
} from '../../../store/actions/employee.actions.names'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import i18n from '@/utils/vendors/i18n/index'

export default defineComponent({
  name: 'EmployeeAvatarAddModal',
  components: {
    BaseUpload,
    BaseDialog,
    UpdateModal,
    UpdateBottomSheet,
  },
  props: {
    employeeId: {
      type: String,
      required: true,
    },
    isRemoveButtonHidden: {
      type: Boolean,
      default: false,
    },
  },
  trackedActions: {
    updateAvatar: EMPLOYEE_ACTION_UPDATE_EMPLOYEE_AVATAR,
    removeAvatar: EMPLOYEE_ACTION_DELETE_EMPLOYEE_AVATAR,
  },
  setup(props, { emit }) {
    const newPhoto = ref<File | null>(null)
    const isRemoveAvatarModalOpen = ref(false)

    const handleUpdate = async () => {
      if (!newPhoto.value) return

      try {
        await store.dispatch(EMPLOYEE_ACTION_UPDATE_EMPLOYEE_AVATAR, {
          employeeId: props.employeeId,
          newPhotoFile: newPhoto.value,
        })

        Snackbar.open(
          i18n.t(
            'employee.employee_details.general.upload_avatar_success_message'
          )
        )

        emit('updated')
        emit('close')
      } catch (err) {
        Snackbar.open({
          message: i18n.t(
            'employee.employee_details.general.upload_avatar_error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    const handleDelete = async () => {
      try {
        await store.dispatch(
          EMPLOYEE_ACTION_DELETE_EMPLOYEE_AVATAR,
          props.employeeId
        )

        Snackbar.open(
          i18n.t(
            'employee.employee_details.general.remove_avatar_success_message'
          )
        )
        emit('updated')
        emit('close')
      } catch (err) {
        Snackbar.open({
          message: i18n.t(
            'employee.employee_details.general.upload_avatar_error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    const closeModal = () => {
      newPhoto.value = null
      emit('close')
    }

    return {
      newPhoto,
      isRemoveAvatarModalOpen,
      handleUpdate,
      handleDelete,
      closeModal,
    }
  },
})
