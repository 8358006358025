import { AxiosPromise, AxiosResponse } from 'axios'
import { type ActionContext } from 'vuex'
import {
  restructureRecord,
  flattenRecord,
  extractRelationships,
} from '@/utils/jsonapi/utils'
import { RecordRelationship } from '@/utils/jsonapi/types'
import { shortname } from '@/utils/store'
import { BaseRequestPagination } from '@/utils/api/base-request'
import { BaseResponseMeta } from '@/utils/api/base-response'
import store from '@/store'
import { AREA_TYPE } from '@/app/core/area/models/area.model'
import { AREA_PUBLIC_ACTION_LIST_BY_ID } from '@/app/core/area/store/actions/area.actions.names'
import { TAG_PUBLIC_ACTION_LIST_BY_ID } from '@/app/core/tag/store/actions/tag.actions.names'
import api from '../../network/employee.api'
import {
  Employee,
  EmployeeRaw,
  EMPLOYEE_TYPE,
  TIME_TRACKING_METHOD_TYPE,
  TargetHours,
  TARGET_HOURS_TYPE,
  TargetHoursUpdateParams,
  EmployeeStatus,
  EMPLOYEE_REL_AREAS,
  DATA_PROFILE_TYPE,
  DataProfile,
  RECORDS_TYPE,
  ContractSettingsEdit,
  EmployeeRecordItem,
  EmployeeVacations,
  VACATIONS_TYPE,
} from '../../models/employee.model'
import { EmployeeState } from '../employee.state'
import * as actionNamesObj from './employee.actions.names'
import * as mutationNamesObj from '../mutations/employee.mutations.names'
import * as getterNamesObj from '../getters/employee.getters.names'
import { TABLE_COLUMNS } from '../../pages/composables/use-table-columns'
import { USER_PUBLIC_GETTER_PREFERENCE } from '@/app/core/user/store/getters/user.getters.names'
import { USER_PUBLIC_ACTION_UPDATE_PREFERENCES } from '@/app/core/user/store/actions/user.actions.names'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)
const getterNames = shortname(getterNamesObj)

export type ListResponse = AxiosResponse<{
  data: EmployeeRaw[]
  meta: BaseResponseMeta
  included: RecordRelationship[]
}>

export default {
  [actionNames.EMPLOYEE_ACTION_LIST_EMPLOYEES]: async ({
    rootState,
    state,
    getters,
    commit,
  }: ActionContext<EmployeeState, any>): Promise<void> => {
    commit(mutationNames.EMPLOYEE_MUTATION_RESET_PAGE)

    const { data: response }: ListResponse = await api.listEmployees({
      workspaceId: rootState.workspace.workspace.id,
      sorting: state.sorting,
      filter: getters[getterNames.EMPLOYEE_GETTER_ACTIVE_FILTERS],
      pagination: {
        ...state.pagination,
        page: 1,
      },
    })

    commit(mutationNames.EMPLOYEE_MUTATION_SET_META, response.meta)

    if (response.data.length) {
      handleFetchingRelationships(response.data)

      commit(mutationNames.EMPLOYEE_MUTATION_ADD_INCLUDED, {
        records: response.included,
      })
      commit(mutationNames.EMPLOYEE_MUTATION_SET_RECORDS, {
        type: EMPLOYEE_TYPE,
        records: response.data,
      })
      commit(mutationNames.EMPLOYEE_MUTATION_INCREMENT_PAGE)
    } else {
      commit(mutationNames.EMPLOYEE_MUTATION_CLEAR_RECORDS, {
        type: EMPLOYEE_TYPE,
      })
    }
  },

  [actionNames.EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES]: async ({
    rootState,
    state,
    getters,
    commit,
  }: ActionContext<EmployeeState, any>): Promise<void> => {
    const pagination: BaseRequestPagination = {
      ...state.pagination,
      page: state.pagination.page + 1,
    }
    if (pagination.page > state.meta.total_pages) {
      return
    }

    const { data: response }: ListResponse = await api.listEmployees({
      workspaceId: rootState.workspace.workspace.id,
      sorting: state.sorting,
      filter: getters[getterNames.EMPLOYEE_GETTER_ACTIVE_FILTERS],
      pagination,
    })

    commit(mutationNames.EMPLOYEE_MUTATION_SET_META, response.meta)

    if (response.data.length) {
      handleFetchingRelationships(response.data)
      commit(mutationNames.EMPLOYEE_MUTATION_ADD_INCLUDED, {
        records: response.included,
      })

      commit(mutationNames.EMPLOYEE_MUTATION_APPEND_RECORDS, {
        type: EMPLOYEE_TYPE,
        records: response.data,
      })
      commit(mutationNames.EMPLOYEE_MUTATION_INCREMENT_PAGE)
    }
  },

  [actionNames.EMPLOYEE_ACTION_CREATE_EMPLOYEE]: (
    { rootState }: ActionContext<EmployeeState, any>,
    payload: Employee
  ): AxiosPromise => {
    return api.createEmployee(rootState.workspace.workspace.id, payload)
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_EMPLOYEE]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    payload: Employee
  ) => {
    const { data: response } = await api.updateEmployee(
      rootState.workspace.workspace.id,
      payload
    )

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: EMPLOYEE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_EMPLOYEE_EMAIL]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    { employeeId, email }: { employeeId: RecordId; email: string }
  ) => {
    const { data: response } = await api.updateEmployeeEmail(
      rootState.workspace.workspace.id,
      { employeeId, email }
    )

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: EMPLOYEE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_EMPLOYEE_AVATAR]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    { employeeId, newPhotoFile }: { employeeId: RecordId; newPhotoFile: File }
  ) => {
    const { data: response } = await api.updateEmployeeAvatar(
      rootState.workspace.workspace.id,
      { employeeId, newPhotoFile }
    )

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: EMPLOYEE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
  },

  [actionNames.EMPLOYEE_ACTION_DELETE_EMPLOYEE_AVATAR]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    employeeId: RecordId
  ) => {
    const { data: response } = await api.deleteEmployeeAvatar(
      rootState.workspace.workspace.id,
      employeeId
    )

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: EMPLOYEE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
  },

  [actionNames.EMPLOYEE_ACTION_GET_EMPLOYEE]: async (
    { rootState, state, commit }: ActionContext<EmployeeState, any>,
    employeeId: RecordId
  ): Promise<Employee> => {
    const { data: response } = await api.getEmployee(
      rootState.workspace.workspace.id,
      employeeId
    )

    handleFetchingRelationships([response.data])
    commit(mutationNames.EMPLOYEE_MUTATION_ADD_INCLUDED, {
      records: response.included,
    })

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: EMPLOYEE_TYPE,
      match: {
        id: employeeId,
      },
      replacement: response.data,
    })

    return <Employee>restructureRecord(response.data, state)
  },

  [actionNames.EMPLOYEE_PUBLIC_ACTION_ATTACH_AREA]: async (
    { commit }: ActionContext<EmployeeState, any>,
    { employeeIds, areaId }: { employeeIds: RecordId[]; areaId: RecordId }
  ) => {
    for (const employeeId of employeeIds) {
      commit(mutationNames.EMPLOYEE_MUTATION_ATTACH_RELATIONSHIPS, {
        parentType: EMPLOYEE_TYPE,
        parentId: employeeId,
        relName: EMPLOYEE_REL_AREAS,
        children: [{ id: areaId, type: AREA_TYPE }],
      })
    }
  },

  [actionNames.EMPLOYEE_PUBLIC_ACTION_DETACH_AREA]: async (
    { commit }: ActionContext<EmployeeState, any>,
    { employeeIds, areaId }: { employeeIds: RecordId[]; areaId: RecordId }
  ) => {
    for (const employeeId of employeeIds) {
      commit(mutationNames.EMPLOYEE_MUTATION_DETACH_RELATIONSHIPS, {
        parentType: EMPLOYEE_TYPE,
        parentId: employeeId,
        relName: EMPLOYEE_REL_AREAS,
        children: [{ id: areaId, type: AREA_TYPE }],
      })
    }
  },

  [actionNames.EMPLOYEE_ACTION_DELETE_EMPLOYEE]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    payload: RecordId
  ) => {
    const { data: response } = await api.deleteEmployee(
      rootState.workspace.workspace.id,
      payload
    )

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: EMPLOYEE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: null,
    })
  },

  [actionNames.EMPLOYEE_ACTION_LIST_UNREGISTERED]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      withEmail,
      pagination,
    }: { withEmail: boolean; pagination: BaseRequestPagination }
  ) => {
    const { data: response } = await api.listUnregisteredEmployees(
      rootState.workspace.workspace.id,
      { withEmail, pagination }
    )
    const data = response.data.map(flattenRecord)

    return { data, meta: response.meta }
  },

  [actionNames.EMPLOYEE_ACTION_INVITE_EMPLOYEES]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      employeeIds,
      checkin,
      welcomeMessage,
    }: {
      employeeIds: RecordId[] | 'all'
      checkin: boolean
      welcomeMessage: string | null
    }
  ) => {
    const { data: response } = await api.inviteEmployees(
      rootState.workspace.workspace.id,
      {
        all: employeeIds === 'all',
        employeeIds: employeeIds === 'all' ? [] : employeeIds,
        checkin,
        welcomeMessage,
      }
    )
    return response.meta
  },

  [actionNames.EMPLOYEE_ACTION_GET_INVITE_LINK]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      checkin,
      welcomeMessage,
    }: {
      checkin: boolean
      welcomeMessage: string | null
    }
  ) => {
    const { data: response } = await api.getInviteLink(
      rootState.workspace.workspace.id,
      {
        checkin,
        welcomeMessage,
      }
    )
    return response.meta
  },

  [actionNames.EMPLOYEE_ACTION_LIST_TRACKING_METHODS]: async ({
    rootState,
    state,
    commit,
  }: ActionContext<EmployeeState, any>): Promise<void> => {
    if (state.records[TIME_TRACKING_METHOD_TYPE].length) {
      return
    }

    const { data: response }: AxiosResponse = await api.listTrackingMethods(
      rootState.workspace.workspace.id
    )

    if (response.data.length) {
      commit(mutationNames.EMPLOYEE_MUTATION_SET_RECORDS, {
        type: TIME_TRACKING_METHOD_TYPE,
        records: response.data,
      })
    }
  },

  [actionNames.EMPLOYEE_ACTION_CREATE_TARGET_HOURS]: (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      employeeId,
      targetHours,
    }: { employeeId: RecordId; targetHours: TargetHours }
  ): AxiosPromise => {
    return api.createTargetHours(
      rootState.workspace.workspace.id,
      employeeId,
      targetHours
    )
  },

  [actionNames.EMPLOYEE_ACTION_LIST_TARGET_HOURS]: async (
    { rootState, state, getters, commit }: ActionContext<EmployeeState, any>,
    employeeId: RecordId
  ) => {
    commit(mutationNames.EMPLOYEE_MUTATION_RESET_TARGET_HOURS_PAGE)

    const { data: response }: AxiosResponse = await api.listTargetHours({
      workspaceId: rootState.workspace.workspace.id,
      employeeId,
      pagination: {
        ...state.targetHoursPagination,
        page: 1,
      },
      filter: getters[getterNames.EMPLOYEE_TARGET_HOURS_GETTER_ACTIVE_FILTERS],
    })

    if (response.data.length) {
      commit(
        mutationNames.EMPLOYEE_MUTATION_SET_TARGET_HOURS_META,
        response.meta
      )
      commit(mutationNames.EMPLOYEE_MUTATION_INCREMENT_TARGET_HOURS_PAGE)

      commit(mutationNames.EMPLOYEE_MUTATION_SET_RECORDS, {
        type: TARGET_HOURS_TYPE,
        records: response.data,
      })
    } else {
      commit(mutationNames.EMPLOYEE_MUTATION_CLEAR_RECORDS, {
        type: TARGET_HOURS_TYPE,
      })
    }
  },

  [actionNames.EMPLOYEE_ACTION_LIST_APPEND_TARGET_HOURS]: async (
    { state, getters, rootState, commit }: ActionContext<EmployeeState, any>,
    employeeId: RecordId
  ) => {
    const pagination: BaseRequestPagination = {
      ...state.targetHoursPagination,
      page: state.targetHoursPagination.page + 1,
    }
    if (pagination.page > state.targetHoursMeta.total_pages) {
      return
    }

    const { data: response }: AxiosResponse = await api.listTargetHours({
      workspaceId: rootState.workspace.workspace.id,
      employeeId,
      pagination,
      filter: getters[getterNames.EMPLOYEE_TARGET_HOURS_GETTER_ACTIVE_FILTERS],
    })

    if (response.data.length) {
      commit(
        mutationNames.EMPLOYEE_MUTATION_SET_TARGET_HOURS_META,
        response.meta
      )

      commit(mutationNames.EMPLOYEE_MUTATION_INCREMENT_TARGET_HOURS_PAGE)

      commit(mutationNames.EMPLOYEE_MUTATION_APPEND_RECORDS, {
        type: TARGET_HOURS_TYPE,
        records: response.data,
      })
    }
    return
  },

  [actionNames.EMPLOYEE_ACTION_LIST_ABSENCES]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      userId,
      startsAt,
      endsAt,
    }: { userId: RecordId; startsAt: Date; endsAt: Date }
  ) => {
    const { data: response } = await api.listEmployeeAbsences(
      rootState.workspace.workspace.id,
      { userId, startsAt, endsAt }
    )
    return { meta: response.meta }
  },

  [actionNames.EMPLOYEE_ACTION_GET_TARGET_HOURS]: async (
    { rootState, state, commit }: ActionContext<EmployeeState, any>,
    {
      employeeId,
      targetHoursId,
    }: {
      employeeId: RecordId
      targetHoursId: RecordId
    }
  ): Promise<TargetHours> => {
    const { data: response } = await api.getTargetHours(
      rootState.workspace.workspace.id,
      employeeId,
      targetHoursId
    )

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: TARGET_HOURS_TYPE,
      match: {
        id: targetHoursId,
      },
      replacement: response.data,
    })

    return <TargetHours>restructureRecord(response.data, state)
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_TARGET_HOURS]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    {
      employeeId,
      targetHours,
    }: { employeeId: RecordId; targetHours: TargetHoursUpdateParams }
  ) => {
    const { data: response } = await api.updateTargetHours(
      rootState.workspace.workspace.id,
      employeeId,
      targetHours
    )
    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: TARGET_HOURS_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
  },

  [actionNames.EMPLOYEE_ACTION_DELETE_TARGET_HOURS]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    {
      employeeId,
      targetHoursId,
    }: { employeeId: RecordId; targetHoursId: RecordId }
  ) => {
    await api.deleteTargetHours({
      workspaceId: rootState.workspace.workspace.id,
      employeeId,
      targetHoursId,
    })
    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: TARGET_HOURS_TYPE,
      match: {
        id: targetHoursId,
      },
      replacement: null,
    })
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_EMPLOYEE_STATUS]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    { employeeId, status }: { employeeId: RecordId; status: EmployeeStatus }
  ) => {
    const { data: response } = await api.updateEmployeeStatus({
      workspaceId: rootState.workspace.workspace.id,
      employeeId,
      status,
    })

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: EMPLOYEE_TYPE,
      match: {
        id: employeeId,
      },
      replacement: response.data,
    })
  },

  [actionNames.EMPLOYEE_ACTION_LIST_DATA_PROFILES]: async ({
    rootState,
    commit,
  }: ActionContext<EmployeeState, any>) => {
    const { data: response } = await api.getEmployeeDataProfileList(
      rootState.user.user.account.id,
      rootState.workspace.workspace.id
    )

    commit(mutationNames.EMPLOYEE_MUTATION_SET_RECORDS, {
      type: DATA_PROFILE_TYPE,
      records: response.data,
    })
    commit(mutationNames.EMPLOYEE_MUTATION_ADD_INCLUDED, {
      records: response.included,
    })
  },

  [actionNames.EMPLOYEE_ACTION_SELECT_COLUMNS_TO_SHOW]: async (
    { dispatch, getters }: ActionContext<EmployeeState, any>,
    {
      selected,
      column,
    }: {
      selected: boolean
      column: string
    }
  ) => {
    const userPreference = getters[getterNames.EMPLOYEE_GETTER_LIMITED_COLUMNS]

    if (!userPreference) return

    const newColumns = { ...userPreference.value, [column]: selected }

    dispatch(
      USER_PUBLIC_ACTION_UPDATE_PREFERENCES,
      { preferenceId: userPreference.id, value: newColumns },
      { root: true }
    )
  },

  [actionNames.EMPLOYEE_ACTION_RESET_DEFAULT_COLUMNS_TO_SHOW]: async ({
    dispatch,
    rootGetters,
  }: ActionContext<EmployeeState, any>) => {
    const userPreference =
      rootGetters[USER_PUBLIC_GETTER_PREFERENCE]('employee_list')

    if (!userPreference) return

    const defaultColumns: Record<string, boolean> = TABLE_COLUMNS.reduce(
      (acc, col) => ({ ...acc, [col.unique_key]: true }),
      {}
    )

    const allColumns = Object.keys(userPreference.value).reduce(
      (init, unique_key) => ({
        ...init,
        [unique_key]: Boolean(defaultColumns[unique_key]),
      }),
      {}
    )

    dispatch(
      USER_PUBLIC_ACTION_UPDATE_PREFERENCES,
      { preferenceId: userPreference.id, value: allColumns },
      { root: true }
    )
  },

  [actionNames.EMPLOYEE_ACTION_GET_DATA_PROFILE]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    { dataProfileId, userId }: { dataProfileId: RecordId; userId: RecordId }
  ) => {
    const { data: response } = await api.getEmployeeDataProfile({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      dataProfileId,
    })

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: DATA_PROFILE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })

    commit(mutationNames.EMPLOYEE_MUTATION_ADD_INCLUDED, {
      records: response.included,
    })
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_DATA_PROFILE]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    { dataProfile, userId }: { dataProfile: DataProfile; userId: RecordId }
  ) => {
    await api.updateEmployeeDataProfile({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      dataProfile,
    })
  },

  [actionNames.EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    const { data: response } = await api.getEmployeeContractSettings({
      workspaceId: rootState.workspace.workspace.id,
      userId,
    })

    return flattenRecord(response.data)
  },

  [actionNames.EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS_ATTRIBUTES]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    const { data: response } = await api.getEmployeeContractSettingsAttributes({
      workspaceId: rootState.workspace.workspace.id,
      userId,
    })

    return flattenRecord(response.data)
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      userId,
      contractSettings,
    }: { userId: RecordId; contractSettings: ContractSettingsEdit }
  ) => {
    await api.updateEmployeeContractSettings({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      contractSettings,
    })
  },

  [actionNames.EMPLOYEE_ACTION_GET_RECORDS_SUMMARY]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    const { data: response } = await api.getEmployeeRecordsSummary({
      workspaceId: rootState.workspace.workspace.id,
      userId,
    })

    return response.data
  },

  [actionNames.EMPLOYEE_ACTION_GET_VACATIONS_SUMMARY]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    const { data: response } = await api.getEmployeeVacationsSummary({
      workspaceId: rootState.workspace.workspace.id,
      userId,
    })

    return response.data
  },

  [actionNames.EMPLOYEE_ACTION_LIST_RECORDS]: async (
    { rootState, state, getters, commit }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    commit(mutationNames.EMPLOYEE_RECORDS_MUTATION_RESET_PAGE)

    const { data: response } = await api.getEmployeeRecords({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      sorting: state.recordsSorting,
      filter: getters[getterNames.EMPLOYEE_RECORDS_GETTER_ACTIVE_FILTERS],
      pagination: {
        ...state.recordsPagination,
        page: 1,
      },
    })

    commit(mutationNames.EMPLOYEE_RECORDS_MUTATION_SET_META, response.meta)

    if (response.data.length) {
      commit(mutationNames.EMPLOYEE_MUTATION_SET_RECORDS, {
        type: RECORDS_TYPE,
        records: response.data,
      })
      commit(mutationNames.EMPLOYEE_RECORDS_MUTATION_INCREMENT_PAGE)
    } else {
      commit(mutationNames.EMPLOYEE_MUTATION_CLEAR_RECORDS, {
        type: RECORDS_TYPE,
      })
    }
  },

  [actionNames.EMPLOYEE_ACTION_LIST_APPEND_RECORDS]: async (
    { rootState, state, getters, commit }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    const pagination: BaseRequestPagination = {
      ...state.recordsPagination,
      page: state.recordsPagination.page + 1,
    }
    if (pagination.page > state.recordsMeta.total_pages) {
      return
    }

    const { data: response } = await api.getEmployeeRecords({
      workspaceId: rootState.workspace.workspace.id,
      sorting: state.recordsSorting,
      filter: getters[getterNames.EMPLOYEE_RECORDS_GETTER_ACTIVE_FILTERS],
      userId,
      pagination,
    })

    commit(mutationNames.EMPLOYEE_RECORDS_MUTATION_SET_META, response.meta)

    if (response.data.length) {
      commit(mutationNames.EMPLOYEE_MUTATION_APPEND_RECORDS, {
        type: RECORDS_TYPE,
        records: response.data,
      })
      commit(mutationNames.EMPLOYEE_RECORDS_MUTATION_INCREMENT_PAGE)
    }
  },

  [actionNames.EMPLOYEE_ACTION_GET_RECORD]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      userId,
      recordId,
    }: {
      userId: RecordId
      recordId: RecordId
    }
  ) => {
    const { data: response } = await api.getEmployeeRecord({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      recordId,
    })

    return flattenRecord(response.data)
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_RECORD]: async (
    { rootState, commit }: ActionContext<EmployeeState, any>,
    {
      userId,
      recordId,
      record,
    }: { userId: RecordId; recordId: RecordId; record: EmployeeRecordItem }
  ) => {
    const { data: response } = await api.updateEmployeeRecord({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      recordId,
      record,
    })

    commit(mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD, {
      type: RECORDS_TYPE,
      match: {
        id: recordId,
      },
      replacement: response.data,
    })
  },

  [actionNames.EMPLOYEE_ACTION_CREATE_VACATION]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    { userId, vacation }: { userId: RecordId; vacation: EmployeeVacations }
  ) => {
    return await api.createEmployeeVacation({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      vacation,
    })
  },

  [actionNames.EMPLOYEE_ACTION_LIST_VACATIONS]: async (
    { rootState, state, commit }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    commit(mutationNames.EMPLOYEE_MUTATION_RESET_VACATIONS_PAGE)

    const { data: response } = await api.listEmployeeVacations({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      sorting: state.vacationsSorting,
      pagination: {
        ...state.vacationsPagination,
        page: 1,
      },
    })

    commit(mutationNames.EMPLOYEE_MUTATION_SET_VACATIONS_META, response.meta)

    if (response.data.length) {
      commit(mutationNames.EMPLOYEE_MUTATION_SET_RECORDS, {
        type: VACATIONS_TYPE,
        records: response.data,
      })
      commit(mutationNames.EMPLOYEE_MUTATION_INCREMENT_VACATIONS_PAGE)
    } else {
      commit(mutationNames.EMPLOYEE_MUTATION_CLEAR_RECORDS, {
        type: VACATIONS_TYPE,
      })
    }
  },

  [actionNames.EMPLOYEE_ACTION_LIST_APPEND_VACATIONS]: async (
    { rootState, state, commit }: ActionContext<EmployeeState, any>,
    userId: RecordId
  ) => {
    const pagination: BaseRequestPagination = {
      ...state.vacationsPagination,
      page: state.vacationsPagination.page + 1,
    }
    if (pagination.page > state.vacationsMeta.total_pages) {
      return
    }

    const { data: response } = await api.listEmployeeVacations({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      sorting: state.vacationsSorting,
      pagination,
    })

    commit(mutationNames.EMPLOYEE_MUTATION_SET_VACATIONS_META, response.meta)

    if (response.data.length) {
      commit(mutationNames.EMPLOYEE_MUTATION_APPEND_RECORDS, {
        type: VACATIONS_TYPE,
        records: response.data,
      })
      commit(mutationNames.EMPLOYEE_MUTATION_INCREMENT_VACATIONS_PAGE)
    }
  },

  [actionNames.EMPLOYEE_ACTION_GET_VACATION]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      userId,
      vacationId,
    }: {
      userId: RecordId
      vacationId: RecordId
    }
  ) => {
    const { data: response } = await api.getEmployeeVacation({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      vacationId,
    })

    return flattenRecord(response.data)
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_VACATION]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      userId,
      vacation,
    }: { userId: RecordId; vacationId: RecordId; vacation: EmployeeVacations }
  ) => {
    await api.updateEmployeeVacation({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      vacation,
    })
  },

  [actionNames.EMPLOYEE_ACTION_DELETE_VACATION]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    { userId, vacationId }: { userId: RecordId; vacationId: RecordId }
  ) => {
    await api.deleteEmployeeVacation({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      vacationId,
    })
  },

  [actionNames.EMPLOYEE_ACTION_TRANSFER_VACATION]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    { userId, vacationId }: { userId: RecordId; vacationId: RecordId }
  ) => {
    await api.transferEmployeeVacation({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      vacationId,
    })
  },

  [actionNames.EMPLOYEE_ACTION_GET_VACATION_HISTORY]: async (
    { rootState }: ActionContext<EmployeeState, any>,
    {
      userId,
      vacationId,
    }: {
      userId: RecordId
      vacationId: RecordId
    }
  ) => {
    const { data: response } = await api.getEmployeeVacationHistory({
      workspaceId: rootState.workspace.workspace.id,
      userId,
      vacationId,
    })

    return response.data.map(flattenRecord)
  },

  [actionNames.EMPLOYEE_ACTION_UPDATE_HOUR_HOLIDAYS]: async (
    { rootState, state }: ActionContext<EmployeeState, any>,
    {
      employeeId,
      hour_holidays,
    }: { employeeId: RecordId; hour_holidays: boolean }
  ) => {
    const { data: response } = await api.updateEmployeeHourHolidays({
      workspaceId: rootState.workspace.workspace.id,
      employeeId,
      hour_holidays,
    })

    return <Employee>restructureRecord(response.data, state)
  },
}

function handleFetchingRelationships(data: EmployeeRaw[]) {
  const relationships = extractRelationships(data, ['areas', 'tags'])

  handleFetchingAreas(relationships)
  handleFetchingTags(relationships)
}

function handleFetchingTags(relationships: Map<string, Set<string>>) {
  const tagsIds = relationships.get('tags')

  if (tagsIds?.size) {
    store.dispatch(TAG_PUBLIC_ACTION_LIST_BY_ID, [...tagsIds], {
      root: true,
    })
  }
}

function handleFetchingAreas(relationships: Map<string, Set<string>>) {
  const areaIds = relationships.get('areas')

  if (areaIds?.size) {
    store.dispatch(AREA_PUBLIC_ACTION_LIST_BY_ID, [...areaIds], {
      root: true,
    })
  }
}
