<template>
  <div :class="value ? 'text-error-400' : 'text-success-350'">
    {{
      value
        ? $t('employee.employee_details.target_hours.not_allowed')
        : $t('employee.employee_details.target_hours.allowed')
    }}
  </div>
</template>

<script>
export default {
  name: 'TableRowCellExceedHours',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
