












































import Vue from 'vue'
import BaseInput from '@/app/util-modules/ui/input/BaseInput.vue'
import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'

export default Vue.extend({
  name: 'EmployeeTargetHoursDay',
  components: {
    BaseInput,
    BaseToggle,
    BaseIcon,
    BaseInputNumber,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    freeday: {
      type: Boolean,
      required: true,
    },
    hours: {
      type: Number,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(freeday: boolean, hours: string | number) {
      const hoursNumb: number = freeday ? 0 : parseFloat(hours + '' || '0')
      this.$emit('input', { freeday, hours: hoursNumb })
    },
  },
})
