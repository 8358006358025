<template>
  <div>
    <div class="grid mb-4 form-checkin">
      <base-toggle
        data-id-prefix="employee.invite_modal.user_checkin"
        class="row-span-2"
        :value="userCheckin"
        :disabled="!userPermission"
        @input="$emit('user-checkin-input', $event)"
      />

      <h3 class="font-bold">{{
        $t('employee.invite_modal.user_checkin_title')
      }}</h3>

      <p v-if="!userPermission" class="max-w-xs">{{
        $t('employee.invite_modal.user_checkin_description')
      }}</p>
    </div>

    <base-input-field
      :value="welcomeMessage"
      :label="$t('employee.invite_modal.welcome_message')"
      :placeholder="$t('employee.invite_modal.welcome_message_placeholder')"
      :disabled="!userCheckin || !userPermission"
      name="welcome"
      type="text"
      data-id="employee.invite_modal.welcome_message"
      @input="$emit('welcome-message-input', $event)"
    />
  </div>
</template>

<script>
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'

export default {
  name: 'EmployeeInviteFormCheckin',
  components: {
    BaseInputField,
    BaseToggle,
  },
  props: {
    userPermission: {
      type: Boolean,
      required: true,
    },
    userCheckin: {
      type: Boolean,
      required: true,
    },
    welcomeMessage: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.form-checkin {
  grid-template-columns: 60px auto;
}
</style>
