var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$isSmallDevice)?_c('portal',{attrs:{"to":"modal"}},[_c('base-modal',{attrs:{"has-modal-card":""}},[_c('base-card-modal-form',{attrs:{"title":_vm.$t('employee.employee_details.records.edit.title'),"button-submit-text":_vm.$t('employee.employee_details.records.edit.button_submit'),"action-status":_vm.$actions.updateRecord,"loading":_vm.$actions.updateRecord.isLoading},on:{"submit":function($event){return _vm.handleSubmit()},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleInputChange = ref.handleInputChange;
return [_c('edit-form',{attrs:{"value":_vm.recordItem},on:{"input":function($event){_vm.recordItem = $event},"updated":function($event){return handleInputChange(true)}}})]}}],null,false,454238943)})],1)],1):(_vm.$isSmallDevice)?_c('portal',{attrs:{"to":"bottom-sheet"}},[_c('bottom-sheet-form-wrapper',{attrs:{"header-right-actions":[
      {
        title: _vm.$t('employee.employee_details.records.edit.button_submit'),
        event: 'submit',
        class: 'justify-self-end text-green',
      } ],"header-title":_vm.$t('employee.employee_details.records.edit.title'),"action-status":_vm.$actions.updateRecord,"loading":_vm.$actions.updateRecord.isLoading},on:{"close":function($event){return _vm.$emit('close')},"submit":function($event){return _vm.handleSubmit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleInputChange = ref.handleInputChange;
return [_c('edit-form',{staticClass:"p-4",attrs:{"value":_vm.recordItem},on:{"input":function($event){_vm.recordItem = $event},"updated":function($event){return handleInputChange(true)}}})]}}])})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }