





















import Vue from 'vue'
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper.vue'
import TargetHoursForm from './EmployeeTargetHoursForm.vue'

export default Vue.extend({
  name: 'EmployeeTargetHoursBottomSheet',
  components: {
    BottomSheetFormWrapper,
    TargetHoursForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonLabel: {
      type: String,
      required: true,
    },
    actionStatus: {
      type: Object,
      required: true,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
})
