import { runOrReturn } from '@/utils/helpers'
import EmployeeVacationsFooterCell from './EmployeeVacationsFooterCell.vue'

export default {
  name: 'EmployeeVacationsTableFooter',
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
  },
  render(h) {
    const footerCells = this.columns.map((col) => {
      const data = {
        props: {},
        style: {},
        class: {},
      }

      // pre-process props
      for (const [key, value] of Object.entries(col?.footer?.props || {})) {
        data.props[key] = runOrReturn(value)
      }

      if (col.width) {
        data.style = {
          width: runOrReturn(col.width),
        }
      }

      if (col.header?.['class']) {
        data.class = runOrReturn(col?.header?.class)
      }

      return h(col.footer?.element || EmployeeVacationsFooterCell, data)
    })

    return h(
      'div',
      {
        class: `
          min-w-max w-full border-t py-2 px-4
          md:py-2.5 md:px-2 h-full border-coal-100
          bg-coal-80 flex items-center
        `,
      },
      footerCells
    )
  },
}
