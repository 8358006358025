<template>
  <div>
    <div class="mb-1 text-left font-medium">
      {{ $t('employee.invite_modal.invite_link_note') }}
      <button
        type="button"
        data-id="employee.invite_modal.see_no_email_list"
        class="font-semibold text-underline text-green-350 cursor-pointer"
        @click="$emit('text-click')"
      >
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        {{ $t('employee.invite_modal.see_no_email_list') }} &raquo;
      </button>
    </div>

    <button
      type="button"
      data-id="employee.invite_modal.copy_invitation"
      class="cursor-pointer font-semibold text-green-350"
      @click="$emit('link-click')"
    >
      {{ $t('employee.invite_modal.copy_invitation') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'EmployeeInviteLink',
}
</script>
