


































import { computed, defineComponent, ref } from '@vue/composition-api'
import BaseAvatar from '@/app/util-modules/ui/avatar/BaseAvatar.vue'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import AddModal from './EmployeeAvatarAddModal.vue'
import { omitDefaultAvatarUrl, isDefaultAvatarUrl } from '@/utils/helpers'

export default defineComponent({
  name: 'EmployeeAvatar',
  components: {
    BaseAvatar,
    BaseButton,
    BaseIcon,
    AddModal,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    employeeId: {
      type: String,
      required: true,
    },
    avatarUrl: {
      type: String,
      default: '',
    },
    isEmployeeActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isModalOpen = ref(false)
    const avatar = computed(() => omitDefaultAvatarUrl(props.avatarUrl))
    const isRemoveButtonHidden = computed(() =>
      isDefaultAvatarUrl(props.avatarUrl)
    )

    return {
      isModalOpen,
      avatar,
      isRemoveButtonHidden,
    }
  },
})
