import { MODULE_NAME } from '../../employee.module'

export const EMPLOYEE_GETTER_ACTIVE_FILTERS = `${MODULE_NAME}/GETTER_ACTIVE_FILTERS`
export const EMPLOYEE_GETTER_CUSTOM_COLUMNS = `${MODULE_NAME}/GETTER_CUSTOM_COLUMNS`
export const EMPLOYEE_PUBLIC_GETTER_GET_BY_ID = `${MODULE_NAME}/PUBLIC_GETTER_GET_BY_ID`

export const EMPLOYEE_TARGET_HOURS_GETTER_ACTIVE_FILTERS = `${MODULE_NAME}/TARGET_HOURS_GETTER_ACTIVE_FILTERS`
export const EMPLOYEE_GETTER_ALL_COLUMNS_SORTED = `${MODULE_NAME}/GETTER_ALL_COLUMNS_SORTED`
export const EMPLOYEE_GETTER_LIMITED_COLUMNS = `${MODULE_NAME}/GETTER_LIMITED_COLUMNS`
export const EMPLOYEE_RECORDS_GETTER_ACTIVE_FILTERS = `${MODULE_NAME}/RECORDS_GETTER_ACTIVE_FILTERS`
export const EMPLOYEE_VACATIONS_GETTER_IS_TRANSFERRED = `${MODULE_NAME}/VACATIONS_GETTER_IS_TRANSFERRED`
