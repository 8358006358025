












































































import { PropType, defineComponent, toRefs } from '@vue/composition-api'
import { objectHasOwnProperty } from '../employee.utils'
import {
  WeeklyTargetHours,
  CustomTargetHours,
} from '../../models/employee.model'
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox.vue'
import TargetHoursRhythm from './EmployeeTargetHoursRhythm.vue'
import TargetHoursDate from './EmployeeTargetHoursDate.vue'
import TargetHoursEndDate from './EmployeeTargetHoursEndDate.vue'
import TargetHoursWeekly from './EmployeeTargetHoursWeekly.vue'
import TargetHoursMonthly from './EmployeeTargetHoursMonthly.vue'
import TargetHoursCustom from './EmployeeTargetHoursCustom.vue'
import HelpText from './EmployeeTargetHoursHelpText.vue'

export default defineComponent({
  name: 'EmployeeTargetHoursForm',
  components: {
    BaseCheckbox,
    TargetHoursWeekly,
    TargetHoursMonthly,
    TargetHoursCustom,
    TargetHoursRhythm,
    TargetHoursDate,
    TargetHoursEndDate,
    HelpText,
  },
  props: {
    preloadActionStatus: {
      type: Object,
      default: () => ({}),
    },
    rhythm: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      default: null,
    },
    endDateShown: {
      type: Boolean,
      default: false,
    },
    totalHours: {
      type: Number,
      default: 0,
    },
    limited: {
      type: Boolean,
      default: false,
    },
    weeklyPayload: {
      type: Object as PropType<WeeklyTargetHours>,
      required: true,
    },
    customPayload: {
      type: Object as PropType<CustomTargetHours>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { weeklyPayload } = toRefs(props)

    const getWeekdayBool = (weekdayShortname: string) => {
      return `is_${weekdayShortname}`
    }

    const isFreeWeekday = (weekdayShortname: string) => {
      if (objectHasOwnProperty(weeklyPayload.value, weekdayShortname)) {
        const weekdayBool = getWeekdayBool(weekdayShortname)
        return !weeklyPayload.value[weekdayBool]
      }
      return false
    }

    const emitChange = (eventName: string, value: any) => {
      emit(eventName, value)
      emit('input')
    }

    return {
      getWeekdayBool,
      isFreeWeekday,
      emitChange,
    }
  },
})
