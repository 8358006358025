






















































import { defineComponent, computed, ref } from '@vue/composition-api'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import i18n from '@/utils/vendors/i18n'

export default defineComponent({
  name: 'EmployeeVacationsViewSwitchBottomSheet',
  components: {
    BaseButton,
    BaseToggle,
    BottomSheet,
  },
  props: {
    isHourHolidays: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBottomSheetOpen = ref(false)

    const handleToggle = () => {
      emit('toggle-vacation-view', {
        value: !props.isHourHolidays,
        type: 'bottom-sheet',
      })
    }

    const bottomSheetActions = computed(() => {
      return [
        {
          title: i18n.t(
            'employee.employee_details.vacations.views.button_apply'
          ),
          class: 'justify-self-end text-green',
          event: 'update-vacation-view',
        },
      ]
    })

    const updateVacationsView = () => {
      emit('update-vacation-view', !props.isHourHolidays)
      isBottomSheetOpen.value = false
    }

    return {
      bottomSheetActions,
      isBottomSheetOpen,

      handleToggle,
      updateVacationsView,
    }
  },
})
