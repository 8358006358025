import { ref } from '@vue/composition-api'

export type View = 'invite-form' | 'no-email-list'

export default function useView(onViewChange: (view: View) => void) {
  const currentView = ref<View>('invite-form')

  const switchView = (view: View) => {
    currentView.value = view

    if (typeof onViewChange === 'function') {
      onViewChange(view)
    }
  }

  return { currentView, switchView }
}
