








































import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n/index'
import store from '@/store'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'
import ActivateDeactivate from './EmployeeGeneralActivateDeactivate.vue'
import DataSections from './EmployeeGeneralDataSections.vue'
import DataSectionEdit from './EmployeeGeneralDataSectionEdit.vue'
import { EMPLOYEE_ACTION_UPDATE_EMPLOYEE_STATUS } from '../../store/actions/employee.actions.names'
import {
  USER_GETTER_IS_ROLE_ADMIN,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'
import { Employee, DataProfile } from '../../models/employee.model'

export default defineComponent({
  name: 'EmployeeGeneral',
  components: {
    BaseDialog,
    ActivateDeactivate,
    DataSections,
    DataSectionEdit,
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
    dataProfileValues: {
      type: Array as PropType<DataProfile[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const newUserStatus = ref('')
    const editingSectionId = ref('')
    const isEmployeeActive = computed(() => props.employee.status === 'active')
    const dataProfileValue = computed(() =>
      editingSectionId.value
        ? props.dataProfileValues.find((p) => p.id === editingSectionId.value)
        : {}
    )

    const dialogContent = computed(() =>
      isEmployeeActive.value
        ? {
            title: i18n.t(
              'employee.employee_details.general.confirmation_modal.deactivate_title'
            ),
            content: i18n.t(
              'employee.employee_details.general.confirmation_modal.deactivate_content'
            ),
            button: i18n.t(
              'employee.employee_details.general.confirmation_modal.deactivate_button'
            ),
          }
        : {
            title: i18n.t(
              'employee.employee_details.general.confirmation_modal.activate_title'
            ),
            content: i18n.t(
              'employee.employee_details.general.confirmation_modal.activate_content'
            ),
            button: i18n.t(
              'employee.employee_details.general.confirmation_modal.activate_button'
            ),
          }
    )
    const canActivateDeactivate = computed(
      () => props.employee.permissions?.activate_deactivate
    )
    const isAdmin = computed(() => store.getters[USER_GETTER_IS_ROLE_ADMIN])
    const isCurrentUser = computed(
      () => props.employee.id === store.getters[USER_GETTER_USER].id
    )
    const changeUserStatus = () => {
      if (canActivateDeactivate.value) {
        newUserStatus.value = isEmployeeActive.value ? 'inactive' : 'active'
      }
    }

    const submitStatusChange = async () => {
      await store.dispatch(EMPLOYEE_ACTION_UPDATE_EMPLOYEE_STATUS, {
        employeeId: props.employee.id,
        status: newUserStatus.value,
      })

      emit('updated')
      newUserStatus.value = ''
    }

    return {
      newUserStatus,
      dialogContent,
      isEmployeeActive,
      canActivateDeactivate,
      isCurrentUser,
      isAdmin,
      editingSectionId,
      dataProfileValue,

      changeUserStatus,
      submitStatusChange,
    }
  },
})
