








import { PropType } from 'vue'
import { Employee } from '../../../models/employee.model'
import { MODULE_NAME } from '../../../employee.module'

export default {
  name: 'TableRowCellName',
  props: {
    value: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },
  data() {
    return { MODULE_NAME }
  },
}
