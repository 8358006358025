


































import { defineComponent, PropType, ref } from '@vue/composition-api'
import store from '@/store'
import { TranslateResult } from 'vue-i18n'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { Employee } from '../models/employee.model'
import useTransaction from '@/utils/composables/use-transaction'
import FormModal from './employee-form/EmployeeFormModal.vue'
import FormBottomSheet from './employee-form/EmployeeFormBottomSheet.vue'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'
import {
  EMPLOYEE_ACTION_DELETE_EMPLOYEE,
  EMPLOYEE_ACTION_GET_EMPLOYEE,
  EMPLOYEE_ACTION_UPDATE_EMPLOYEE,
} from '../store/actions/employee.actions.names'

export default defineComponent({
  name: 'EmployeeEdit',
  components: {
    FormModal,
    FormBottomSheet,
    BaseDialog,
  },
  props: {
    employeeId: {
      // TODO: find out why eslint doesn't recognize typescript globals
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
  },
  trackedActions: {
    updateEmployee: EMPLOYEE_ACTION_UPDATE_EMPLOYEE,
    getEmployee: EMPLOYEE_ACTION_GET_EMPLOYEE,
    deleteEmployee: EMPLOYEE_ACTION_DELETE_EMPLOYEE,
  },
  setup(props, { emit }) {
    const isDeleteEmployeeeConfirmOpen = ref(false)
    const moreOptions = ref<Record<string, TranslateResult>[]>([])
    const payload = ref<Employee>({
      username: '',
      role_ids: [],
      area_ids: [],
      tag_ids: [],
      tags: [],
      time_tracking_method_id: '',
      permissions: {
        update: false,
        destroy: false,
        update_account_admin_role: false,
      },
    })

    const handleEdit = async () => {
      const handleEditWithTracker = useTransaction({
        name: 'EmployeeEdit',
        op: 'handleEdit',
        description: 'edit employee',
        data: { ...payload.value },
        callback: () =>
          store.dispatch(EMPLOYEE_ACTION_UPDATE_EMPLOYEE, {
            ...payload.value,
          }),
      })

      await handleEditWithTracker()

      Snackbar.open(i18n.t('employee.edit_form.success_message'))
      emit('close')
    }

    const handleDelete = async () => {
      const handleSelectionWithTracker = useTransaction({
        name: 'EmployeeDelete',
        op: 'handleSelectionDelete',
        description: 'delete employee',
        callback: () =>
          store.dispatch(EMPLOYEE_ACTION_DELETE_EMPLOYEE, props.employeeId),
      })
      await handleSelectionWithTracker()

      emit('close-deleted')
      Snackbar.open(i18n.t('employee.edit_form.delete_success_message'))
    }

    const handleSelection = async (option: any) => {
      if (option.id === 'delete') isDeleteEmployeeeConfirmOpen.value = true
    }

    const loadEmployees = () =>
      store
        .dispatch(EMPLOYEE_ACTION_GET_EMPLOYEE, props.employeeId)
        .then((employee) => {
          const role_ids = employee.roles.map((r: any) => r.id)
          const area_ids = employee.areas.map((a: any) => a.id)
          const tag_ids = employee.tags.map((a: any) => a.id)

          Object.assign(payload.value, employee, {
            role_ids,
            area_ids,
            tag_ids,
          })

          if (employee.permissions?.destroy)
            moreOptions.value.push({
              id: 'delete',
              label: i18n.t('employee.edit_form.option_label_delete'),
              icon: 'delete/delete-16',
              classes: 'text-error-400',
            })
        })

    const loadEmployeeWithTracker = useTransaction({
      name: 'EmployeeLoad',
      op: 'fetchEmployee',
      description: 'loading employee',
      callback: loadEmployees,
    })

    loadEmployeeWithTracker()

    return {
      handleEdit,
      handleDelete,
      payload,
      moreOptions,
      handleSelection,
      isDeleteEmployeeeConfirmOpen,
    }
  },
})
