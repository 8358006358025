<template>
  <base-group-selection-toolbar-action-item
    :tooltip="
      value === 'active'
        ? $t('employee.index.body.active')
        : $t('employee.index.body.inactive')
    "
    tooltip-position="is-top"
  >
    <span
      class="block rounded-xl w-3 h-3"
      :class="value === 'active' ? 'bg-success-400' : 'bg-red-400'"
    ></span>
  </base-group-selection-toolbar-action-item>
</template>

<script>
import BaseGroupSelectionToolbarActionItem from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionItem'

export default {
  name: 'TableRowCellStatus',
  components: {
    BaseGroupSelectionToolbarActionItem,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
}
</script>
