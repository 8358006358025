import { RecordRelationship } from '@/utils/jsonapi/types'
import BaseRequest, {
  BaseRequestPagination,
  BaseRequestPermissions,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import {
  EMPLOYEE_ENDPOINT_LIST_EMPLOYEES,
  EMPLOYEE_ENDPOINT_CREATE_EMPLOYEE,
  EMPLOYEE_ENDPOINT_UPDATE_EMPLOYEE,
  EMPLOYEE_ENDPOINT_DELETE_EMPLOYEE,
  EMPLOYEE_ENDPOINT_SHOW_EMPLOYEE,
  EMPLOYEE_ENDPOINT_LIST_UNREGISTERED,
  EMPLOYEE_ENDPOINT_BATCH_INVITE,
  EMPLOYEE_ENDPOINT_GET_INVITE_LINK,
  EMPLOYEE_ENDPOINT_TRACKING_METHOD_LIST,
  EMPLOYEE_ENDPOINT_TARGET_HOURS_CREATE,
  EMPLOYEE_ENDPOINT_TARGET_HOURS_SHOW,
  EMPLOYEE_ENDPOINT_TARGET_HOURS_UPDATE,
  EMPLOYEE_ENDPOINT_TARGET_HOURS_LIST,
  EMPLOYEE_ENDPOINT_ABSENCES_LIST,
  EMPLOYEE_ENDPOINT_TARGET_HOURS_DELETE,
  EMPLOYEE_ENDPOINT_UPDATE_STATUS,
  EMPLOYEE_ENDPOINT_GET_DATA_PROFILE_LIST,
  EMPLOYEE_ENDPOINT_GET_DATA_PROFILE,
  EMPLOYEE_ENDPOINT_UPDATE_DATA_PROFILE,
  EMPLOYEE_ENDPOINT_GET_CONTRACT_SETTINGS,
  EMPLOYEE_ENDPOINT_GET_CONTRACT_SETTINGS_ATTRIBUTES,
  EMPLOYEE_ENDPOINT_UPDATE_CONTRACT_SETTINGS,
  EMPLOYEE_ENDPOINT_GET_RECORDS_SUMMARY,
  EMPLOYEE_ENDPOINT_GET_VACATIONS_SUMMARY,
  EMPLOYEE_ENDPOINT_GET_RECORDS,
  EMPLOYEE_ENDPOINT_GET_RECORD,
  EMPLOYEE_ENDPOINT_UPDATE_RECORD,
  EMPLOYEE_ENDPOINT_CREATE_VACATION,
  EMPLOYEE_ENDPOINT_LIST_VACATIONS,
  EMPLOYEE_ENDPOINT_GET_VACATION,
  EMPLOYEE_ENDPOINT_UPDATE_VACATION,
  EMPLOYEE_ENDPOINT_DELETE_VACATION,
  EMPLOYEE_ENDPOINT_TRANSFER_VACATION,
  EMPLOYEE_ENDPOINT_GET_VACATION_HISTORY,
} from '../network/employee.endpoints'
import { Role } from '@/app/core/user/models/role.model'
import {
  makeTargetHoursCreateAttributes,
  makeTargetHoursUpdateAttributes,
  makeDataProfileUpdateAttributes,
  generateDateRangeQueryParam,
} from './employee.model.utils'
export const EMPLOYEE_TYPE = 'user'
export const TIME_TRACKING_METHOD_TYPE = 'time_tracking_method'
export const TARGET_HOURS_TYPE = 'target_hour'
export const EMPLOYEE_REL_AREAS = 'areas'
export const EMPLOYEE_CUSTOM_FIELD_VALUES = 'custom_field_values'
export const DATA_PROFILE_TYPE = 'data_profile'
export const CUSTOM_FIELD_TYPE = 'custom_field'
export const CUSTOM_FIELD_VALUE_TYPE = 'custom_field_value'
export const CONTRACT_SETTINGS_VIEW_TYPE = 'contract_settings_view'
export const RECORDS_SUMMARY_TYPE = 'records_summary'
export const VACATION_SUMMARY_TYPE = 'vacation_summary'
export const RECORDS_TYPE = 'record'
export const VACATIONS_TYPE = 'vacation_time'

// TODO: move to area module
export interface Area {
  id: RecordId
  name: string
}

// TODO: move to tag module
export interface Tag {
  active: boolean
  color?: string
  external_id?: string
  title: string
}

// TODO: move to workspace module
export interface Workspace {
  id?: RecordId
  name: string
}

// TODO: rewrite BaseFilterbar with typescript and move this interface there
export interface Filter {
  field: string
  filter: Record<string, string>
  form: {
    value: any
  }
}

export interface TimeTrackingMethod {
  id: RecordId
  name: string
  localized_name: string
  disabled: boolean
}

interface Permissions extends BaseRequestPermissions {
  update_account_admin_role: boolean
  activate_deactivate?: boolean
}

export type EmployeeStatus = 'active' | 'inactive'

export interface EmployeeRaw {
  id: RecordId
  attributes: Record<string, unknown>
  relationships: RecordRelationship[]
}
export interface Employee {
  id?: RecordId
  // attributes
  username: string
  avatar?: string
  avatar_medium_url?: string
  avatar_thumb_url?: string
  email?: string
  abbreviation?: string
  area_ids?: RecordId[]
  role_ids?: RecordId[]
  tag_ids?: RecordId[]
  time_tracking_method_id?: RecordId | null
  permissions?: Permissions
  status?: EmployeeStatus
  // relationships
  areas?: Area[]
  roles?: Role[]
  tags?: Tag[]
  workspaces?: Workspace[]
  remaining_vacation_days_this_year?: number
  total_overtime_in_sec?: number
  vacation_summary?: number
  hour_holidays?: boolean
  [TIME_TRACKING_METHOD_TYPE]?: TimeTrackingMethod
  [EMPLOYEE_CUSTOM_FIELD_VALUES]?: CustomFieldValue[]
}

interface BaseEmployeeRecords {
  target: number
  plan: number
  actual: number
  absent: number
  compensation: number
}
export interface EmployeeRecords extends BaseEmployeeRecords {
  id: RecordId
  public_holiday: boolean
  difference: number
  current_balance: number
  vacation_title: string | null
  day: string
}
export interface EmployeeRecordItem extends BaseEmployeeRecords {
  absent_calc: number
  overlapping_absence: boolean
}

export type RhythmType = 'weekly' | 'free'

export interface BaseTargetHours {
  id?: RecordId
  rhythm_type: RhythmType
  start_date: string
  end_date?: string | null
  target_limited: boolean
  total_hours?: number
  reset_confirmed_absences: boolean
  permissions?: Permissions
}

export interface WeeklyTargetHours {
  per_day: boolean
  is_week: boolean
  is_month: boolean
  month: number
  is_mon: boolean
  is_tue: boolean
  is_wed: boolean
  is_thu: boolean
  is_fri: boolean
  is_sat: boolean
  is_sun: boolean
  mon: number
  tue: number
  wed: number
  thu: number
  fri: number
  sat: number
  sun: number
}

interface BaseContractSettings {
  custom_holiday_hours: number
  hours_on_vacation_day: number
  initial_time_account: number
  salary: number
  start_of_accounting: string
}

export interface ContractSettings extends BaseContractSettings {
  calc_holiday_options: string
  saldo_type: string
}

export type ContractSettingsSaldoType = { key: number; value: string }
export type ContractSettingsCalcHolidayOptions = { key: string; value: string }
export interface ContractSettingsEdit extends BaseContractSettings {
  saldo_type: ContractSettingsSaldoType
  saldo_type_options: ContractSettingsSaldoType[]
  calc_holiday_options: ContractSettingsCalcHolidayOptions
  calc_holiday_options_data: ContractSettingsCalcHolidayOptions[]
}

export interface CustomTargetHoursItem {
  id?: RecordId
  hours: number
  day_type: 'workday' | 'freeday'
}

export interface CustomTargetHours {
  target_working_days: CustomTargetHoursItem[]
}

export type TargetHours = BaseTargetHours &
  Either<WeeklyTargetHours, CustomTargetHours>

type CustomFieldCountryOption = [string, string]
export interface CustomFieldOption {
  id: RecordId
  name: string
}

export interface DataProfile {
  enabled: boolean
  id: RecordId
  title: string
  custom_fields: CustomField[]
  permissions?: Permissions
}

export interface CustomField {
  data_type: string
  id: RecordId
  label: string
  mandatory: boolean
  unique_key: RecordId
  value?: any
  custom_field_value_id?: RecordId
  multiselect?: boolean
  country_dropdown_options?: CustomFieldCountryOption[]
  dropdown_options?: CustomFieldOption[]
}

export interface CustomFieldValue {
  id: RecordId
  value: any
  custom_field_id: RecordId
  data_type: string
}

export interface EmployeeRecordsSummary {
  confirmed_total_overtime_in_sec: number
  total_overtime_in_sec: number
}

export interface EmployeeVacationsSummary {
  planned_vacation_days_this_year: number
  remaining_vacation_days_this_year: number
  hour_holidays: boolean
}

export interface EmployeeVacationsBase {
  title: string
  starts_at: string
  ends_at: string
  end_of_transfer_period: string | null
  planned_days: number
  transfer_remaining: boolean
  expires: boolean
}

export type EmployeeVacationsStatus =
  | 'used'
  | `doesn't expire`
  | 'expires'
  | 'expired'

export interface EmployeeVacations extends EmployeeVacationsBase {
  id?: RecordId
  status: EmployeeVacationsStatus
  spent_days: number
  transferred_days: number
  remaining_days: number
  transferred_to: RecordId | null
}

export interface EmployeeVacationsHistory {
  id: RecordId
  date_list: {
    date: string
    spent_days: number
  }[]
  title: string
  confirmed_by: string
  confirmed_at: string
}

export class EmployeeListRequest extends BaseRequest {
  constructor({
    workspaceId,
    pagination,
    sorting,
    filter,
  }: {
    workspaceId: RecordId
    pagination: BaseRequestPagination
    sorting: BaseRequestSorting
    filter: Record<string, unknown>
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_LIST_EMPLOYEES(workspaceId)
    super.pagination = pagination
    super.sorting = sorting
    super.filter = filter
    super.fields = {
      user: [
        'username',
        'email',
        'permissions',
        'status',
        'remaining_vacation_days_this_year',
        'total_overtime_in_sec',
        'hour_holidays',
        'filters',
        'working_areas',
        'roles',
        'companies',
        'custom_field_values',
      ].join(','),
    }
    super.include = 'companies,custom_field_values'
    super.permissions = {
      update: true,
      destroy: true,
    }
  }
}

export class EmployeeCreateRequest extends BaseRequest {
  constructor({
    workspaceId,
    employee,
  }: {
    workspaceId: RecordId
    employee: Employee
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_CREATE_EMPLOYEE(workspaceId)
    super.type = EMPLOYEE_TYPE
    super.method = 'post'
    super.attributes = employee
  }
}

export class EmployeeUpdateRequest extends BaseRequest {
  constructor({
    workspaceId,
    employee,
  }: {
    workspaceId: RecordId
    employee: Employee
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_EMPLOYEE(workspaceId, employee.id)
    super.type = EMPLOYEE_TYPE
    super.method = 'patch'
    super.permissions = {
      update: true,
      destroy: true,
      update_account_admin_role: true,
    }
    super.attributes = {
      username: employee.username,
      email: employee.email,
      role_ids: employee.role_ids,
      area_ids: employee.area_ids,
      tag_ids: employee.tag_ids,
      time_tracking_method_id: employee.time_tracking_method_id,
    }
  }
}

export class EmployeeEmailUpdateRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    email,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    email: string
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_EMPLOYEE(workspaceId, employeeId)
    super.type = EMPLOYEE_TYPE
    super.method = 'patch'
    super.permissions = {
      update: true,
      destroy: true,
      update_account_admin_role: true,
    }
    super.attributes = {
      email: email,
    }
  }
}

export class EmployeeAvatarUpdateRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    newPhotoFile,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    newPhotoFile: File
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_EMPLOYEE(workspaceId, employeeId)
    super.type = EMPLOYEE_TYPE
    super.method = 'patch'
    super.permissions = {
      update: true,
      destroy: true,
      update_account_admin_role: true,
    }
    const data = new FormData()
    data.append(`${EMPLOYEE_TYPE}[avatar]`, newPhotoFile)

    super.data = data
  }
}

export class EmployeeAvatarDeleteRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_EMPLOYEE(workspaceId, employeeId)
    super.type = EMPLOYEE_TYPE
    super.method = 'patch'
    super.permissions = {
      update: true,
      destroy: true,
      update_account_admin_role: true,
    }

    super.attributes = {
      avatar: null,
    }
  }
}

export class EmployeeRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_SHOW_EMPLOYEE(workspaceId, employeeId)
    super.include = 'companies,custom_field_values'
    super.permissions = {
      update: true,
      destroy: true,
      update_account_admin_role: true,
      activate_deactivate: true,
    }
  }
}

export class EmployeeDeleteRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_DELETE_EMPLOYEE(workspaceId, employeeId)
    super.method = 'delete'
  }
}

export class EmployeeUnregisteredListRequest extends BaseRequest {
  constructor({
    workspaceId,
    withEmail,
    pagination,
  }: {
    workspaceId: RecordId
    withEmail: boolean
    pagination: BaseRequestPagination
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_LIST_UNREGISTERED(workspaceId)

    if (withEmail) {
      super.filter = {
        email: `present:true`,
        with_pending_invitation: 'true',
      }
    } else {
      super.filter = {
        email: `present:false`,
        status: 'eq:active',
      }
    }
    super.pagination = pagination
  }
}

export class EmployeeInviteRequest extends BaseRequest {
  constructor({
    workspaceId,
    all,
    employeeIds,
    checkin,
    welcomeMessage,
  }: {
    workspaceId: RecordId
    all: boolean
    employeeIds: RecordId[]
    checkin: boolean
    welcomeMessage: string | null
  }) {
    super()
    super.type = 'batch_invitation'
    super.method = 'post'
    super.url = EMPLOYEE_ENDPOINT_BATCH_INVITE(workspaceId)
    super.attributes = {
      all,
      user_ids: employeeIds,
      checkin,
      welcome_message: welcomeMessage,
    }
  }
}

export class EmployeeGetInviteLink extends BaseRequest {
  constructor({
    workspaceId,
    checkin,
    welcomeMessage,
  }: {
    workspaceId: RecordId
    checkin: boolean
    welcomeMessage: string | null
  }) {
    super()
    super.type = 'invitation'
    super.method = 'post'
    super.url = EMPLOYEE_ENDPOINT_GET_INVITE_LINK(workspaceId)
    super.attributes = {
      checkin,
      welcome_message: welcomeMessage,
    }
  }
}

export class EmployeeTrackingMethodList extends BaseRequest {
  constructor({ workspaceId }: { workspaceId: RecordId }) {
    super()
    super.type = TIME_TRACKING_METHOD_TYPE
    super.url = EMPLOYEE_ENDPOINT_TRACKING_METHOD_LIST(workspaceId)
  }
}

export type TargetHoursCreateParams = Partial<TargetHours> &
  Pick<TargetHours, 'start_date'>

export class EmployeeTargetHoursCreate extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    targetHours,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    targetHours: TargetHoursCreateParams
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_TARGET_HOURS_CREATE(workspaceId, employeeId)
    super.type = TARGET_HOURS_TYPE
    super.method = 'post'
    super.attributes = makeTargetHoursCreateAttributes(targetHours)
  }
}

export class EmployeeTargetHoursList extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    pagination,
    filter,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    pagination: BaseRequestPagination
    filter: Record<string, unknown>
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_TARGET_HOURS_LIST(workspaceId, employeeId)
    super.type = TARGET_HOURS_TYPE
    super.sorting = { start_date: 1 }
    super.pagination = pagination
    super.filter = filter
    super.permissions = {
      update: true,
      destroy: true,
    }
  }
}

export class EmployeeAbsencesList extends BaseRequest {
  constructor({
    workspaceId,
    filter,
  }: {
    workspaceId: RecordId
    filter: {
      userId: RecordId
      startsAt: Date
      endsAt: Date
    }
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_ABSENCES_LIST(workspaceId)
    super.filter = {
      user_id: `eq:${filter.userId}`,
      overlapping_with_range: filter.endsAt
        ? `${filter.startsAt}_${filter.endsAt}`
        : filter.startsAt,
      confirmed: 'eq:true',
    }
  }
}

export class EmployeeTargetHoursRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    targetHoursId,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    targetHoursId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_TARGET_HOURS_SHOW(
      workspaceId,
      employeeId,
      targetHoursId
    )
    super.permissions = {
      update: true,
      destroy: true,
    }
  }
}

export type CustomTargetHoursUpdateItem = CustomTargetHoursItem & {
  isNew?: boolean
  toDestroy?: boolean
}
interface CustomTargetHoursUpdate {
  target_working_days: CustomTargetHoursUpdateItem[]
}
export type TargetHoursUpdateParams = BaseTargetHours &
  Either<WeeklyTargetHours, CustomTargetHoursUpdate>

export class EmployeeTargetHoursUpdate extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    targetHours,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    targetHours: TargetHoursUpdateParams
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_TARGET_HOURS_UPDATE(
      workspaceId,
      employeeId,
      targetHours.id
    )
    super.type = TARGET_HOURS_TYPE
    super.method = 'patch'
    super.attributes = makeTargetHoursUpdateAttributes(targetHours)
  }
}

export class EmployeeTargetHoursDeleteRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    targetHoursId,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    targetHoursId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_TARGET_HOURS_DELETE(
      workspaceId,
      employeeId,
      targetHoursId
    )
    super.method = 'delete'
  }
}

export class EmployeeUpdateStatusRequest extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    status,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    status: EmployeeStatus
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_STATUS(workspaceId, employeeId)
    super.method = 'patch'
    super.type = EMPLOYEE_TYPE
    super.attributes = {
      status,
    }
  }
}

export class EmployeeGetDataProfileListRequest extends BaseRequest {
  constructor(accountId: RecordId, workspaceId: RecordId) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_DATA_PROFILE_LIST(accountId, workspaceId)
    super.method = 'get'
    super.include = 'custom_fields'
    super.type = DATA_PROFILE_TYPE
    super.permissions = {
      update: true,
      destroy: true,
    }
  }
}

export class EmployeeGetDataProfileRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    dataProfileId,
  }: {
    userId: RecordId
    workspaceId: RecordId
    dataProfileId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_DATA_PROFILE(
      workspaceId,
      userId,
      dataProfileId
    )
    super.method = 'get'
    super.include = 'custom_field_values,custom_fields'
    super.type = DATA_PROFILE_TYPE
    super.permissions = {
      update: true,
      destroy: true,
    }
  }
}

export class EmployeeUpdateDataProfileRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    dataProfile,
  }: {
    userId: RecordId
    workspaceId: RecordId
    dataProfile: DataProfile
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_DATA_PROFILE(
      workspaceId,
      userId,
      dataProfile.id
    )
    super.method = 'patch'
    super.type = DATA_PROFILE_TYPE
    super.attributes = makeDataProfileUpdateAttributes(dataProfile)
  }
}
export class EmployeeGetContractSettings extends BaseRequest {
  constructor({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_CONTRACT_SETTINGS(workspaceId, userId)
    super.type = CONTRACT_SETTINGS_VIEW_TYPE
  }
}
export class EmployeeGetContractSettingsAttributes extends BaseRequest {
  constructor({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_CONTRACT_SETTINGS_ATTRIBUTES(
      workspaceId,
      userId
    )
    super.type = EMPLOYEE_TYPE
  }
}
export class EmployeeUpdateContractSettings extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    contractSettings,
  }: {
    userId: RecordId
    workspaceId: RecordId
    contractSettings: ContractSettingsEdit
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_CONTRACT_SETTINGS(workspaceId, userId)
    super.type = EMPLOYEE_TYPE
    super.method = 'patch'
    super.attributes = {
      start_of_accounting: contractSettings.start_of_accounting,
      initial_time_account: contractSettings.initial_time_account,
      saldo_type: contractSettings.saldo_type.key,
      salary: contractSettings.salary,
      calc_holiday_options: contractSettings.calc_holiday_options.key,
      custom_holiday_hours: contractSettings.custom_holiday_hours,
      hours_on_vacation_day: contractSettings.hours_on_vacation_day,
    }
  }
}
export class EmployeeGetRecordsSummary extends BaseRequest {
  constructor({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_RECORDS_SUMMARY(workspaceId, userId)
    super.type = RECORDS_SUMMARY_TYPE
  }
}
export class EmployeeGetVacationsSummary extends BaseRequest {
  constructor({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_VACATIONS_SUMMARY(workspaceId, userId)
    super.type = VACATION_SUMMARY_TYPE
  }
}

export class EmployeeGetRecords extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    sorting,
    filter,
    pagination,
  }: {
    userId: RecordId
    workspaceId: RecordId
    sorting: BaseRequestSorting
    filter: Record<string, Date[]>
    pagination: BaseRequestPagination
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_RECORDS(workspaceId, userId)
    super.type = RECORDS_TYPE
    super.sorting = sorting
    super.pagination = pagination

    if (filter.day) this.url += generateDateRangeQueryParam(filter.day)
  }
}
export class EmployeeGetRecord extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    recordId,
  }: {
    userId: RecordId
    workspaceId: RecordId
    recordId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_RECORD(workspaceId, userId, recordId)
    super.type = RECORDS_TYPE
    super.fields = {
      record:
        'target,plan,actual,absent,compensation,absent_calc,overlapping_absence',
    }
  }
}
export class EmployeeUpdateRecord extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    recordId,
    record,
  }: {
    userId: RecordId
    workspaceId: RecordId
    recordId: RecordId
    record: EmployeeRecordItem
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_RECORD(workspaceId, userId, recordId)
    super.type = 'evaluation'
    super.method = 'patch'
    super.attributes = {
      compensation: record.compensation,
      absent_calc: record.absent_calc,
    }
  }
}

export class EmployeeCreateVacationRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    vacation,
  }: {
    userId: RecordId
    workspaceId: RecordId
    vacation: EmployeeVacations
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_CREATE_VACATION(workspaceId, userId)
    super.type = VACATIONS_TYPE
    super.method = 'post'
    super.attributes = {
      title: vacation.title,
      starts_at: vacation.starts_at,
      ends_at: vacation.ends_at,
      planned_days: vacation.planned_days,
      transfer_remaining: vacation.transfer_remaining,
      end_of_transfer_period: vacation.end_of_transfer_period,
      expires: vacation.expires,
    }
  }
}
export class EmployeeListVacationsRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    pagination,
    sorting,
  }: {
    userId: RecordId
    workspaceId: RecordId
    pagination: BaseRequestPagination
    sorting: BaseRequestSorting
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_LIST_VACATIONS(workspaceId, userId)
    super.type = VACATIONS_TYPE
    super.pagination = pagination
    super.sorting = sorting
  }
}

export class EmployeeGetVacationRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    vacationId,
  }: {
    userId: RecordId
    workspaceId: RecordId
    vacationId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_VACATION(workspaceId, userId, vacationId)
    super.type = VACATIONS_TYPE
  }
}

export class EmployeeUpdateVacationRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    vacation,
  }: {
    userId: RecordId
    workspaceId: RecordId
    vacation: EmployeeVacations
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_VACATION(
      workspaceId,
      userId,
      vacation.id
    )
    super.type = VACATIONS_TYPE
    super.method = 'patch'
    super.attributes = {
      title: vacation.title,
      starts_at: vacation.starts_at,
      ends_at: vacation.ends_at,
      planned_days: vacation.planned_days,
      transfer_remaining: vacation.transfer_remaining,
      end_of_transfer_period: vacation.end_of_transfer_period,
      expires: vacation.expires,
    }
  }
}

export class EmployeeDeleteVacationRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    vacationId,
  }: {
    userId: RecordId
    workspaceId: RecordId
    vacationId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_DELETE_VACATION(
      workspaceId,
      userId,
      vacationId
    )
    super.method = 'delete'
    // This ignore wont break anything, this is required so the BE accepts the delete request
    // Once this task is done we can remove it https://app.asana.com/0/1201734167233877/1205581114327267/f
    //@ts-ignore
    super.data = undefined
  }
}

export class EmployeeTransferVacationRequest extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    vacationId,
  }: {
    userId: RecordId
    workspaceId: RecordId
    vacationId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_TRANSFER_VACATION(
      workspaceId,
      userId,
      vacationId
    )
    super.type = VACATIONS_TYPE
    super.method = 'patch'
    super.attributes = {}
  }
}

export class EmployeeGetVacationHistory extends BaseRequest {
  constructor({
    userId,
    workspaceId,
    vacationId,
  }: {
    userId: RecordId
    workspaceId: RecordId
    vacationId: RecordId
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_GET_VACATION_HISTORY(
      workspaceId,
      userId,
      vacationId
    )
    super.type = 'absence_vacation_time_ship'
  }
}

export class EmployeeUpdateHourHolidays extends BaseRequest {
  constructor({
    workspaceId,
    employeeId,
    hour_holidays,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    hour_holidays: boolean
  }) {
    super()
    super.url = EMPLOYEE_ENDPOINT_UPDATE_EMPLOYEE(workspaceId, employeeId)
    super.type = EMPLOYEE_TYPE
    super.method = 'patch'
    super.attributes = {
      hour_holidays,
    }
  }
}
