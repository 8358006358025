<template>
  <base-dropdown
    v-if="$isSmallDevice"
    :mobile-modal="false"
    :expanded="true"
    aria-role="list"
  >
    <template v-slot:trigger>
      <div
        data-id="employee.employee_details.tabs.trigger"
        class="flex items-center rounded-md py-1.5 px-3 cursor-pointer font-semibold bg-coal-80"
      >
        {{ selectedLabel }}
        <base-icon
          class="rotate-90 ml-auto"
          icon="arrow/arrow-16"
          size="1rem"
        />
      </div>
    </template>

    <base-dropdown-item
      v-for="tab in tabs"
      :key="tab.id"
      has-link
      :class="{ 'is-active': tab.id === selectedTabId }"
    >
      <router-link
        :to="{ name: tab.route }"
        :data-id="`employee.employee_details.tabs.${tab.id}`"
      >
        {{ tab.label }}
      </router-link>
    </base-dropdown-item>
  </base-dropdown>

  <ul v-else>
    <li v-for="tab in tabs" :key="tab.id">
      <router-link
        :to="{ name: tab.route }"
        :data-id="`employee.employee_details.tabs.${tab.id}`"
        exact-active-class="bg-coal-80 text-blue-400"
        class="block rounded-md p-2 mb-2 text-coal cursor-pointer font-medium"
      >
        {{ tab.label }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownItem from '@/app/util-modules/ui/dropdown/BaseDropdownItem.vue'
import { MODULE_NAME } from '../../employee.module'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
} from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'EmployeeTabs',
  components: { BaseIcon, BaseDropdown, BaseDropdownItem },
  computed: {
    ...mapGetters({
      isAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
      userPermissions: USER_GETTER_FEATURE_PERMISSIONS,
    }),
    isRecordsHidden() {
      if (this.isAdmin) return false

      return this.userPermissions('General').hide_records_for_employees
    },
    tabs() {
      const moduleName = this.$route.name.startsWith(PROFILE_MODULE_NAME)
        ? PROFILE_MODULE_NAME
        : MODULE_NAME

      const allTalbs = [
        {
          id: 'general',
          route: `${moduleName}.details`,
          label: this.$t('employee.employee_details.tabs.general'),
        },
        {
          id: 'contractData',
          route: `${moduleName}.details.targetHours`,
          label: this.$t('employee.employee_details.tabs.contract_data'),
        },
        {
          id: 'records',
          route: `${moduleName}.details.records`,
          label: this.$t('employee.employee_details.tabs.records'),
          hidden: this.isRecordsHidden,
        },
        {
          id: 'vacations',
          route: `${moduleName}.details.vacations`,
          label: this.$t('employee.employee_details.tabs.vacations'),
        },
      ]

      return allTalbs.filter((tab) => !tab.hidden)
    },
    selectedLabel() {
      const tab = this.tabs.find((tab) => tab.route === this.$route.name)
      return tab?.label
    },
    selectedTabId() {
      const tab = this.tabs.find((tab) => tab.route === this.$route.name)
      return tab?.id
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown::v-deep .dropdown-menu {
  @apply -mt-2 px-4;
}

.dropdown::v-deep .is-active {
  @apply bg-green-100 text-coal;
}
</style>
