import { render, staticRenderFns } from "./EmployeeGeneralDataSections.vue?vue&type=template&id=995d77cc&scoped=true&"
import script from "./EmployeeGeneralDataSections.vue?vue&type=script&lang=ts&"
export * from "./EmployeeGeneralDataSections.vue?vue&type=script&lang=ts&"
import style0 from "./EmployeeGeneralDataSections.vue?vue&type=style&index=0&id=995d77cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_lh6vo7dceoovl5fyh3epe3vcle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "995d77cc",
  null
  
)

export default component.exports