









import { defineComponent, computed } from '@vue/composition-api'
import VueI18n from 'vue-i18n'
import i18n from '@/utils/vendors/i18n/index'
import store from '@/store'
import BaseTabsHeader from '@/app/util-modules/ui/tabs/BaseTabsHeader.vue'
import { MODULE_NAME } from '../../employee.module'
import { EmployeeTab } from '../../store/employee.state'
import { EMPLOYEE_MUTATION_SET_CURRENT_TAB } from '../../store/mutations/employee.mutations.names'

interface EmployeeTabs {
  id: EmployeeTab
  label: VueI18n.TranslateResult
  labelTrigger: VueI18n.TranslateResult
  dataIdAttr: string
  count?: number
}

export default defineComponent({
  name: 'EmployeeTabsHeader',
  components: { BaseTabsHeader },
  setup() {
    const activeTab = computed(() => store.state[MODULE_NAME].selectedTab)
    const tabs = computed<EmployeeTabs[]>(() => [
      {
        id: 'all',
        label: i18n.t('employee.tabs.all'),
        labelTrigger: i18n.t('employee.tabs.all_trigger_label'),
        dataIdAttr: 'employee.tabs.all',
        //count: `${this.meta.total_count}`,
      },
      {
        id: 'active',
        label: i18n.t('employee.tabs.active'),
        labelTrigger: i18n.t('employee.tabs.active_trigger_label'),
        dataIdAttr: 'employee.tabs.active',
        //count: `${this.meta.total_active}`,
      },
      {
        id: 'inactive',
        label: i18n.t('employee.tabs.inactive'),
        labelTrigger: i18n.t('employee.tabs.inactive_trigger_label'),
        dataIdAttr: 'employee.tabs.inactive',
        //count: `${this.meta.total_inactive}`,
      },
    ])

    const changeTab = (id: string) => {
      store.commit(EMPLOYEE_MUTATION_SET_CURRENT_TAB, { tab: id })
    }

    return {
      activeTab,
      tabs,
      changeTab,
    }
  },
})
