











































import { defineComponent, computed, ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import LastUpdate from '../EmployeeLastUpdateText.vue'
import ContractSettingsEdit from './EmployeeRecordsContractSettingsEdit.vue'
import useUserId from '../../pages/composables/use-user-id'
import { formatDate } from '@/utils/date-time.utils'
import type { ContractSettings } from '../../models/employee.model'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import { EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS } from '../../store/actions/employee.actions.names'

export default defineComponent({
  name: 'EmployeeRecordsContractSettings',
  components: {
    BaseBadge,
    BaseButton,
    LastUpdate,
    ContractSettingsEdit,
  },
  props: {
    lastUpdatedAt: {
      type: String,
      default: '',
    },
    lastUpdatedBy: {
      type: String,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const { userId } = useUserId()
    const contractSettings = ref<ContractSettings | null>(null)
    const isOpen = ref(false)
    const isAdmin = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )

    const contractSettingList = computed(() => {
      if (!contractSettings.value) return []

      return [
        {
          id: 'start_of_accounting',
          label: i18n.t(
            'employee.employee_details.records.contract_settings.start_of_accounting'
          ),
          value: formatDate(
            new Date(contractSettings.value?.start_of_accounting)
          ),
        },
        {
          id: 'initial_time_account',
          label: i18n.t(
            'employee.employee_details.records.contract_settings.initial_time_account'
          ),
          value: contractSettings.value?.initial_time_account,
        },
      ]
    })

    const fetchContractSettings = () => {
      store
        .dispatch(EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS, userId.value)
        .then((response) => {
          contractSettings.value = response
        })
    }

    const refetchContractSettings = () => {
      emit('updated')
      fetchContractSettings()
    }

    fetchContractSettings()

    return {
      userId,
      isOpen,
      isAdmin,
      contractSettingList,

      fetchContractSettings,
      refetchContractSettings,
    }
  },
})
