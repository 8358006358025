








import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import EmployeeGeneral from '../components/employee-info/EmployeeGeneral.vue'
import { Employee } from '../models/employee.model'
import useDataProfileValues from './composables/use-data-profile-values'

export default defineComponent({
  name: 'EmployeeDetailsGeneralPage',
  components: {
    EmployeeGeneral,
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },
  setup(props) {
    const { employee } = toRefs(props)
    const dataProfileValues = useDataProfileValues(employee)

    return {
      dataProfileValues,
    }
  },
})
