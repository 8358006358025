








































import {
  defineComponent,
  PropType,
  ref,
  computed,
  toRefs,
  watch,
  nextTick,
} from '@vue/composition-api'
import store from '@/store'
import { logError } from '@/utils/helpers'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownItem from '@/app/util-modules/ui/dropdown/BaseDropdownItem.vue'
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox.vue'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import { MODULE_NAME } from '../../employee.module'
import { Role } from '@/app/core/user/models/role.model'

// TODO: move to user.model when #475 merged
const ADMIN_ROLE_NAMES: string[] = [
  'working_area_admin',
  'workspace_admin',
  'account_admin',
]

interface SelectedRole {
  [k: string]: Role['localized_name']
}
interface DisabledRole {
  [k: string]: boolean
}

export default defineComponent({
  name: 'EmployeeFormRoleSelect',
  components: {
    BaseTooltip,
    BaseDropdownItem,
    BaseDropdown,
    BaseCheckbox,
    BaseInputField,
  },
  // customize namings for custom v-model
  model: {
    prop: 'userRoles',
    event: 'input',
  },
  props: {
    userRoles: {
      type: Array as PropType<Role[]>,
      default: () => [],
    },
    canUpdateAccountAdminRole: {
      type: Boolean,
      default: false,
    },
    isTerminalApp: {
      type: Boolean,
      default: false,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const {
      canUpdateAccountAdminRole,
      isTerminalApp,
      userRoles,
      actionStatus,
    } = toRefs(props)

    const allRoles = computed<Role[]>(store.state[MODULE_NAME].records.role)

    const EMPLOYEE_ROLE: Role | undefined = computed(() =>
      allRoles.value.find((r) => r.name === 'employee')
    ).value
    const ACCOUNT_ADMIN_ROLE: Role | undefined = computed(() =>
      allRoles.value.find((r) => r.name === 'account_admin')
    ).value
    const WORKSPACE_ADMIN_ROLE: Role | undefined = computed(() =>
      allRoles.value.find((r) => r.name === 'workspace_admin')
    ).value

    // IDs of user's existing roles
    const userRoleIds = computed(() => userRoles.value.map((r) => r.id))

    // roles currently checked in checkbox list
    const selectedRoles = ref<SelectedRole>({})

    // roles currently disabled in checkbox list
    const disabledRoles = ref<DisabledRole>({})

    // whether any of admin roles are selected
    const hasAdminRole = computed<boolean>(() =>
      allRoles.value.some(
        (role) =>
          selectedRoles.value[role.id] && ADMIN_ROLE_NAMES.includes(role.name)
      )
    )

    // comma separated selectedRoles shown in input field
    const displayedRoles = computed(() =>
      Object.values(selectedRoles.value).join(', ')
    )

    // checks or unchecks given role checkbox
    const setSelected = (role: Role, checked: boolean) => {
      if (checked) {
        selectedRoles.value = {
          [role.id]: role.localized_name,
          ...selectedRoles.value,
        }
      } else {
        // remove unchecked role from selectedRoles object
        const { [role.id]: removed, ...rest } = selectedRoles.value
        selectedRoles.value = rest
      }
    }

    // sets employee checkbox state, because it's state is dependent
    // on admin role checkboxes
    const setEmployeeSelected = () => {
      if (EMPLOYEE_ROLE) {
        setSelected(EMPLOYEE_ROLE, !hasAdminRole.value)
      }
    }
    const setDisabledFields = () => {
      allRoles.value.forEach((role: Role) => {
        disabledRoles.value[role.id] = isFieldDisabled(role)
      })
    }

    // handles `input` event from checkbox list
    // emits `input` event with array of role IDs
    const handleInput = (role: Role, checked: boolean) => {
      setSelected(role, checked)
      setEmployeeSelected()
      setDisabledFields()
      emit('input', Object.keys(selectedRoles.value))
    }

    const isFieldDisabled = (role: Role) => {
      const accountId = ACCOUNT_ADMIN_ROLE?.id || -1
      const workspaceId = WORKSPACE_ADMIN_ROLE?.id || -1

      if (role.name === EMPLOYEE_ROLE?.name) return true
      else if (
        role.name === ACCOUNT_ADMIN_ROLE?.name &&
        !canUpdateAccountAdminRole.value
      )
        return true
      // A user can't have a role lower than account/workspace admin
      // if his tracking method is via_terminal_app
      else if (!isTerminalApp.value) return false
      else if (
        selectedRoles.value[accountId] &&
        selectedRoles.value[workspaceId]
      )
        return false
      else if (
        role.name === ACCOUNT_ADMIN_ROLE?.name &&
        selectedRoles.value[role.id] &&
        !selectedRoles.value[workspaceId]
      )
        return true
      else if (
        role.name === WORKSPACE_ADMIN_ROLE?.name &&
        selectedRoles.value[role.id] &&
        !selectedRoles.value[accountId]
      )
        return true

      return false
    }
    // sets state of checkbox list, called on component init
    const setState = () => {
      if (!allRoles.value.length) {
        logError('Role list is not available')
      }

      allRoles.value.forEach((role: Role) => {
        // mark role as checked when user already has the role
        const checked = userRoleIds.value.includes(role.id)
        setSelected(role, checked)

        disabledRoles.value[role.id] = isFieldDisabled(role)
      })
      setEmployeeSelected()
    }

    // when network request is completed, call setState()
    watch(
      () => actionStatus.value.isOk || false,
      (isRequestSuccessfull) => {
        if (isRequestSuccessfull) {
          // props are available on nextTick
          nextTick(setState)
        }
      },
      { immediate: true }
    )

    return {
      allRoles,
      selectedRoles,
      disabledRoles,
      displayedRoles,
      handleInput,
    }
  },
})
