




















import { defineComponent, computed, PropType } from '@vue/composition-api'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'EmployeeInfoSummary',

  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
    showNumberSymbol: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const isNegativeValue = computed(() => {
      return props.value < 0
    })

    const formattedValue = computed(() => {
      if (props.value > 0 && props.showNumberSymbol) {
        return `+${props.value.toFixed(2)}`
      }
      if (props.value < 0 && props.showNumberSymbol) {
        return `${props.value.toFixed(2)}`
      }
      return props.value
    })

    return { isNegativeValue, formattedValue }
  },
})
