import { AxiosPromise } from 'axios'
import axios from '@/utils/vendors/axios'
import {
  BaseRequestConfig,
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import { BaseResponseMeta } from '@/utils/api/base-response'
import {
  ContractSettingsEdit,
  Employee,
  EmployeeRaw,
  TargetHours,
  EmployeeStatus,
  EmployeeListRequest,
  EmployeeCreateRequest,
  EmployeeUpdateRequest,
  EmployeeEmailUpdateRequest,
  EmployeeAvatarUpdateRequest,
  EmployeeAvatarDeleteRequest,
  EmployeeRequest,
  EmployeeDeleteRequest,
  EmployeeUnregisteredListRequest,
  EmployeeInviteRequest,
  EmployeeGetInviteLink,
  EmployeeTrackingMethodList,
  EmployeeTargetHoursCreate,
  EmployeeTargetHoursRequest,
  EmployeeTargetHoursUpdate,
  EmployeeTargetHoursList,
  EmployeeAbsencesList,
  EmployeeTargetHoursDeleteRequest,
  TargetHoursUpdateParams,
  EmployeeUpdateStatusRequest,
  EmployeeGetDataProfileListRequest,
  EmployeeGetDataProfileRequest,
  EmployeeUpdateDataProfileRequest,
  DataProfile,
  EmployeeGetContractSettings,
  EmployeeGetContractSettingsAttributes,
  EmployeeUpdateContractSettings,
  EmployeeGetRecordsSummary,
  EmployeeGetVacationsSummary,
  EmployeeGetRecords,
  EmployeeGetRecord,
  EmployeeRecords,
  EmployeeRecordItem,
  EmployeeUpdateRecord,
  EmployeeCreateVacationRequest,
  EmployeeVacations,
  ContractSettings,
  EmployeeRecordsSummary,
  EmployeeVacationsSummary,
  EmployeeListVacationsRequest,
  EmployeeGetVacationRequest,
  EmployeeUpdateVacationRequest,
  EmployeeDeleteVacationRequest,
  EmployeeTransferVacationRequest,
  EmployeeGetVacationHistory,
  EmployeeVacationsHistory,
  EmployeeUpdateHourHolidays,
} from '../models/employee.model'
import { VacationsResponseMeta } from '../store/employee.state'
import { RecordRelationship } from '@/utils/jsonapi/types'

type ResponseObject<T> = {
  id: RecordId
  attributes: T
  type: string
}

export default {
  createEmployee(workspaceId: RecordId, employee: Employee): AxiosPromise {
    return axios.request(
      new EmployeeCreateRequest({
        workspaceId,
        employee,
      })
    )
  },

  listEmployees({
    workspaceId,
    sorting,
    filter,
    pagination,
  }: {
    workspaceId: RecordId
    sorting: BaseRequestSorting
    filter: Record<string, unknown>
    pagination: BaseRequestPagination
  }): AxiosPromise<{
    data: EmployeeRaw[]
    meta: BaseResponseMeta
    included: RecordRelationship[]
  }> {
    return axios.request(
      new EmployeeListRequest({ workspaceId, pagination, sorting, filter })
    )
    // return axios.request(
    //   mockRequest(
    //     new EmployeeListRequest({
    //       workspaceId: '1',
    //       pagination,
    //       sorting,
    //       filter,
    //     }),
    //     'https://2fa9b3d3-3d20-4e52-a7af-00956d662428.mock.pstmn.io/api/v3'
    //   )
    // )
  },

  updateEmployee(workspaceId: RecordId, employee: Employee) {
    return axios.request(new EmployeeUpdateRequest({ workspaceId, employee }))
  },

  updateEmployeeEmail(
    workspaceId: RecordId,
    { employeeId, email }: { employeeId: RecordId; email: string }
  ) {
    return axios.request(
      new EmployeeEmailUpdateRequest({ workspaceId, employeeId, email })
    )
  },

  updateEmployeeAvatar(
    workspaceId: RecordId,
    { employeeId, newPhotoFile }: { employeeId: RecordId; newPhotoFile: File }
  ) {
    return axios.request(
      new EmployeeAvatarUpdateRequest({ workspaceId, employeeId, newPhotoFile })
    )
  },

  deleteEmployeeAvatar(workspaceId: RecordId, employeeId: RecordId) {
    return axios.request(
      new EmployeeAvatarDeleteRequest({ workspaceId, employeeId })
    )
  },

  getEmployee(
    workspaceId: RecordId,
    employeeId: RecordId
  ): AxiosPromise<{ data: EmployeeRaw; included: RecordRelationship[] }> {
    return axios.request(new EmployeeRequest({ workspaceId, employeeId }))
    // return axios.request(
    //   mockRequest(
    //     new EmployeeRequest({ workspaceId: '1', employeeId }),
    //     'https://2fa9b3d3-3d20-4e52-a7af-00956d662428.mock.pstmn.io/api/v3'
    //   )
    // )
  },

  deleteEmployee(
    workspaceId: RecordId,
    employeeId: RecordId
  ): AxiosPromise<{ data: Employee }> {
    return axios.request(new EmployeeDeleteRequest({ workspaceId, employeeId }))
  },

  listUnregisteredEmployees(
    workspaceId: RecordId,
    {
      withEmail,
      pagination,
    }: { withEmail: boolean; pagination: BaseRequestPagination }
  ): AxiosPromise<{ data: Employee[]; meta: BaseResponseMeta }> {
    return axios.request(
      new EmployeeUnregisteredListRequest({
        workspaceId,
        withEmail,
        pagination,
      })
    )
  },

  inviteEmployees(
    workspaceId: RecordId,
    {
      all,
      employeeIds,
      checkin,
      welcomeMessage,
    }: {
      all: boolean
      employeeIds: RecordId[]
      checkin: boolean
      welcomeMessage: string | null
    }
  ): AxiosPromise {
    return axios.request(
      new EmployeeInviteRequest({
        workspaceId,
        all,
        employeeIds,
        checkin,
        welcomeMessage,
      })
    )
  },

  getInviteLink(
    workspaceId: RecordId,
    {
      checkin,
      welcomeMessage,
    }: {
      checkin: boolean
      welcomeMessage: string | null
    }
  ): AxiosPromise {
    return axios.request(
      new EmployeeGetInviteLink({
        workspaceId,
        checkin,
        welcomeMessage,
      })
    )
  },

  listTrackingMethods(workspaceId: RecordId) {
    return axios.request(new EmployeeTrackingMethodList({ workspaceId }))
  },

  createTargetHours(
    workspaceId: RecordId,
    employeeId: RecordId,
    targetHours: TargetHours
  ) {
    return axios.request(
      new EmployeeTargetHoursCreate({ workspaceId, employeeId, targetHours })
    )
  },

  getTargetHours(
    workspaceId: RecordId,
    employeeId: RecordId,
    targetHoursId: RecordId
  ): AxiosPromise<{ data: TargetHours }> {
    return axios.request(
      new EmployeeTargetHoursRequest({ workspaceId, employeeId, targetHoursId })
    )
  },

  updateTargetHours(
    workspaceId: RecordId,
    employeeId: RecordId,
    targetHours: TargetHoursUpdateParams
  ) {
    return axios.request(
      new EmployeeTargetHoursUpdate({ workspaceId, employeeId, targetHours })
    )
  },

  listTargetHours({
    workspaceId,
    employeeId,
    pagination,
    filter,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    pagination: BaseRequestPagination
    filter: Record<string, unknown>
  }) {
    return axios.request(
      new EmployeeTargetHoursList({
        workspaceId,
        employeeId,
        pagination,
        filter,
      })
    )
  },

  listEmployeeAbsences(
    workspaceId: RecordId,
    filter: {
      userId: RecordId
      startsAt: Date
      endsAt: Date
    }
  ) {
    return axios.request(
      new EmployeeAbsencesList({
        workspaceId,
        filter,
      })
    )
  },

  deleteTargetHours({
    workspaceId,
    employeeId,
    targetHoursId,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    targetHoursId: RecordId
  }): AxiosPromise<{ data: TargetHours }> {
    return axios.request(
      new EmployeeTargetHoursDeleteRequest({
        workspaceId,
        employeeId,
        targetHoursId,
      })
    )
  },

  updateEmployeeStatus({
    workspaceId,
    employeeId,
    status,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    status: EmployeeStatus
  }): AxiosPromise<{ data: Employee }> {
    return axios.request(
      new EmployeeUpdateStatusRequest({
        workspaceId,
        employeeId,
        status,
      })
    )
  },

  getEmployeeDataProfileList(
    accountId: RecordId,
    workspaceId: RecordId
  ): AxiosPromise<{ data: DataProfile; included: RecordRelationship[] }> {
    return axios.request(
      new EmployeeGetDataProfileListRequest(accountId, workspaceId)
    )
  },

  getEmployeeDataProfile({
    userId,
    workspaceId,
    dataProfileId,
  }: {
    userId: RecordId
    workspaceId: RecordId
    dataProfileId: RecordId
  }): AxiosPromise<{ data: DataProfile; included: RecordRelationship[] }> {
    return axios.request(
      new EmployeeGetDataProfileRequest({
        workspaceId,
        userId,
        dataProfileId,
      })
    )
  },

  updateEmployeeDataProfile({
    userId,
    workspaceId,
    dataProfile,
  }: {
    userId: RecordId
    workspaceId: RecordId
    dataProfile: DataProfile
  }): AxiosPromise<{ data: DataProfile; included: RecordRelationship[] }> {
    return axios.request(
      new EmployeeUpdateDataProfileRequest({
        workspaceId,
        userId,
        dataProfile,
      })
    )
  },

  getEmployeeContractSettings({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }): AxiosPromise<{ data: ResponseObject<ContractSettings> }> {
    return axios.request(
      new EmployeeGetContractSettings({
        userId: userId,
        workspaceId: workspaceId,
      })
    )
  },

  getEmployeeContractSettingsAttributes({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }): AxiosPromise<{ data: ResponseObject<ContractSettingsEdit> }> {
    return axios.request(
      new EmployeeGetContractSettingsAttributes({
        userId,
        workspaceId,
      })
    )
  },

  updateEmployeeContractSettings({
    userId,
    workspaceId,
    contractSettings,
  }: {
    userId: RecordId
    workspaceId: RecordId
    contractSettings: ContractSettingsEdit
  }): AxiosPromise<{ data: ResponseObject<ContractSettings> }> {
    return axios.request(
      new EmployeeUpdateContractSettings({
        userId,
        workspaceId,
        contractSettings,
      })
    )
  },

  getEmployeeRecordsSummary({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }): AxiosPromise<{ data: ResponseObject<EmployeeRecordsSummary> }> {
    return axios.request(
      new EmployeeGetRecordsSummary({
        userId: userId,
        workspaceId: workspaceId,
      })
    )
  },

  getEmployeeVacationsSummary({
    userId,
    workspaceId,
  }: {
    userId: RecordId
    workspaceId: RecordId
  }): AxiosPromise<{ data: ResponseObject<EmployeeVacationsSummary> }> {
    return axios.request(
      new EmployeeGetVacationsSummary({
        userId: userId,
        workspaceId: workspaceId,
      })
    )
  },
  getEmployeeRecords({
    userId,
    workspaceId,
    filter,
    sorting,
    pagination,
  }: {
    workspaceId: RecordId
    userId: RecordId
    sorting: BaseRequestSorting
    filter: Record<string, Date[]>
    pagination: BaseRequestPagination
  }): AxiosPromise<{
    data: ResponseObject<EmployeeRecords>[]
    meta: BaseResponseMeta
  }> {
    return axios.request(
      new EmployeeGetRecords({
        userId,
        workspaceId,
        sorting,
        filter,
        pagination,
      })
    )
  },

  getEmployeeRecord({
    userId,
    workspaceId,
    recordId,
  }: {
    workspaceId: RecordId
    userId: RecordId
    recordId: RecordId
  }): AxiosPromise<{
    data: ResponseObject<EmployeeRecordItem>
    meta: BaseResponseMeta
  }> {
    return axios.request(
      new EmployeeGetRecord({
        userId,
        workspaceId,
        recordId,
      })
    )
  },

  updateEmployeeRecord({
    userId,
    workspaceId,
    recordId,
    record,
  }: {
    userId: RecordId
    workspaceId: RecordId
    recordId: RecordId
    record: EmployeeRecordItem
  }): AxiosPromise<{
    data: ResponseObject<EmployeeRecordItem>
    meta: BaseResponseMeta
  }> {
    return axios.request(
      new EmployeeUpdateRecord({
        userId,
        workspaceId,
        recordId,
        record,
      })
    )
  },

  createEmployeeVacation({
    userId,
    workspaceId,
    vacation,
  }: {
    userId: RecordId
    workspaceId: RecordId
    vacation: EmployeeVacations
  }): AxiosPromise<{
    data: ResponseObject<EmployeeRecordItem>
    meta: BaseResponseMeta
  }> {
    return axios.request(
      new EmployeeCreateVacationRequest({
        workspaceId,
        userId,
        vacation,
      })
    )
  },

  listEmployeeVacations({
    userId,
    workspaceId,
    pagination,
    sorting,
  }: {
    workspaceId: RecordId
    userId: RecordId
    pagination: BaseRequestPagination
    sorting: BaseRequestSorting
  }): AxiosPromise<{
    data: ResponseObject<EmployeeVacations>[]
    meta: VacationsResponseMeta
  }> {
    return axios.request(
      new EmployeeListVacationsRequest({
        userId,
        workspaceId,
        pagination,
        sorting,
      })
    )
  },

  getEmployeeVacation({
    userId,
    workspaceId,
    vacationId,
  }: {
    workspaceId: RecordId
    userId: RecordId
    vacationId: RecordId
  }): AxiosPromise<{
    data: ResponseObject<EmployeeVacations>[]
  }> {
    return axios.request(
      new EmployeeGetVacationRequest({
        userId,
        workspaceId,
        vacationId,
      })
    )
  },

  updateEmployeeVacation({
    userId,
    workspaceId,
    vacation,
  }: {
    workspaceId: RecordId
    userId: RecordId
    vacation: EmployeeVacations
  }): AxiosPromise<{
    data: ResponseObject<EmployeeVacations>
  }> {
    return axios.request(
      new EmployeeUpdateVacationRequest({
        userId,
        workspaceId,
        vacation,
      })
    )
  },

  deleteEmployeeVacation({
    userId,
    workspaceId,
    vacationId,
  }: {
    workspaceId: RecordId
    userId: RecordId
    vacationId: RecordId
  }): AxiosPromise<{
    data: ResponseObject<EmployeeVacations>[]
  }> {
    return axios.request(
      new EmployeeDeleteVacationRequest({
        userId,
        workspaceId,
        vacationId,
      })
    )
  },

  transferEmployeeVacation({
    userId,
    workspaceId,
    vacationId,
  }: {
    workspaceId: RecordId
    userId: RecordId
    vacationId: RecordId
  }): AxiosPromise<{
    data: ResponseObject<EmployeeVacations>
  }> {
    return axios.request(
      new EmployeeTransferVacationRequest({
        userId,
        workspaceId,
        vacationId,
      })
    )
  },

  getEmployeeVacationHistory({
    userId,
    workspaceId,
    vacationId,
  }: {
    workspaceId: RecordId
    userId: RecordId
    vacationId: RecordId
  }): AxiosPromise<{
    data: ResponseObject<EmployeeVacationsHistory>[]
    meta: BaseResponseMeta
  }> {
    return axios.request(
      new EmployeeGetVacationHistory({
        userId,
        workspaceId,
        vacationId,
      })
    )
  },

  updateEmployeeHourHolidays({
    workspaceId,
    employeeId,
    hour_holidays,
  }: {
    workspaceId: RecordId
    employeeId: RecordId
    hour_holidays: boolean
  }): AxiosPromise<{
    data: ResponseObject<Employee>
    meta: BaseResponseMeta
  }> {
    return axios.request(
      new EmployeeUpdateHourHolidays({
        workspaceId,
        employeeId,
        hour_holidays,
      })
    )
  },
}

// TODO: move to global utils
// create mock request to be used with axios.request()
// `err` will be passed as URL param, so that mock
// server can return mocked error response
export function mockRequest(
  request: BaseRequestConfig,
  baseUrl: string = 'https://2fa9b3d3-3d20-4e52-a7af-00956d662428.mock.pstmn.io/api/v3',
  mockOptionWhitelist: string[] = [],
  option?: string
) {
  request.baseURL = baseUrl

  if (option && mockOptionWhitelist.includes(option)) {
    request.mockOption = option
  }
  return request
}
