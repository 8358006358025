














































































































































import { defineComponent, computed, PropType } from '@vue/composition-api'
import { extend } from 'vee-validate'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import BaseDatePicker from '@/app/util-modules/ui/date-picker/BaseDatePicker.vue'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'
import i18n from '@/utils/vendors/i18n'
import { isPast } from 'date-fns'
import { EmployeeVacations } from '../../models/employee.model'

type EndDateRule = { starts_at: Date; ends_at: Date }
extend('ends_date', {
  params: ['starts_at', 'ends_at'],
  validate: (_, params) => {
    const { starts_at, ends_at } = params as EndDateRule

    if (starts_at > ends_at)
      return i18n.t(
        'employee.employee_details.vacations.form.validation_message.start_date_earlier_than_end_date'
      ) as string
    else return true
  },
})

type DisabledFields = Record<string, boolean>

export default defineComponent({
  name: 'EmployeeVacationsForm',
  components: {
    BaseIcon,
    BaseInputField,
    BaseDatePicker,
    BaseField,
    BaseToggle,
    BaseInputNumber,
  },
  props: {
    value: {
      type: Object as PropType<EmployeeVacations>,
      required: true,
    },
    disabledFields: {
      type: Object as PropType<DisabledFields>,
      default: null,
    },
    hideHint: {
      type: Boolean,
      default: false,
    },
    hideRemainingDaysGroup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleInput = ($event: any) => {
      emit('input', { ...props.value, ...$event })
      emit('updated')
    }

    const endsAtValidationRule = computed(() => ({
      ends_date: {
        starts_at: props.value.starts_at,
        ends_at: props.value.ends_at,
      },
    }))

    const isVacationPast = computed(() => isPast(new Date(props.value.ends_at)))

    return { isVacationPast, endsAtValidationRule, handleInput }
  },
})
