

























import { defineComponent, computed, ref } from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n/index'
import { MODULE_NAME } from '../../employee.module'
import store from '@/store'
import {
  EMPLOYEE_TARGET_HOURS_MUTATION_ADD_FILTER,
  EMPLOYEE_TARGET_HOURS_MUTATION_REMOVE_FILTER,
} from '../../store/mutations/employee.mutations.names'
import { Filter } from '../../models/employee.model'
import { BASE_GETTER_IS_SMALL_DEVICE } from '@/store/getters/store.getters.names'
import BaseFilterbar from '@/app/util-modules/ui/filterbar/BaseFilterbar.vue'
import HeaderActions from './EmployeeTargetHoursListHeaderActions.vue'

export default defineComponent({
  name: 'EmployeeTargetHoursListHeader',
  components: { BaseFilterbar, HeaderActions },
  props: {
    canCreateTargetHours: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isMobileFilterbarShown = ref(false)

    const selectedFilters = computed(() => {
      return store.state[MODULE_NAME].targetHoursFilters
    })

    const selectedFilterNames = computed(() => {
      return Object.keys(selectedFilters.value)
    })

    const filterbarShown = computed(() => {
      if (!store.getters[BASE_GETTER_IS_SMALL_DEVICE]) return true
      return (
        isMobileFilterbarShown.value || selectedFilterNames.value.length > 0
      )
    })

    const filters = computed(() => {
      return [
        {
          field: 'rhythm',
          label: () => i18n.t('employee.target_hours.filterbar.rhythm.label'),
          type: 'radio',
          params: {
            options: [
              {
                label: () =>
                  i18n.t('employee.target_hours.filterbar.rhythm.weekly'),
                value: 'weekly',
              },
              {
                label: () =>
                  i18n.t('employee.target_hours.filterbar.rhythm.monthly'),
                value: 'monthly',
              },
              {
                label: () =>
                  i18n.t('employee.target_hours.filterbar.rhythm.custom'),
                value: 'custom',
              },
            ],
          },
        },
        {
          field: 'target_limited',
          label: () => i18n.t('employee.target_hours.filterbar.exceed.label'),
          type: 'radio',
          params: {
            options: [
              {
                label: () =>
                  i18n.t('employee.target_hours.filterbar.exceed.allowed'),
                value: 'false',
              },
              {
                label: () =>
                  i18n.t('employee.target_hours.filterbar.exceed.not_allowed'),
                value: 'true',
              },
            ],
          },
        },
      ]
    })

    const addFilter = (filter: Filter) =>
      store.commit(EMPLOYEE_TARGET_HOURS_MUTATION_ADD_FILTER, filter)

    const removeFilter = (filter: Filter) =>
      store.commit(EMPLOYEE_TARGET_HOURS_MUTATION_REMOVE_FILTER, filter)

    return {
      isMobileFilterbarShown,
      selectedFilters,
      selectedFilterNames,
      filterbarShown,
      filters,

      addFilter,
      removeFilter,
    }
  },
})
