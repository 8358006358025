var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-form-validation',{attrs:{"action-status":_vm.actionStatus},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var handleInputChange = ref.handleInputChange;
var handleSubmit = ref.handleSubmit;
return [_c('div',{on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.validate(handleSubmit)}}},[_c('bottom-sheet',{attrs:{"header-title":_vm.title,"header-right-actions":[
          {
            title: _vm.submitButtonText,
            event: 'submit',
            loading: _vm.actionStatus.isLoading,
            class: 'justify-self-end text-green',
          } ]},on:{"cancel-click":function($event){return _vm.$emit('close')},"submit":function($event){return _vm.validate(handleSubmit)}}},[_c('base-form-validation-messages',{staticClass:"mx-4 mt-4 mb-2",attrs:{"action-status":_vm.actionStatus}}),_c('form-body',{attrs:{"form-fields":_vm.payload},on:{"input":function($event){return _vm.$emit('input', $event)},"updated":function($event){return handleInputChange(true)}}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }