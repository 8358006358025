




















import { defineComponent, computed } from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n/index'
import { formatDate } from '@/utils/date-time.utils'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import HelpText from './EmployeeTargetHoursHelpText.vue'

export default defineComponent({
  name: 'EmployeeTargetHoursEndDate',
  components: {
    BaseField,
    HelpText,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const formattedEndDate = computed(() => {
      if (props.value == null) {
        return i18n.t('employee.target_hours.form.no_end_date')
      } else {
        return formatDate(new Date(props.value))
      }
    })

    const helpShown = computed(() => {
      return props.value != null
    })

    return {
      formattedEndDate,
      helpShown,
    }
  },
})
