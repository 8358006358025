import type { GetterTree } from 'vuex'
import type { TranslateResult } from 'vue-i18n'
import { shortname } from '@/utils/store'
import { RootState } from '@/store'
import { TAG_PUBLIC_GETTER_TAG_LIST_SELECTED } from '@/app/core/tag/store/getters/tag.getters.names'
import { AREA_PUBLIC_GETTER_AREA_LIST_SELECTED_EXCLUDE_WITHOUT_AREAS } from '@/app/core/area/store/getters/area.getters.names'
import { USER_PUBLIC_GETTER_PREFERENCE } from '@/app/core/user/store/getters/user.getters.names'
import type { EmployeeState, EmployeeTab } from '../employee.state'
import * as getterNamesObj from './employee.getters.names'
import {
  CustomField,
  CUSTOM_FIELD_TYPE,
  VACATIONS_TYPE,
} from '../../models/employee.model'
import { runOrReturn } from '@/utils/helpers'
import { TABLE_COLUMNS } from '../../pages/composables/use-table-columns'
import { MAX_COLUMNS_LENGTH } from '../../constants'

type ActiveFilters = Record<string, string | string[]>
type TableColumn =
  | CustomField
  | {
      unique_key: string
      label: () => TranslateResult
    }
interface UserPreference {
  id: RecordId
  value: Record<string, boolean>
  key?: string
}

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.EMPLOYEE_GETTER_ACTIVE_FILTERS]: (
    state: EmployeeState,
    _getters: GetterTree<EmployeeState, RootState>,
    _rootState: RootState,
    rootGetters: GetterTree<EmployeeState, RootState>
  ) => {
    let activeFilters: ActiveFilters = {}
    const tab: EmployeeTab = state.selectedTab
    const tags = rootGetters[TAG_PUBLIC_GETTER_TAG_LIST_SELECTED]
    const areas =
      rootGetters[AREA_PUBLIC_GETTER_AREA_LIST_SELECTED_EXCLUDE_WITHOUT_AREAS]

    if (Array.isArray(tags)) {
      activeFilters.tags = tags
    }
    if (Array.isArray(areas)) {
      activeFilters.areas = areas
    }

    Object.keys(state.filters).forEach((key) => {
      activeFilters = {
        ...activeFilters,
        ...state.filters[key].filter,
      }
    })

    if (tab === 'active') {
      activeFilters.status = 'eq:active'
    } else if (tab === 'inactive') {
      activeFilters.status = 'eq:inactive'
    }

    return activeFilters
  },

  [getterNames.EMPLOYEE_GETTER_CUSTOM_COLUMNS]: (
    state: EmployeeState,
    getters: Record<string, UserPreference>
  ) => {
    const allCustomFields = state.records[CUSTOM_FIELD_TYPE]
    const preferences =
      getters[getterNames.EMPLOYEE_GETTER_LIMITED_COLUMNS]?.value

    if (!preferences) return []
    return allCustomFields.filter((field) => preferences[field.unique_key])
  },

  [getterNames.EMPLOYEE_PUBLIC_GETTER_GET_BY_ID]:
    (state: EmployeeState) => (id: RecordId) => {
      return state.records.user.find((user) => user.id === id)
    },

  [getterNames.EMPLOYEE_TARGET_HOURS_GETTER_ACTIVE_FILTERS]: (
    state: EmployeeState
  ) => {
    let activeFilters = {}

    Object.keys(state.targetHoursFilters).forEach((key) => {
      const { field, filter, form } = state.targetHoursFilters[key]
      let newFilter = filter

      if (field === 'rhythm') {
        const rhythmFilters = makeRhythmFilter(form.value)

        if (rhythmFilters) {
          newFilter = rhythmFilters
        }
      }

      activeFilters = {
        ...activeFilters,
        ...newFilter,
      }
    })

    return activeFilters
  },

  [getterNames.EMPLOYEE_GETTER_LIMITED_COLUMNS]: (
    _state: EmployeeState,
    _getters: GetterTree<EmployeeState, RootState>,
    _rootState: RootState,
    rootGetters: any
  ) => {
    const userPreference =
      rootGetters[USER_PUBLIC_GETTER_PREFERENCE]('employee_list')

    if (!userPreference) return {}

    const allVisibleColumnsSize = Object.values(userPreference.value).filter(
      (p) => p
    ).length

    if (allVisibleColumnsSize <= MAX_COLUMNS_LENGTH) return userPreference

    const defaultColumns: Record<string, boolean> = TABLE_COLUMNS.reduce(
      (acc, col) => ({ ...acc, [col.unique_key]: true }),
      {}
    )

    const onlyDefaultColumnsVisible = Object.keys(userPreference.value).reduce(
      (init, unique_key) => ({
        ...init,
        [unique_key]: Boolean(defaultColumns[unique_key]),
      }),
      {}
    )

    return {
      id: userPreference.id,
      value: onlyDefaultColumnsVisible,
    }
  },

  [getterNames.EMPLOYEE_GETTER_ALL_COLUMNS_SORTED]: (
    state: EmployeeState,
    getters: Record<string, UserPreference>
  ) => {
    const customColumns = state.records[CUSTOM_FIELD_TYPE]
    const allColumns = [...TABLE_COLUMNS, ...customColumns]
    const selectedColumns =
      getters[getterNames.EMPLOYEE_GETTER_LIMITED_COLUMNS].value || {}

    const sortedSelectedColumns = allColumns
      .filter(checkSelectedColumn)
      .sort(sortByLabel)

    const sortedUnselectedColumns = allColumns
      .filter(checkUnselectedColumn)
      .sort(sortByLabel)

    function checkSelectedColumn(c: TableColumn) {
      return Boolean(selectedColumns[c.unique_key])
    }
    function checkUnselectedColumn(c: TableColumn) {
      return Boolean(selectedColumns[c.unique_key] === false)
    }

    function sortByLabel(a: TableColumn, b: TableColumn) {
      return runOrReturn(a.label).localeCompare(runOrReturn(b.label))
    }

    return [...sortedSelectedColumns, ...sortedUnselectedColumns]
  },

  [getterNames.EMPLOYEE_RECORDS_GETTER_ACTIVE_FILTERS]: (
    state: EmployeeState
  ) => {
    const activeFilters: Record<string, unknown> = {}
    const dateRange = state.recordsFilterDateRange

    if (Array.isArray(dateRange)) {
      activeFilters.day = dateRange
    }

    return activeFilters
  },

  [getterNames.EMPLOYEE_VACATIONS_GETTER_IS_TRANSFERRED]:
    (state: EmployeeState) =>
    (vacationId: RecordId): boolean =>
      state.records[VACATIONS_TYPE].some(
        (vacation) => vacation.transferred_to == vacationId
      ),
}

function makeRhythmFilter(rhythm: string) {
  if (rhythm === 'custom') {
    return {
      // 'eq:1' means 'eq:free', however 'eq:free' is not working
      // in backend, so we use this workaround
      rhythm_type: 'eq:1',

      // TODO: following 2 fields are not necessary, but Typescript
      // complains if they are missing. Need to find out solution
      is_week: 'eq:true',
      is_month: 'eq:false',
    }
  } else if (rhythm === 'weekly') {
    return {
      rhythm_type: 'eq:weekly',
      is_week: 'eq:true',
      is_month: 'eq:false',
    }
  } else if (rhythm === 'monthly') {
    return {
      rhythm_type: 'eq:weekly',
      is_week: 'eq:false',
      is_month: 'eq:true',
    }
  }
}
