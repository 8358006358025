var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$isSmallDevice && _vm.contractSettings)?_c('base-modal',{attrs:{"has-modal-card":""}},[_c('base-card-modal-form',{attrs:{"title":_vm.$t('employee.employee_details.records.contract_settings.edit_title'),"button-submit-text":_vm.$t('employee.employee_details.records.contract_settings.submit_button'),"action-status":_vm.$actions.updateContractSettings,"loading":_vm.$actions.updateContractSettings.isLoading},on:{"submit":function($event){return _vm.handleSubmit()},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleInputChange = ref.handleInputChange;
return [_c('edit-form',{attrs:{"value":_vm.contractSettings,"is-start-of-accounting-disabled":_vm.isStartOfAccountingDisabled},on:{"updated":function($event){return handleInputChange(true)},"input":function($event){_vm.contractSettings = $event}}})]}}],null,false,3042108041)})],1):(_vm.$isSmallDevice && _vm.contractSettings)?_c('bottom-sheet-form-wrapper',{attrs:{"header-title":_vm.$t('employee.employee_details.records.contract_settings.edit_title'),"header-right-actions":[
    {
      title: _vm.$t(
        'employee.employee_details.records.contract_settings.submit_button'
      ),
      loading: _vm.$actions.updateContractSettings.isLoading,
      class: 'justify-self-end text-green',
      event: 'submit',
    } ],"action-status":_vm.$actions.updateContractSettings},on:{"close":function($event){return _vm.$emit('close')},"submit":function($event){return _vm.handleSubmit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var handleInputChange = ref.handleInputChange;
return [_c('edit-form',{staticClass:"p-4",attrs:{"value":_vm.contractSettings,"is-start-of-accounting-disabled":_vm.isStartOfAccountingDisabled},on:{"updated":function($event){return handleInputChange(true)},"input":function($event){_vm.contractSettings = $event}}})]}}])}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }