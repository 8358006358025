<template>
  <base-modal has-modal-card>
    <base-card-modal-form
      v-slot="{ handleInputChange, submitCount }"
      :title="title"
      :button-submit-text="submitButtonText"
      :action-status="actionStatus"
      :loading="actionStatus.isLoading"
      :footer-more-options="moreOptions"
      data-id-prefix="employee.form"
      @selected="$emit('selected', $event)"
      @submit="$emit('submit')"
      @close="$emit('close')"
    >
      <form-body
        :value="payload"
        :preload-action-status="preloadActionStatus"
        :submit-count="submitCount"
        :edit-mode="editMode"
        @input="$emit('input', $event)"
        @updated="handleInputChange(true)"
      />
    </base-card-modal-form>
  </base-modal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import FormBody from '../employee-form/EmployeeFormBody.vue'

export default {
  name: 'EmployeeFormModal',
  components: {
    BaseModal,
    BaseCardModalForm,
    FormBody,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
    preloadActionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
::v-deep .base-card-modal {
  @apply overflow-visible;
}
</style>
