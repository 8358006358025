






















import round from 'lodash/round'
import { defineComponent, PropType, computed } from '@vue/composition-api'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import {
  TargetHours,
  CustomTargetHoursItem,
} from '../../../models/employee.model'
import i18n from '@/utils/vendors/i18n/index'
import { getWeekdays, objectHasOwnProperty } from '../../employee.utils'

export default defineComponent({
  name: 'TableRowCellSummary',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    value: {
      type: Object as PropType<TargetHours>,
      required: true,
    },
  },
  setup(props) {
    const WEEKDAYS = getWeekdays()
    type weekdayNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const WEEKDAY_SHORTS = Object.keys(WEEKDAYS) as weekdayNames
    const rhythmType = computed(() =>
      props.value.rhythm_type === 'free'
        ? 'custom'
        : props.value.is_month
        ? 'monthly'
        : 'weekly'
    )

    const selectedWeekdays = WEEKDAY_SHORTS.filter((day) => {
      const hasWeekday = `is_${day}`

      if (objectHasOwnProperty(props.value, hasWeekday)) {
        return props.value[hasWeekday]
      }
    })

    const baseSummary = computed(() =>
      i18n.t('employee.employee_details.target_hours.summary_total_hours', {
        hours: round(props.value.total_hours || 0, 2),
      })
    )

    const summary = computed(
      () =>
        `${baseSummary.value} / ${
          getTimeText(props.value.target_working_days)[rhythmType.value]
        }`
    )

    const fullDescription = computed(() => {
      if (rhythmType.value === 'custom') return ''
      else if (rhythmType.value === 'monthly') return `(${getMonthMsg()})`
      return selectedWeekdays.length ? `(${getWeekdaysMsg()})` : '--'
    })

    function getMonthMsg() {
      return selectedWeekdays
        .map((day) => `${getweekdayShortname(day)}`)
        .join(' ')
    }

    function getWeekdaysMsg() {
      return selectedWeekdays
        .map((day) => {
          if (objectHasOwnProperty(props.value, day)) {
            return `${getweekdayShortname(day)}: ${round(
              props.value[day] || 0,
              2
            )}`
          }
        })
        .join(' ')
    }

    function getweekdayShortname(day: string) {
      const weekdaysShortname = {
        mon: i18n.t('weekdays_shortname.mon'),
        tue: i18n.t('weekdays_shortname.tue'),
        wed: i18n.t('weekdays_shortname.wed'),
        thu: i18n.t('weekdays_shortname.thu'),
        fri: i18n.t('weekdays_shortname.fri'),
        sat: i18n.t('weekdays_shortname.sat'),
        sun: i18n.t('weekdays_shortname.sun'),
      }
      if (objectHasOwnProperty(weekdaysShortname, day))
        return weekdaysShortname[day]
    }

    function getTimeText(workingDays: CustomTargetHoursItem[] = []) {
      const freeRhythmDays = workingDays.length

      return {
        custom: i18n.t('employee.employee_details.target_hours.free_days', {
          days: freeRhythmDays,
        }),
        monthly: i18n.t('employee.employee_details.target_hours.month_days'),
        weekly: i18n.t('employee.employee_details.target_hours.week_days'),
      }
    }

    return {
      summary,
      fullDescription,
    }
  },
})
