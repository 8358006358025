




















































import { defineComponent, computed, ref } from '@vue/composition-api'
import useDeviceSize from '@/utils/composables/use-ui-size'
import store from '@/store'
import { USER_GETTER_HIGHEST_ROLE } from '@/app/core/user/store/getters/user.getters.names'
import { Role } from '@/app/core/user/models/role.model'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import MobileActionBar from '@/app/util-modules/ui/mobile-action-bar/MobileActionBar.vue'
import ActionsDropdown from './EmployeeHeaderActionsDropdown.vue'
import UpgradeDialog from './EmployeeHeaderActionsUpgradeDialog.vue'
import ConfigLink from './EmployeeHeaderActionsConfigLink.vue'

export default defineComponent({
  name: 'EmployeeHeaderActions',
  components: {
    MobileActionBar,
    BaseButton,
    ActionsDropdown,
    UpgradeDialog,
    ConfigLink,
  },
  props: {
    canCreateEmployee: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { $isSmallDevice } = useDeviceSize()
    const buttonSize = computed(() =>
      $isSmallDevice.value ? 'is-medium' : 'is-small'
    )
    const isAccountAdmin = computed(
      () =>
        (store.getters[USER_GETTER_HIGHEST_ROLE] as Role)?.name ===
        'account_admin'
    )
    const isUpgradeDialogShown = ref(false)

    return { buttonSize, isAccountAdmin, isUpgradeDialogShown }
  },
})
