




















































import cloneDeep from 'lodash/cloneDeep'
import { PropType, defineComponent, ref, computed } from '@vue/composition-api'
import { CustomTargetHoursUpdateItem as Day } from '../../models/employee.model'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import HelpText from './EmployeeTargetHoursHelpText.vue'
import TargetHoursDay from './EmployeeTargetHoursDay.vue'

export default defineComponent({
  name: 'EmployeeTargetHoursCustom',
  components: {
    BaseField,
    BaseButton,
    HelpText,
    TargetHoursDay,
  },
  props: {
    days: {
      type: Array as PropType<Day[]>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const daysLocal = ref<Day[]>(cloneDeep(props.days))
    const daysComputed = computed(() => {
      return daysLocal.value
        .filter((day) => !day.toDestroy)
        .map((day, i) => ({
          ...day,
          freeday: day.day_type === 'freeday',
          count: i + 1,
        }))
    })
    const totalHours = computed(() =>
      daysComputed.value.reduce((acc, val) => acc + +val.hours, 0)
    )

    const handleDayAdd = () => {
      daysLocal.value.push({
        id: Date.now() + '',
        day_type: 'workday',
        hours: 8,
        isNew: true,
      })
      emit('days-change', daysLocal.value)
    }

    const handleDayChange = (
      id: Day['id'],
      { freeday, hours }: { freeday: boolean; hours: number }
    ) => {
      const day = daysLocal.value.find((d) => d.id === id)

      if (day) {
        day.day_type = freeday ? 'freeday' : 'workday'
        day.hours = hours
        emit('days-change', daysLocal.value)
      }
    }

    const handleDayRemove = (id: Day['id']) => {
      const day = daysLocal.value.find((d) => d.id === id)

      daysLocal.value = daysLocal.value.filter((d) => d.id !== id)

      if (day && !day.isNew)
        daysLocal.value.push({
          ...day,
          toDestroy: true,
        })

      emit('days-change', daysLocal.value)
    }

    return {
      daysLocal,
      daysComputed,
      totalHours,

      handleDayAdd,
      handleDayChange,
      handleDayRemove,
    }
  },
})
