import { computed, WritableComputedRef } from '@vue/composition-api'
import { BaseTargetHours, WeeklyTargetHours } from '../../models/employee.model'

export default function useRhythm(
  basePayload: WritableComputedRef<Partial<BaseTargetHours>>,
  weeklyPayload: WritableComputedRef<Partial<WeeklyTargetHours>>
) {
  const rhythm = computed({
    get() {
      if (basePayload.value.rhythm_type === 'weekly') {
        if (weeklyPayload.value.is_week) {
          return 'weekly'
        } else if (weeklyPayload.value.is_month) {
          return 'monthly'
        }
      } else if (basePayload.value.rhythm_type === 'free') {
        return 'custom'
      }
      return ''
    },
    set(value) {
      basePayload.value = {
        rhythm_type: value === 'custom' ? 'free' : 'weekly',
      }
      weeklyPayload.value = { is_week: value === 'weekly' }
      weeklyPayload.value = { is_month: value === 'monthly' }
    },
  })

  return {
    rhythm,
  }
}
