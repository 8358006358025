<template>
  <base-icon
    :icon="icon"
    :original-color="isTimeTrackingIcon"
    size="1rem"
    :class="{
      'browser-icon': isBrowserIcon,
      'time-tracking-icon': isTimeTrackingIcon,
    }"
  />
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import { getIconByMethodName } from './employee.utils'

export default {
  name: 'EmployeeTimeTrackingMethodIcon',
  components: {
    BaseIcon,
  },
  props: {
    methodName: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      return getIconByMethodName(this.methodName)
    },
    isBrowserIcon() {
      return this.icon.includes('browser')
    },
    isTimeTrackingIcon() {
      return this.icon.includes('time-tracking')
    },
  },
}
</script>

<style lang="scss" scoped>
.time-tracking-icon::v-deep path {
  fill: currentColor;
}
.browser-icon::v-deep circle {
  fill: currentColor;
}
</style>
