








import { defineComponent, computed } from '@vue/composition-api'
import type { TranslateResult } from 'vue-i18n'
import i18n from '@/utils/vendors/i18n/index'
import { formatDate } from '@/utils/date-time.utils'

const DATE_FORMAT = 'EEEEEE, dd.MM.yy, HH:mm'

export default defineComponent({
  name: 'EmployeeLastUpdateText',
  props: {
    lastUpdatedBy: {
      type: String,
      default: '',
    },
    lastUpdatedAt: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const lastChanged = computed<TranslateResult>(() => {
      const date = props.lastUpdatedAt
        ? new Date(props.lastUpdatedAt)
        : new Date()
      const lastChangedAt = formatDate(date, DATE_FORMAT, true)

      if (!props.lastUpdatedBy) return lastChangedAt
      else
        return i18n.t('employee.employee_details.updated_by', {
          time: lastChangedAt,
          user: props.lastUpdatedBy,
        })
    })

    return { lastChanged }
  },
})
