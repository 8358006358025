



















import { defineComponent } from '@vue/composition-api'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'

export default defineComponent({
  name: 'TableRowCellDefault',
  components: { BaseTooltip, BaseIcon },
  props: {
    value: {
      type: String,
      required: true,
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
    overrideClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    const defaultClass = 'relative flex justify-end w-full items-end'

    return { defaultClass }
  },
})
