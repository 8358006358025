



























import { computed, defineComponent } from '@vue/composition-api'
import { ORIG_APP_URL } from '@/constants'
import store from '@/store'
import {
  USER_GETTER_USER,
  USER_GETTER_FEATURE_PERMISSIONS,
} from '@/app/core/user/store/getters/user.getters.names'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'

export default defineComponent({
  name: 'EmployeeHeaderActionsConfigLink',
  components: { BaseIcon },
  setup() {
    const hasFreeSubscription =
      store.getters[USER_GETTER_FEATURE_PERMISSIONS](
        PROFILE_MODULE_NAME
      ).free_subscription
    const configuratorLink = computed(() => {
      const accountId = store.getters[USER_GETTER_USER].account.id
      return `${ORIG_APP_URL}/accounts/${accountId}/settings?tab=data-profiles`
    })

    return {
      hasFreeSubscription,
      configuratorLink,
    }
  },
})
