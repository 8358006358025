var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isSmallDevice)?_c('portal',{attrs:{"to":"bottom-sheet"}},[_c('bottom-sheet',{attrs:{"header-right-actions":[
      {
        title: _vm.$t('employee.employee_details.edit_email.button_submit'),
        event: 'submit',
        loading: _vm.$actions.updateEmail.isLoading,
        class: 'justify-self-end text-green',
      } ],"header-title":_vm.$t('employee.employee_details.edit_email.title')},on:{"submit":function($event){return _vm.updateEmployeeEmail()},"cancel-click":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleInputChange = ref.handleInputChange;
return [_c('edit-email-form',{staticClass:"p-4",attrs:{"value":_vm.email},on:{"input":function($event){_vm.email = $event},"updated":function($event){return handleInputChange(true)}}})]}}],null,false,1607551127)})],1):_c('portal',{attrs:{"to":"modal"}},[_c('base-modal',{attrs:{"has-modal-card":""}},[_c('base-card-modal-form',{attrs:{"title":_vm.$t('employee.employee_details.edit_email.title'),"button-submit-text":_vm.$t('employee.employee_details.edit_email.button_submit'),"action-status":_vm.$actions.updateEmail,"loading":_vm.$actions.updateEmail.isLoading},on:{"submit":function($event){return _vm.updateEmployeeEmail()},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleInputChange = ref.handleInputChange;
return [_c('edit-email-form',{attrs:{"value":_vm.email},on:{"input":function($event){_vm.email = $event},"updated":function($event){return handleInputChange(true)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }