


















































































































import { defineComponent, PropType, watch } from '@vue/composition-api'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import BaseDatePicker from '@/app/util-modules/ui/date-picker/BaseDatePicker.vue'
import BaseTimePicker from '@/app/util-modules/ui/time-picker/BaseTimePicker.vue'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownItem from '@/app/util-modules/ui/dropdown/BaseDropdownItem.vue'
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox.vue'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'
import { CustomField } from '../../../models/employee.model'
import { formatCustomField } from '../../../utils/employee-data-section.utils'

interface TimeInputObject {
  hours: number | string
  minutes: number | string
}

export default defineComponent({
  name: 'DataSectionFormBody',
  components: {
    BaseField,
    BaseInputField,
    BaseDatePicker,
    BaseTimePicker,
    BaseDropdown,
    BaseDropdownItem,
    BaseCheckbox,
    BaseInputNumber,
  },
  props: {
    formFields: {
      type: Array as PropType<CustomField[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const getDateValue = (fieldValue: Date) => {
      if (fieldValue) return new Date(fieldValue)
      else return null
    }

    const getTimeValue = (
      fieldValue: TimeInputObject | string
    ): TimeInputObject => {
      if (typeof fieldValue === 'string') {
        const [hours, minutes] = fieldValue.split(':')
        return { hours, minutes }
      } else if (typeof fieldValue === 'object') return fieldValue
      else return { hours: 0, minutes: 0 }
    }

    const handleInput = (index: number, value: any) => {
      const newValues = props.formFields.map((field) => ({ ...field }))

      newValues[index].value = value
      emit('input', newValues)
    }

    watch(
      () => props.formFields,
      () => {
        emit('updated')
      }
    )

    return {
      getDateValue,
      getTimeValue,
      formatCustomField,
      handleInput,
    }
  },
})
