var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bottom-sheet',{attrs:{"header-right-actions":[
    {
      title: _vm.submitButtonText,
      event: 'submit',
      loading: _vm.actionStatus.isLoading,
      class: _vm.submitDisabled ? 'text-coal-550' : 'text-green',
      disabled: _vm.submitDisabled,
    } ],"header-title":_vm.title},on:{"submit":function($event){return _vm.$emit('submit')},"cancel-click":function($event){return _vm.$emit('close')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }