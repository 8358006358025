



















import { defineComponent, ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { Employee } from '../models/employee.model'
import useTransaction from '@/utils/composables/use-transaction'
import FormModal from './employee-form/EmployeeFormModal.vue'
import FormBottomSheet from './employee-form/EmployeeFormBottomSheet.vue'
import { EMPLOYEE_ACTION_CREATE_EMPLOYEE } from '../store/actions/employee.actions.names'

export default defineComponent({
  name: 'EmployeeAdd',
  components: {
    FormModal,
    FormBottomSheet,
  },
  trackedActions: {
    createEmployee: EMPLOYEE_ACTION_CREATE_EMPLOYEE,
  },
  setup(props, { emit }) {
    const handleCreate = async () => {
      await store.dispatch(EMPLOYEE_ACTION_CREATE_EMPLOYEE, {
        ...payload.value,
      })
      Snackbar.open(i18n.t('employee.add_form.success_message'))
      emit('success')
      emit('close')
    }

    const payload = ref<Employee>({
      username: '',
      abbreviation: '',
      email: '',
      area_ids: [],
      tag_ids: [],
      time_tracking_method_id: null,
    })

    const handleCreateWithTracker = useTransaction({
      name: 'EmployeeAdd',
      op: 'handleCreate',
      data: { ...payload.value },
      callback: handleCreate,
    })

    return {
      handleCreate: handleCreateWithTracker,
      payload,
    }
  },
})
