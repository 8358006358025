<template>
  <tabs-topbar
    :tabs="shownTabs"
    :modules="[]"
    :back-button-route="backButtonRoute"
    data-id-prefix="employee_topbar_tabs"
  />
</template>

<script>
import TabsTopbar from '@/app/util-modules/ui/tabs-topbar/TabsTopbar'
import { MODULE_NAME } from '../employee.module'

export default {
  name: 'EmployeeTopbarTabs',
  components: {
    TabsTopbar,
  },
  computed: {
    isDetailsPages() {
      return this.$route.name.includes(`${MODULE_NAME}.details`)
    },
    tabs() {
      return [
        {
          id: 'employees',
          label: () => this.$t('modules.employees.label'),
          route: { name: `${MODULE_NAME}.index` },
          show: this.$route.name === `${MODULE_NAME}.index`,
        },
        {
          id: 'employees-details',
          label: () => this.$t('modules.employees.details'),
          route: { name: `${MODULE_NAME}.details` },
          show: this.isDetailsPages,
        },
      ]
    },
    shownTabs() {
      return this.tabs.filter((tab) => tab.show)
    },
    backButtonRoute() {
      return this.isDetailsPages ? { name: `${MODULE_NAME}.index` } : null
    },
  },
}
</script>
