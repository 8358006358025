<template>
  <portal to="modal">
    <base-modal class="absence-dialog">
      <div class="md:px-0 px-4">
        <div class="modal-card dialog">
          <base-dialog-header
            :title="$t('employee.target_hours.form.confirm_absences.title')"
            @close="$emit('close')"
          />
          <div class="px-4 md:px-6 pb-8">
            {{ $t('employee.target_hours.form.confirm_absences.description') }}
          </div>
          <div
            class="px-4 md:px-6 py-4 border-t border-coal-150 w-full flex items-center flex-shrink-0"
          >
            <target-hours-dialog-footer
              @close="$emit('close')"
              @reset="$emit('confirm')"
              @keep="$emit('keep')"
            >
            </target-hours-dialog-footer>
          </div>
        </div>
      </div>
    </base-modal>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseDialogHeader from '@/app/util-modules/ui/dialog/BaseDialogHeader.vue'
import TargetHoursDialogFooter from './EmployeeTargetHoursAbsenceDialogFooter.vue'

export default {
  name: 'EmployeeTargetHoursAbsenceDialog',
  components: {
    BaseModal,
    BaseDialogHeader,
    TargetHoursDialogFooter,
  },
}
</script>

<style scoped>
::v-deep .animation-content .modal-card {
  @apply m-auto;
}
.dialog {
  @apply relative md:w-auto w-full rounded-md md:rounded bg-white-400 pt-2 max-w-2xl;
}

.absence-dialog {
  z-index: 150;
}
</style>
