import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { Employee } from '../../../models/employee.model'
import { EMPLOYEE_ACTION_LIST_UNREGISTERED } from '../../../store/actions/employee.actions.names'

interface State {
  employeeList: Employee[]
  currentPage: number
  totalEmployeeCount: number
}

const initialState: () => State = () => ({
  employeeList: [],
  currentPage: 0,
  totalEmployeeCount: 0,
})

export default function useData() {
  const state = ref(initialState())
  const fetchedEmployeeCount = computed(() => state.value.employeeList.length)

  const resetData = () => {
    state.value = initialState()
  }

  const fetchData = async (withEmail: boolean) => {
    if (
      state.value.totalEmployeeCount &&
      state.value.totalEmployeeCount === fetchedEmployeeCount.value
    )
      return

    state.value.currentPage++

    const { data, meta } = await store.dispatch(
      EMPLOYEE_ACTION_LIST_UNREGISTERED,
      { withEmail, pagination: { page: state.value.currentPage, items: 20 } }
    )

    state.value.totalEmployeeCount = meta.total_count
    state.value.employeeList.push(...data)
  }

  return {
    employeeList: computed(() => state.value.employeeList),
    fetchedEmployeeCount,
    totalEmployeeCount: computed(() => state.value.totalEmployeeCount),
    resetData,
    fetchData,
  }
}
