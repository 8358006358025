





















import { defineComponent, ref, PropType, watch } from '@vue/composition-api'
import BaseCollapse from '@/app/util-modules/ui/collapse/BaseCollapse.vue'
import { DataProfile } from '../../models/employee.model'
import { formatCustomField } from '../../utils/employee-data-section.utils'
import i18n from '@/utils/vendors/i18n'

export default defineComponent({
  name: 'EmployeeGeneralDataSections',
  components: {
    BaseCollapse,
  },
  props: {
    sections: {
      type: Array as PropType<DataProfile[]>,
      default: () => [],
    },
  },
  setup(props) {
    const openedItem = ref('')

    const getSectionLabel = (section: DataProfile) =>
      section.permissions?.update
        ? (i18n.t(
            'employee.employee_details.general.edit_data_section'
          ) as string)
        : ''

    watch(
      () => props.sections.length > 0,
      () => {
        if (props.sections.length) openedItem.value = props.sections[0].id
      },
      { immediate: true }
    )

    return { openedItem, formatCustomField, getSectionLabel }
  },
})
