


































import { PropType, defineComponent } from '@vue/composition-api'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import BaseFormValidation from '@/app/util-modules/ui/form/BaseFormValidation.vue'
import BaseFormValidationMessages from '@/app/util-modules/ui/form/BaseFormValidationMessages.vue'
import FormBody from '../data-section-form/DataSectionFormBody.vue'
import { CustomField } from '../../../models/employee.model'

export default defineComponent({
  name: 'DataSectionFormBottomSheet',
  components: {
    BottomSheet,
    BaseFormValidation,
    BaseFormValidationMessages,
    FormBody,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    payload: {
      type: Array as PropType<CustomField[]>,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
  },
  setup(_props, { emit }) {
    const validate = async (handleSubmit: () => Promise<void>) => {
      try {
        await handleSubmit()
        emit('submit')
      } catch (e) {} /* eslint-disable-line no-empty */
    }

    return { validate }
  },
})
