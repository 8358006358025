




































import { defineComponent, ref, computed } from '@vue/composition-api'
import type { TranslateResult } from 'vue-i18n'
import store from '@/store'
import router from '@/router'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'
import { MODULE_NAME } from '../employee.module'
import {
  Employee,
  EmployeeRecordsSummary,
  EmployeeVacationsSummary,
} from '../models/employee.model'
import i18n from '@/utils/vendors/i18n/index'
import useTransaction from '@/utils/composables/use-transaction'
import EmployeeInfo from '../components/employee-info/EmployeeInfo.vue'
import EmployeeTabs from '../components/employee-info/EmployeeTabs.vue'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'
import {
  EMPLOYEE_ACTION_GET_EMPLOYEE,
  EMPLOYEE_ACTION_GET_RECORDS_SUMMARY,
  EMPLOYEE_ACTION_GET_VACATIONS_SUMMARY,
} from '../store/actions/employee.actions.names'
import { USER_PUBLIC_ACTION_GET_CURRENT_USER_BASE_ATTRIBUTES } from '@/app/core/user/store/actions/user.actions.names'
import useUserId from './composables/use-user-id'

interface FormattedVacationSummary {
  text: TranslateResult
  value: number
}

export default defineComponent({
  name: 'EmployeeDetailsPage',
  components: {
    BaseLoading,
    EmployeeInfo,
    EmployeeTabs,
  },
  trackedActions: {
    getEmployee: EMPLOYEE_ACTION_GET_EMPLOYEE,
  },
  setup() {
    const employee = ref<Employee>({
      username: '',
      roles: [],
      areas: [],
    })
    const { userId } = useUserId()

    const areaTotalCount = computed(() => store.state.area.meta?.total_count)
    const tagTotalCount = computed(() => store.state.tag.meta?.total_count)

    const fetchEmployee = () =>
      store
        .dispatch(EMPLOYEE_ACTION_GET_EMPLOYEE, userId.value)
        .then((resp) => (employee.value = resp))
        .catch(() => router.replace({ name: `${MODULE_NAME}.index` }))

    const fetchEmployeeWithTracker = useTransaction({
      name: 'EmployeeDetail',
      op: 'fetchEmployee',
      description: 'loading employee detail',
      callback: fetchEmployee,
    })

    fetchEmployeeWithTracker()

    const refreshCurrentUser = () => {
      if (userId.value === store.state.user.user.id)
        // we need to update the logged-in user state
        // and not only the shown employee
        store.dispatch(USER_PUBLIC_ACTION_GET_CURRENT_USER_BASE_ATTRIBUTES)

      fetchEmployee()
    }

    const isWorkingHoursShown = computed(() =>
      Boolean(
        store.getters[USER_GETTER_FEATURE_PERMISSIONS](
          TIME_TRACKING_MODULE_NAME
        ).read_index &&
          store.getters[USER_GETTER_FEATURE_PERMISSIONS]('General')
            .hide_records_for_employees !== true
      )
    )

    const isVacationsShown = computed(() =>
      Boolean(
        store.getters[USER_GETTER_FEATURE_PERMISSIONS](ABSENCE_MODULE_NAME)
          .read_index
      )
    )

    const recordsSummary = ref<EmployeeRecordsSummary>({
      confirmed_total_overtime_in_sec: 0,
      total_overtime_in_sec: 0,
    })

    const vacationsSummary = ref<EmployeeVacationsSummary>({
      planned_vacation_days_this_year: 0,
      remaining_vacation_days_this_year: 0,
      hour_holidays: false,
    })

    const fetchRecordsSummary = () =>
      store
        .dispatch(EMPLOYEE_ACTION_GET_RECORDS_SUMMARY, userId.value)
        .then((response) => {
          Object.assign(recordsSummary.value, response.attributes)
        })

    const fetchVacationsSummary = () =>
      store
        .dispatch(EMPLOYEE_ACTION_GET_VACATIONS_SUMMARY, userId.value)
        .then((response) => {
          Object.assign(vacationsSummary.value, response.attributes)
        })

    const workingHoursSummary = computed(() => {
      const { total_overtime_in_sec } = recordsSummary.value

      if (!total_overtime_in_sec) return 0

      return total_overtime_in_sec / 3600
    })

    const formattedVacationSummary = computed<FormattedVacationSummary>(() => {
      if (vacationsSummary.value?.hour_holidays) {
        return {
          text: i18n.t('employee.employee_details.cards.vacation_hours'),
          value: vacationsSummary.value.remaining_vacation_days_this_year,
        }
      }
      return {
        text: i18n.t('employee.employee_details.cards.vacation_days'),
        value: vacationsSummary.value.remaining_vacation_days_this_year,
      }
    })

    const hideProfileHeader = computed(() => {
      const isCurrentUser = userId.value === store.getters[USER_GETTER_USER].id

      const isAdmin = store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]

      return isCurrentUser && isAdmin
    })

    if (isWorkingHoursShown.value) fetchRecordsSummary()

    fetchVacationsSummary()

    return {
      userId,
      employee,
      areaTotalCount,
      tagTotalCount,
      isWorkingHoursShown,
      formattedVacationSummary,
      workingHoursSummary,
      recordsSummary,
      vacationsSummary,
      isVacationsShown,
      hideProfileHeader,

      fetchEmployee,
      refreshCurrentUser,
    }
  },
})
