import { ComputedRef, Ref } from '@vue/composition-api'
import store from '@/store'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { EMPLOYEE_ACTION_INVITE_EMPLOYEES } from '../../../store/actions/employee.actions.names'
import { Employee } from '../../../models/employee.model'

export const CHECKBOX_ID_ALL_USERS = 'all_users'

export default function useInviter({
  checkedItems,
  fetchedEmployeeCount,
  userCheckin,
  welcomeMessage,
}: {
  checkedItems: Ref<Employee[]>
  fetchedEmployeeCount: ComputedRef<number>
  userCheckin: ComputedRef<boolean>
  welcomeMessage: ComputedRef<string | null>
}) {
  let onSuccess: () => void = () => {}

  const invite = async () => {
    const ids = checkedItems.value
      .map(({ id }) => id)
      .filter((id) => id !== CHECKBOX_ID_ALL_USERS)

    const { message } = await store.dispatch(EMPLOYEE_ACTION_INVITE_EMPLOYEES, {
      employeeIds: ids.length === fetchedEmployeeCount.value ? 'all' : ids,
      checkin: userCheckin.value,
      welcomeMessage: welcomeMessage?.value,
    })

    Snackbar.open(message)
    onSuccess()
  }

  return { invite, onSuccess: (callback: () => void) => (onSuccess = callback) }
}
