












































































































import { defineComponent, ref, PropType, computed } from '@vue/composition-api'
import type { TranslateResult } from 'vue-i18n'
import store from '@/store'
import router from '@/router'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import EmployeeTooltipBadge from '../EmployeeTooltipBadge.vue'
import EmployeeRoles from '../EmployeeRoles.vue'
import EmployeeInfoLists from './EmployeeInfoLists.vue'
import EmployeeAvatar from './employee-avatar/EmployeeAvatar.vue'
import EditEmailModal from './EmployeeEditEmailModal.vue'
import EmployeeInfoSummary from './EmployeeInfoSummary.vue'
import { USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE } from '@/app/core/user/store/getters/user.getters.names'
import { MODULE_NAME as EMPLOYEE_MODULE } from '../../employee.module'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'

interface FormattedVacationSummary {
  text: TranslateResult
  value: number
}

export default defineComponent({
  name: 'EmployeeInfo',
  components: {
    BaseIcon,
    BaseBadge,
    EmployeeTooltipBadge,
    EmployeeRoles,
    EmployeeInfoLists,
    EmployeeAvatar,
    EditEmailModal,
    EmployeeInfoSummary,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    areaTotalCount: {
      type: Number,
      default: 0,
    },
    tagTotalCount: {
      type: Number,
      default: 0,
    },
    isWorkingHoursShown: {
      type: Boolean,
      required: true,
    },
    isVacationsShown: {
      type: Boolean,
      required: true,
    },
    workingHoursSummary: {
      type: Number,
      required: true,
    },
    vacationsSummary: {
      type: Object as PropType<FormattedVacationSummary>,
      required: true,
    },
    hideProfileHeader: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const isEditEmailModalOpen = ref(false)
    const isAbsenceVisible = computed(() =>
      store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](
        ABSENCE_MODULE_NAME
      )
    )
    const recordsRoute = computed(() => {
      const moduleName = router.currentRoute?.name?.startsWith(
        PROFILE_MODULE_NAME
      )
        ? PROFILE_MODULE_NAME
        : EMPLOYEE_MODULE

      return `${moduleName}.details.records`
    })

    const absenceRoute = computed(() =>
      isAbsenceVisible.value
        ? `${ABSENCE_MODULE_NAME}.index`
        : `${PROFILE_MODULE_NAME}.details`
    )

    return { isEditEmailModalOpen, recordsRoute, absenceRoute }
  },
})
