































import { defineComponent } from '@vue/composition-api'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import BaseCardModalFooter from '@/app/util-modules/ui/card-modal/BaseCardModalFooter.vue'

export default defineComponent({
  name: 'EmployeeInviteModal',
  components: {
    BaseModal,
    BaseCardModalForm,
    BaseCardModalFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    buttonSubmitText: {
      type: String,
      required: true,
    },
    buttonCloseText: {
      type: String,
      required: true,
    },
    checkedItemsCount: {
      type: Number,
      default: 0,
    },
    isFormView: {
      type: Boolean,
      default: false,
    },
  },
})
