















































import { defineComponent, PropType, ref } from '@vue/composition-api'
import store from '@/store'
import EmployeeVacationsHistoryList from './EmployeeVacationsHistoryList.vue'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModal from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'
import { EmployeeVacationsHistory } from '../../models/employee.model'
import { EMPLOYEE_ACTION_GET_VACATION_HISTORY } from '../../store/actions/employee.actions.names'

export default defineComponent({
  name: 'EmployeeVacationsHistory',
  components: {
    BaseModal,
    BaseCardModal,
    HistoryList: EmployeeVacationsHistoryList,
    BottomSheet,
    BaseLoading,
  },

  props: {
    userId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
    vacationId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
    employeeHourHolidays: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const isLoading = ref(false)
    const historyData = ref<EmployeeVacationsHistory[] | null>(null)

    const loadVacation = async () => {
      isLoading.value = true
      const response = await store.dispatch(
        EMPLOYEE_ACTION_GET_VACATION_HISTORY,
        {
          userId: props.userId,
          vacationId: props.vacationId,
        }
      )
      historyData.value = response
      isLoading.value = false
    }

    loadVacation()

    return {
      isLoading,
      historyData,
    }
  },
})
