


























































import { AxiosError } from 'axios'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import i18n from '@/utils/vendors/i18n'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import store from '@/store'
import TargetHoursAbsenceDialog from './employee-target-hours/EmployeeTargetHoursAbsenceDialog.vue'
import {
  EMPLOYEE_ACTION_DELETE_TARGET_HOURS,
  EMPLOYEE_ACTION_UPDATE_TARGET_HOURS,
  EMPLOYEE_ACTION_GET_TARGET_HOURS,
} from '../store/actions/employee.actions.names'
import { objectHasOwnProperty } from './employee.utils'
import TargetHoursModal from './employee-target-hours/EmployeeTargetHoursModal.vue'
import TargetHoursBottomSheet from './employee-target-hours/EmployeeTargetHoursBottomSheet.vue'
import usePayload from './composables/use-payload'
import useRhythm from './composables/use-rhythm'
import useAbsenceConfirmation from './composables/use-absence-confirmation'
import { TargetHours } from '../models/employee.model'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'

interface Option {
  id: string
  label: TranslateResult
  icon: string
  classes: string
}

export default defineComponent({
  name: 'EmployeeTargetHoursEdit',
  components: {
    TargetHoursModal,
    TargetHoursAbsenceDialog,
    TargetHoursBottomSheet,
    BaseDialog,
  },
  props: {
    employeeId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
    targetHoursId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
  },
  trackedActions: {
    getTargetHours: EMPLOYEE_ACTION_GET_TARGET_HOURS,
    updateTargetHours: EMPLOYEE_ACTION_UPDATE_TARGET_HOURS,
    deleteTargetHours: EMPLOYEE_ACTION_DELETE_TARGET_HOURS,
  },

  setup(props, { emit }) {
    const moreOptions = ref<Option[]>([
      {
        id: 'delete',
        label: i18n.t('employee.target_hours.option_label_delete'),
        icon: 'delete/delete-16',
        classes: 'text-error-400',
      },
    ])
    const isDeleteTHoursConfirmOpen = ref(false)
    const { basePayload, weeklyPayload, customPayload } = usePayload()
    const { rhythm } = useRhythm(basePayload, weeklyPayload)
    const { isAbsenceDialogOpen, checkAbsences } = useAbsenceConfirmation(
      basePayload,
      props.employeeId,
      handleUpdate
    )

    async function handleUpdate(
      { reset_confirmed_absences } = { reset_confirmed_absences: false }
    ) {
      const bodyPayload =
        basePayload.value.rhythm_type === 'free' ? customPayload : weeklyPayload

      const action = await store.dispatch(EMPLOYEE_ACTION_UPDATE_TARGET_HOURS, {
        employeeId: props.employeeId,
        targetHours: {
          ...basePayload.value,
          ...bodyPayload.value,
          reset_confirmed_absences,
        },
      })

      Snackbar.open(i18n.t('employee.target_hours.form.update_success'))
      emit('success')
      emit('close')

      return action
    }

    async function handleDelete() {
      try {
        await store.dispatch(EMPLOYEE_ACTION_DELETE_TARGET_HOURS, {
          employeeId: props.employeeId,
          targetHoursId: props.targetHoursId,
        })
        emit('close')
        Snackbar.open(i18n.t('employee.target_hours.delete_success_message'))
      } catch (err) {
        const { response } = err as AxiosError
        const errors = response?.data?.errors || []
        const message = errors.length
          ? errors[0].title
          : i18n.t('employee.target_hours.delete_error_default_message')

        Snackbar.open({
          type: 'is-danger',
          message,
        })
      }
    }

    // fetch target hours and fill in payload
    store
      .dispatch(EMPLOYEE_ACTION_GET_TARGET_HOURS, {
        employeeId: props.employeeId,
        targetHoursId: props.targetHoursId,
      })
      .then((response: TargetHours) => {
        basePayload.value.id = response.id

        for (const payload of [basePayload, weeklyPayload, customPayload]) {
          const keys = Object.keys(payload.value)

          for (const key of keys) {
            if (objectHasOwnProperty(response, key)) {
              payload.value[key] = response[key]
            }
          }
        }
      })

    const handleOptionSelection = async (option: Option) => {
      if (option.id === 'delete') {
        isDeleteTHoursConfirmOpen.value = true
      }
    }

    return {
      moreOptions,
      isDeleteTHoursConfirmOpen,
      rhythm,
      basePayload,
      weeklyPayload,
      customPayload,
      isAbsenceDialogOpen,

      handleUpdate,
      handleDelete,
      checkAbsences,
      handleOptionSelection,
    }
  },
})
