<template>
  <bottom-sheet-form-wrapper
    :header-right-actions="[
      {
        title: submitButtonText,
        event: 'submit',
        loading: actionStatus.isLoading,
        class: 'justify-self-end text-green',
      },
    ]"
    :header-title="title"
    :header-more-options="moreOptions"
    :action-status="actionStatus"
    validation-messages-class="mx-4 mt-4 mb-2"
    @handle-selection="$emit('selected', $event)"
    @close="$emit('close')"
    @done="$emit('submit')"
  >
    <template v-slot="{ handleInputChange }">
      <form-body
        :value="payload"
        :preload-action-status="preloadActionStatus"
        :edit-mode="editMode"
        @input="$emit('input', $event)"
        @updated="handleInputChange(true)"
      />
    </template>
  </bottom-sheet-form-wrapper>
</template>

<script>
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'
import FormBody from '../employee-form/EmployeeFormBody.vue'

export default {
  name: 'EmployeeFormBottomSheet',
  components: {
    BottomSheetFormWrapper,
    FormBody,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
    preloadActionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const validate = async (handleSubmit) => {
      try {
        await handleSubmit()
        emit('submit')
      } catch (e) {} /* eslint-disable-line no-empty */
    }

    return {
      validate,
    }
  },
}
</script>
