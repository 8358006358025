


















































import { defineComponent, PropType, ref } from '@vue/composition-api'
import store from '@/store'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper.vue'
import EditForm from '../employee-records/EmployeeRecordsEditForm.vue'
import { EmployeeRecordItem } from '../../models/employee.model'
import {
  EMPLOYEE_ACTION_GET_RECORD,
  EMPLOYEE_ACTION_UPDATE_RECORD,
} from '../../store/actions/employee.actions.names'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'

export default defineComponent({
  name: 'EmployeeRecordsEdit',
  components: {
    BaseModal,
    BaseCardModalForm,
    EditForm,
    BottomSheetFormWrapper,
  },
  props: {
    recordId: {
      // TODO: find out why eslint doesn't recognize typescript globals
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
    userId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $actions = useActionTracker({
      updateRecord: EMPLOYEE_ACTION_UPDATE_RECORD,
    })

    const recordItem = ref<EmployeeRecordItem>({
      target: 0.0,
      plan: 0.0,
      actual: 0.0,
      absent: 0.0,
      compensation: 0.0,
      absent_calc: 0,
      overlapping_absence: false,
    })

    const fetchRecordItem = async () => {
      const response = await store.dispatch(EMPLOYEE_ACTION_GET_RECORD, {
        userId: props.userId,
        recordId: props.recordId,
      })

      recordItem.value = response
    }

    const handleSubmit = async () => {
      try {
        await store.dispatch(EMPLOYEE_ACTION_UPDATE_RECORD, {
          userId: props.userId,
          record: recordItem.value,
          recordId: props.recordId,
        })
        Snackbar.open(
          i18n.t('employee.employee_details.records.edit.success_message')
        )
        emit('success')
        emit('close')
      } catch (error) {
        Snackbar.open({
          message: i18n.t(
            'employee.employee_details.records.edit.error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    fetchRecordItem()

    return {
      recordItem,
      $actions,

      handleSubmit,
    }
  },
})
