
















































import { defineComponent } from '@vue/composition-api'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'

export default defineComponent({
  name: 'EmployeeVacationsViewSwitchDropdown',
  components: {
    BaseButton,
    BaseDropdown,
    BaseToggle,
  },

  props: {
    isHourHolidays: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  setup(props, { emit }) {
    const handleToggle = () => {
      emit('toggle-vacation-view', {
        value: !props.isHourHolidays,
        type: 'dropdown',
      })
    }

    return {
      handleToggle,
    }
  },
})
