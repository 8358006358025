





















import { PropType, defineComponent } from '@vue/composition-api'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import DataSectionCardModalForm from './DataSectionCardModalForm.vue'
import FormBody from '../data-section-form/DataSectionFormBody.vue'
import { CustomField } from '../../../models/employee.model'

export default defineComponent({
  name: 'DataSectionFormModal',
  components: {
    BaseModal,
    DataSectionCardModalForm,
    FormBody,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    payload: {
      type: Array as PropType<CustomField[]>,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
  },
})
