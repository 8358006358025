

























































































import {
  defineComponent,
  computed,
  watch,
  onUnmounted,
  PropType,
} from '@vue/composition-api'
import EmployeeRecordsContractSettingsEditFormLabel from './EmployeeRecordsContractSettingsEditFormLabel.vue'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'
import { EmployeeRecordItem } from '../../models/employee.model'

export default defineComponent({
  name: 'EmployeeRecordsEditForm',
  components: {
    EditRecordFormLabel: EmployeeRecordsContractSettingsEditFormLabel,
    BaseInputField,
    BaseField,
    BaseInputNumber,
  },
  props: {
    value: {
      type: Object as PropType<EmployeeRecordItem>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const passedValue = computed({
      get() {
        return props.value
      },
      set(newValue) {
        emit('input', newValue)
      },
    })

    const unwatch = watch(
      () => props.value,
      () => {
        emit('updated')
      },
      { deep: true }
    )

    onUnmounted(unwatch)

    return { passedValue }
  },
})
