import { computed, ComputedRef } from '@vue/composition-api'
import useDeviceSize from '@/utils/composables/use-ui-size'
import { TargetHours } from '../../models/employee.model'
import TableRowCellExceedHours from '../../components/employee-info/table-row/TableRowCellExceedHours.vue'
import TableRowCellActions from '../../components/employee-info/table-row/TableRowCellActions.vue'
import TableRowCellSummary from '../../components/employee-info/table-row/TableRowCellSummary.vue'
import { formatDate } from '@/utils/date-time.utils'
import { BaseTableColumn } from '@/app/util-modules/ui/table/BaseTable.types'
import i18n from '@/utils/vendors/i18n/index'

interface ActionHandler {
  handler: (actionName: string, params?: any) => void
}

const getColumns = (
  $isSmallDevice: ComputedRef<boolean>,
  action: ActionHandler
): ComputedRef<BaseTableColumn<TargetHours>[]> => {
  const cols: BaseTableColumn<TargetHours>[] = [
    {
      width: '100px',
      smallDevice: true,
      header: {
        class:
          'md:pl-3 mr-7 md:mr-10 uppercase font-semibold md:font-sm text-coal-550',
        props: {
          value: () =>
            i18n.t('employee.employee_details.target_hours.start_date'),
        },
      },
      cell: {
        class: 'md:pl-3 mr-7 md:mr-10 font-medium md:text-base',
        props: {
          value: (row: TargetHours) => formatDate(new Date(row.start_date)),
        },
      },
    },
    {
      width: '100px',
      smallDevice: true,
      header: {
        class: 'mr-7 uppercase md:mr-10 text-coal-550',
        props: {
          value: () =>
            i18n.t('employee.employee_details.target_hours.end_date'),
        },
      },
      cell: {
        class: 'mr-7 md:mr-10 md:text-base font-medium',
        props: {
          value: (row: TargetHours) =>
            row.end_date ? formatDate(new Date(row.end_date)) : '--',
        },
      },
    },
    {
      width: $isSmallDevice.value ? '130px' : 'auto',
      smallDevice: true,
      header: {
        class:
          'mr-7 md:mr-10 uppercase font-semibold md:font-sm text-coal-550 flex-1',
        props: {
          value: () => i18n.t('employee.employee_details.target_hours.summary'),
        },
      },
      cell: {
        element: TableRowCellSummary,
        class: 'mr-7 md:mr-10 flex-1',
        props: {
          value: (row: TargetHours) => row,
        },
      },
    },
    {
      width: '170px',
      smallDevice: true,
      header: {
        class:
          'mr-7 md:mr-10 uppercase font-semibold md:font-sm text-coal-550 text-left',
        props: {
          value: () =>
            i18n.t(
              'employee.employee_details.target_hours.exceed_target_hours'
            ),
        },
      },
      cell: {
        element: TableRowCellExceedHours,
        class: 'mr-7 md:mr-10',
        props: {
          value: (row: TargetHours) => row.target_limited,
        },
      },
    },
    {
      width: '70px',
      smallDevice: true,
      header: {
        class:
          'md:pr-8 uppercase font-semibold text-coal-550 text-right justify-end',
        props: {
          value: () => i18n.t('employee.employee_details.target_hours.actions'),
        },
      },
      cell: {
        element: TableRowCellActions,
        props: {
          canUpdate: (row: TargetHours) => row.permissions?.update,
        },
        class: 'md:pr-8',
        events: {
          'edit-target-hour-action-click': (row) =>
            action.handler('edit-target-hour', { id: row.id }),
        },
      },
    },
  ]

  return computed(() =>
    $isSmallDevice.value ? cols.filter((c) => c.smallDevice) : cols
  )
}

export default function useTableColumns() {
  const { $isSmallDevice } = useDeviceSize()
  const action: ActionHandler = {
    handler: () => {},
  }

  const columns = getColumns($isSmallDevice, action)

  return {
    columns,
    onAction: (callback: ActionHandler['handler']) =>
      (action.handler = callback),
  }
}
