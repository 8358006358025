<template>
  <div
    class="employee-form-body flex-1 md:px-0 md:pt-0 md:pb-0 sm:px-4 sm:pt-4 sm:pb-6"
  >
    <base-input-field
      v-model="passedValue.username"
      :label="$t('employee.add_form.field_label_name')"
      :size="inputSize"
      class="mb-6 flex-2"
      name="username"
      type="text"
      placeholder="Jon Doe"
      data-id="employee.form.field_input_name"
      validation-rules="required"
      validation-id="employee.add_form.field_input_name"
    />

    <base-input-field
      ref="emailField"
      v-model="passedValue.email"
      :label="$t('employee.add_form.field_label_email')"
      :size="inputSize"
      :field-props="{
        labelRight: $t('employee.add_form.field_label_optional'),
      }"
      class="mb-6"
      autocomplete="off"
      name="email"
      type="email"
      placeholder="mail@email.com"
      data-id="employee.form.field_input_email"
      validation-rules="email"
    />

    <!-- role selector for Employee Add postponed to v2 -->
    <role-select
      v-if="editMode"
      :user-roles="passedValue.roles"
      :can-update-account-admin-role="
        passedValue.permissions.update_account_admin_role
      "
      :action-status="preloadActionStatus"
      :is-terminal-app="passedValue.time_tracking_method === 'via_terminal_app'"
      data-id="employee.form.field_input_role"
      @input="handleRoleInput($event)"
    />

    <employee-time-tracking-method
      v-if="editMode && timeTrackingModuleVisible"
      data-id="employee.add_form.field_label_time_tracking_method"
      name="methods"
      direction="top"
      :value="value ? value : {}"
      :action-status="preloadActionStatus"
      :label="$t('employee.add_form.field_label_time_tracking_method')"
      class="w-full"
      @input="handleTimeTrackingMethodInput($event)"
    />

    <area-search
      :value="selectedAreas"
      :label="$t('employee.add_form.field_label_area')"
      :placeholder="$t('employee.add_form.field_placeholder_area')"
      :size="inputSize"
      :show-user-count="true"
      name="area"
      class="employee-area-search optional mb-6"
      icon="arrow"
      dropdown
      data-id="employee.form.field_input_area"
      @input="handleAreaInput($event)"
    />
    <tag-search
      :value="selectedTags"
      :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
      data-id="employee.form.field_input_tag"
      name="tags"
      :label="$t('employee.add_form.field_label_tag')"
      :placeholder="$t('employee.add_form.field_placeholder_tag')"
      direction="top"
      class="mb-6"
      @input="handleTagInput($event)"
    />
  </div>
</template>

<script>
import store from '@/store'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField'
import AreaSearch from '@/app/core/area/components/area-search/AreaSearch'
import RoleSelect from './EmployeeFormRoleSelect.vue'
import TagSearch from '@/app/core/tag/components/tag-search/TagSearch'
import EmployeeTimeTrackingMethod from '@/app/modules/employee/components/EmployeeTimeTrackingMethod.vue'
import { USER_GETTER_FEATURE } from '@/app/core/user/store/getters/user.getters.names'
import { TIME_TRACKING_MODULE } from '@/app/modules/time-tracking/constants'
export default {
  name: 'EmployeeFormBody',
  components: {
    EmployeeTimeTrackingMethod,
    AreaSearch,
    BaseInputField,
    RoleSelect,
    TagSearch,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    preloadActionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // temporary state to hold selected area and tag collection
      // for area-search and tag-search
      selectedAreas: [],
      selectedTags: [],
      selectedTimeTrackingMethod: '',
    }
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    inputSize() {
      return !this.$isSmallDevice ? 'is-medium' : 'is-large'
    },
    timeTrackingModuleVisible() {
      return store.getters[USER_GETTER_FEATURE](TIME_TRACKING_MODULE)
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit('updated')
      },
      deep: true,
    },
  },
  created() {
    // fill in areas and tags dropdown as soon as request is completed
    const unwatch = this.$watch(
      () => this.preloadActionStatus.isOk || false,
      (isOk) => {
        if (!isOk) return

        // props are available on nextTick
        this.$nextTick(() => {
          if (this.value.areas)
            this.selectedAreas = this.value.areas.map((a) => {
              return {
                id: a.id,
                title: a.name,
              }
            })
          if (this.value.tags) this.selectedTags = this.value.tags
          if (this.value.time_tracking_method)
            this.selectedTimeTrackingMethod = this.value.time_tracking_method
        })
        unwatch()
      },
      { deep: true }
    )
  },
  mounted() {
    const emailField = this.$refs.emailField?.$el

    if (emailField) {
      this.appendSublabel(
        emailField,
        this.$t('employee.add_form.field_label_email_auto_invited')
      )
    }
  },
  methods: {
    handleAreaInput(collection) {
      // for create-employee payload, we need array of areas' IDs,
      // not collection of areas as area-search passes
      this.passedValue.area_ids = collection.map((item) => item.id)
      this.selectedAreas = collection
    },
    handleTagInput(collection) {
      // for create-employee payload, we need array of tags' IDs,
      // not collection of tags as tag-search passes
      this.passedValue.tag_ids = collection.map((item) => item.id)
      this.selectedTags = collection
    },
    handleTimeTrackingMethodInput(methods) {
      this.passedValue.time_tracking_method_id = methods
    },
    handleRoleInput(roleIds) {
      this.passedValue.role_ids = roleIds
    },
    // TODO: this is hacky, because sub-label is not supported in design system.
    appendSublabel(field, sublabel) {
      const elem = document.createElement('div')
      elem.className = 'mb-1 text-coal-550 font-medium'
      elem.textContent = sublabel

      const label = field.querySelector('label')
      if (label) label.after(elem)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .employee-area-search .control {
  @apply w-full;
}

@media (max-width: theme('screens.md.min')) {
  .employee-form-body {
    @apply overflow-y-auto;

    max-height: calc(100vh - 96px);
  }
}
</style>
