import get from 'lodash/get'
import { formatDate } from '@/utils/date-time.utils'
import {
  BaseTargetHours,
  CustomTargetHoursItem,
  CustomTargetHoursUpdateItem,
  WeeklyTargetHours,
  TargetHoursCreateParams,
  TargetHoursUpdateParams,
  DataProfile,
  CustomField,
  CustomFieldOption,
} from './employee.model'

interface CustomTargetHoursCreate {
  target_working_days_attributes?: CustomTargetHoursItem[]
}

type TargetHoursCreate = BaseTargetHours &
  Either<WeeklyTargetHours, CustomTargetHoursCreate>

export function makeTargetHoursCreateAttributes(
  targetHours: TargetHoursCreateParams
): TargetHoursCreate {
  let attributes: TargetHoursCreate | null = null
  const baseAttributes: BaseTargetHours = {
    rhythm_type: targetHours.rhythm_type || 'weekly',
    start_date: targetHours.start_date,
    target_limited: get(targetHours, 'target_limited', false),
    reset_confirmed_absences: get(
      targetHours,
      'reset_confirmed_absences',
      false
    ),
  }

  if (targetHours.rhythm_type === 'free') {
    const days = (targetHours.target_working_days || []).map(
      (day: CustomTargetHoursItem) => {
        return {
          day_type: day.day_type,
          hours: day.hours,
        }
      }
    )

    attributes = {
      ...baseAttributes,
      target_working_days_attributes: days,
    }
  } else {
    attributes = {
      ...baseAttributes,
      per_day: get(targetHours, 'per_day', false),
      is_week: get(targetHours, 'is_week', true),
      is_month: get(targetHours, 'is_month', false),
      month: get(targetHours, 'month', 0),
      is_mon: get(targetHours, 'is_mon', true),
      is_tue: get(targetHours, 'is_tue', true),
      is_wed: get(targetHours, 'is_wed', true),
      is_thu: get(targetHours, 'is_thu', true),
      is_fri: get(targetHours, 'is_fri', true),
      is_sat: get(targetHours, 'is_sat', false),
      is_sun: get(targetHours, 'is_sun', false),
      mon: get(targetHours, 'mon', 8),
      tue: get(targetHours, 'tue', 8),
      wed: get(targetHours, 'wed', 8),
      thu: get(targetHours, 'thu', 8),
      fri: get(targetHours, 'fri', 8),
      sat: targetHours.sat || 0,
      sun: targetHours.sun || 0,
    }
  }

  return attributes
}

type CustomTargetHourDay = CustomTargetHoursItem & {
  _destroy?: boolean
}

export function makeTargetHoursUpdateAttributes(
  targetHours: TargetHoursUpdateParams
): TargetHoursCreate {
  if (targetHours.rhythm_type === 'free' && targetHours.target_working_days) {
    const { target_working_days, ...rest } = targetHours
    return {
      ...rest,
      target_working_days_attributes:
        makeTargetWorkingDays(target_working_days),
    }
  }

  return targetHours
}

function makeTargetWorkingDays(
  target_working_days: CustomTargetHoursUpdateItem[] = []
) {
  return target_working_days.map((day) => {
    const workingDay: CustomTargetHourDay = {
      day_type: day.day_type,
      hours: day.hours,
    }

    if (day.toDestroy) workingDay._destroy = true
    if (!day.isNew && day.id) workingDay.id = day.id
    return workingDay
  })
}

interface DataProfileUpdateRequest {
  value: any
  data_type: string
  custom_field_id: RecordId
  id?: RecordId
}

export function makeDataProfileUpdateAttributes(dataProfile: DataProfile) {
  const newCustomFieldValues = dataProfile.custom_fields.map((customField) => {
    const obj: DataProfileUpdateRequest = {
      custom_field_id: customField.id,
      value: formatDataProfileValue(customField),
      data_type: customField.data_type,
    }

    if (customField.custom_field_value_id)
      obj.id = customField.custom_field_value_id

    return obj
  })

  return { custom_field_values_attributes: newCustomFieldValues }
}

function formatDataProfileValue(customField: CustomField) {
  if (customField.data_type === 'dropdown')
    return formatDropdownValue(customField)
  else if (customField.data_type === 'date' && customField.value)
    return formatDate(new Date(customField.value), 'yyyy-MM-dd', true)
  else if (
    customField.data_type === 'time' &&
    typeof customField.value === 'object'
  )
    return `${customField.value.hours}:${customField.value.minutes}`

  return customField.value
}

function formatDropdownValue(customField: CustomField) {
  // For multiselect dropdown, the value could also be
  // an empty string if no option is selected
  if (customField.multiselect && customField.value) {
    return customField.value.map((field: CustomFieldOption) =>
      field.id ? field.id : field
    )
  }

  return customField.value ? customField.value.id : ''
}

export const generateDateRangeQueryParam = (dateRange: Date[]) => {
  if (Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
    const newStartDate = new Date(dateRange[0])

    newStartDate.setHours(12)

    const startValue = `gte:${newStartDate.toISOString()}`

    const newEndDate = new Date(dateRange[1])

    newEndDate.setHours(12)

    const endValue = `lte:${newEndDate.toISOString()}`

    return `?filter[day][]=${startValue}&filter[day][]=${endValue}`
  }

  return ''
}
