<template>
  <component
    :is="$isSmallDevice ? 'MobileActionBar' : 'div'"
    class="w-full flex py-2 md:py-0 md:w-auto md:gap-2"
  >
    <base-button
      v-if="canCreateTargetHours"
      data-id="employee.employee_details.add_target_hour"
      type="is-filled"
      :size="$isSmallDevice ? 'is-medium' : 'is-small'"
      class="flex-1 md:order-1"
      @click="$emit('add-click')"
    >
      {{ $t('employee.employee_details.target_hours.add_target_hour') }}
    </base-button>

    <base-button
      v-if="$isSmallDevice"
      data-id="employee.target_hours.filterbar.filter_button"
      type="is-outlined-grey"
      size="is-medium"
      class="flex-1"
      @click="$emit('filter-click')"
    >
      {{ $t('employee.target_hours.filterbar.filter_button') }}
    </base-button>
  </component>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import MobileActionBar from '@/app/util-modules/ui/mobile-action-bar/MobileActionBar.vue'

export default {
  name: 'EmployeeTargetHoursListHeaderActions',
  components: { MobileActionBar, BaseButton },
  props: {
    canCreateTargetHours: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
