import { computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'

export default function useUserId() {
  const userId = computed<string>(() => {
    if (router.currentRoute.name?.startsWith(PROFILE_MODULE_NAME)) {
      return store.state.user.user.id
    } else return router.currentRoute.params.userId
  })

  return { userId }
}
