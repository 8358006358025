import { Ref, computed, ref } from '@vue/composition-api'
import { formatToIso } from '@/utils/date-time.utils'
import {
  BaseTargetHours,
  WeeklyTargetHours,
  CustomTargetHours,
} from '../../models/employee.model'

function makeComputedPayload(payload: Ref<any>) {
  return computed({
    get: () => payload.value,
    set(value: Partial<typeof payload.value>) {
      Object.assign(payload.value, value)
    },
  })
}

export default function usePayload() {
  const basePayload = ref<BaseTargetHours>({
    rhythm_type: 'weekly',
    start_date: formatToIso(),
    end_date: null,
    target_limited: false,
    total_hours: 40,
    reset_confirmed_absences: false,
  })
  const weeklyPayload = ref<WeeklyTargetHours>({
    per_day: false,
    is_week: true,
    is_month: false,
    // total per month for Monthly rhythm
    month: 160,
    // weekday bools
    is_mon: true,
    is_tue: true,
    is_wed: true,
    is_thu: true,
    is_fri: true,
    is_sat: false,
    is_sun: false,
    // weekday shortnames
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0,
    sun: 0,
  })
  const customPayload = ref<CustomTargetHours>({
    target_working_days: [{ hours: 8, day_type: 'workday' }],
  })

  return {
    basePayload: makeComputedPayload(basePayload),
    weeklyPayload: makeComputedPayload(weeklyPayload),
    customPayload: makeComputedPayload(customPayload),
  }
}
