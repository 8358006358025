var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-field',{staticClass:"mb-6",attrs:{"name":"start_of_accounting","hint":_vm.isStartOfAccountingDisabled
        ? _vm.$t(
            'employee.target_hours.contract_settings.field_hint_start_of_accounting'
          )
        : ''},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('contract-settings-form-label',{attrs:{"tooltip-position-mobile":"is-bottom","label":_vm.$t(
            'employee.target_hours.contract_settings.field_label_start_of_accounting'
          ),"tooltip":_vm.$t(
            'employee.target_hours.contract_settings.field_tooltip_start_of_accounting'
          )}})]},proxy:true}])},[_c('base-date-picker',{attrs:{"disabled":_vm.isStartOfAccountingDisabled,"editable":true,"data-id-prefix":"contract_settings_field.start_of_accounting","icon-right":"calendar/calendar-32","append-to-body":""},model:{value:(_vm.startOfAccounting),callback:function ($$v) {_vm.startOfAccounting=$$v},expression:"startOfAccounting"}})],1),_c('base-field',{staticClass:"mb-6",attrs:{"name":"initial_time_account"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('contract-settings-form-label',{attrs:{"tooltip-position-mobile":"is-bottom","label":_vm.$t(
            'employee.target_hours.contract_settings.field_label_initial_time_account'
          ),"tooltip":_vm.$t(
            'employee.target_hours.contract_settings.field_tooltip_initial_time_account'
          )}})]},proxy:true}])},[_c('base-input-number',{attrs:{"placeholder":_vm.$t(
          'employee.target_hours.contract_settings.field_placeholder_initial_time_account'
        ),"data-id":"contract_settings_field.initial_time_account","required":""},nativeOn:{"keydown":function($event){return _vm.preventNonNumericChar($event)}},model:{value:(_vm.passedValue.initial_time_account),callback:function ($$v) {_vm.$set(_vm.passedValue, "initial_time_account", $$v)},expression:"passedValue.initial_time_account"}})],1),_c('base-dropdown',{staticClass:"contract-settings-dropdown mb-6 w-full",attrs:{"mobile-modal":false,"data-id-prefix":"contract_settings_field.saldo_type","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('base-input-field',{attrs:{"value":_vm.passedValue.saldo_type.value,"validation-rules":"required","name":"saldo_type","icon-right":"arrow","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('contract-settings-form-label',{attrs:{"label":_vm.$t(
                'employee.target_hours.contract_settings.field_label_saldo_type'
              ),"tooltip":_vm.$t(
                'employee.target_hours.contract_settings.field_tooltip_saldo_type'
              )}})]},proxy:true}])})]},proxy:true}]),model:{value:(_vm.passedValue.saldo_type),callback:function ($$v) {_vm.$set(_vm.passedValue, "saldo_type", $$v)},expression:"passedValue.saldo_type"}},_vm._l((_vm.passedValue.saldo_type_options),function(option){return _c('base-dropdown-item',{key:option.key,staticClass:"w-full",class:{ 'is-active': option.key === _vm.passedValue.saldo_type.key },attrs:{"value":option}},[_vm._v(" "+_vm._s(option.value)+" ")])}),1),_c('base-field',{staticClass:"mb-6",attrs:{"name":"salary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('contract-settings-form-label',{attrs:{"label":_vm.$t('employee.target_hours.contract_settings.field_label_salary'),"tooltip":_vm.$t('employee.target_hours.contract_settings.field_tooltip_salary')}})]},proxy:true}])},[_c('base-input-number',{attrs:{"placeholder":_vm.$t('employee.target_hours.contract_settings.field_placeholder_salary'),"data-id":"contract_settings_edit.salary","required":""},model:{value:(_vm.passedValue.salary),callback:function ($$v) {_vm.$set(_vm.passedValue, "salary", $$v)},expression:"passedValue.salary"}})],1),_c('base-dropdown',{staticClass:"contract-settings-dropdown mb-6 w-full",attrs:{"mobile-modal":false,"data-id-prefix":"contract_settings_field.calc_holiday_options","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('base-input-field',{attrs:{"value":_vm.passedValue.calc_holiday_options.value,"validation-rules":"required","name":"calc_holiday_options","icon-right":"arrow","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('contract-settings-form-label',{attrs:{"label":_vm.$t(
                'employee.target_hours.contract_settings.field_label_calc_holiday_options'
              ),"tooltip":_vm.$t(
                'employee.target_hours.contract_settings.field_tooltip_calc_holiday_options'
              )}})]},proxy:true}])})]},proxy:true}]),model:{value:(_vm.passedValue.calc_holiday_options),callback:function ($$v) {_vm.$set(_vm.passedValue, "calc_holiday_options", $$v)},expression:"passedValue.calc_holiday_options"}},_vm._l((_vm.passedValue.calc_holiday_options_data),function(option){return _c('base-dropdown-item',{key:option.key,attrs:{"value":option}},[_vm._v(" "+_vm._s(option.value)+" ")])}),1),_c('base-input-field',{staticClass:"mb-6",attrs:{"disabled":_vm.isCustomHolidayHoursDisabled,"label":_vm.$t(
        'employee.target_hours.contract_settings.field_label_custom_holiday_hours'
      ),"name":"custom_holiday_hours","type":"number","validation-rules":"required","data-id-prefix":"contract_settings_field.custom_holiday_hours"},model:{value:(_vm.passedValue.custom_holiday_hours),callback:function ($$v) {_vm.$set(_vm.passedValue, "custom_holiday_hours", $$v)},expression:"passedValue.custom_holiday_hours"}}),_c('base-field',{staticClass:"mb-6",attrs:{"name":"hours_on_vacation_day"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('contract-settings-form-label',{attrs:{"label":_vm.$t(
            'employee.target_hours.contract_settings.field_label_hours_on_vacation_day'
          ),"tooltip":_vm.$t(
            'employee.target_hours.contract_settings.field_tooltip_hours_on_vacation_day'
          )}})]},proxy:true}])},[_c('base-input-number',{attrs:{"placeholder":_vm.$t(
          'employee.target_hours.contract_settings.field_placeholder_hours_on_vacation_day'
        ),"data-id":"contract_settings_edit.hours_on_vacation_day","required":""},nativeOn:{"keydown":function($event){return _vm.preventNonNumericChar($event)}},model:{value:(_vm.passedValue.hours_on_vacation_day),callback:function ($$v) {_vm.$set(_vm.passedValue, "hours_on_vacation_day", $$v)},expression:"passedValue.hours_on_vacation_day"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }