var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.$isSmallDevice && _vm.payload.id)?_c('portal',{attrs:{"to":"modal"}},[_c('base-modal',{attrs:{"has-modal-card":""}},[_c('base-card-modal-form',{attrs:{"title":_vm.$t('employee.employee_details.vacations.edit.title'),"button-submit-text":_vm.$t('employee.employee_details.vacations.edit.submit_button'),"action-status":_vm.$actions.updateVacation,"loading":_vm.$actions.updateVacation.isLoading || _vm.$actions.loadVacation.isLoading,"footer-more-options":_vm.moreOptions,"data-id-prefix":"employee.employee_details.vacations.edit"},on:{"selected":function($event){return _vm.handleSelection($event)},"submit":function($event){return _vm.handleEdit()},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleInputChange = ref.handleInputChange;
return [_c('vacations-form',{attrs:{"value":_vm.payload,"disabled-fields":_vm.disabledFields,"hide-remaining-days-group":_vm.hideRemainingDaysGroup},on:{"input":function($event){return _vm.handleInput($event)},"updated":function($event){return handleInputChange(true)}}})]}}],null,false,57500477)})],1)],1):_vm._e(),(_vm.$isSmallDevice && _vm.payload.id)?_c('portal',{attrs:{"to":"bottom-sheet"}},[_c('bottom-sheet-form-wrapper',{attrs:{"header-right-actions":[
        {
          title: _vm.$t('employee.employee_details.vacations.edit.submit_button'),
          event: 'submit',
          loading:
            _vm.$actions.updateVacation.isLoading ||
            _vm.$actions.loadVacation.isLoading,
          class: 'justify-self-end text-green',
        } ],"header-title":_vm.$t('employee.employee_details.vacations.edit.title'),"header-more-options":_vm.moreOptions,"action-status":_vm.$actions.updateVacation,"validation-messages-class":"mx-4 mt-4 mb-2"},on:{"handle-selection":function($event){return _vm.handleSelection($event)},"close":function($event){return _vm.$emit('close')},"done":function($event){return _vm.handleEdit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleInputChange = ref.handleInputChange;
return [_c('vacations-form',{attrs:{"value":_vm.payload,"disabled-fields":_vm.disabledFields,"hide-remaining-days-group":_vm.hideRemainingDaysGroup},on:{"input":function($event){return _vm.handleInput($event)},"updated":function($event){return handleInputChange(true)}}})]}}],null,false,57500477)})],1):_vm._e(),(_vm.isDeleteEmployeeConfirmOpen)?_c('vacations-delete',{attrs:{"vacation-id":_vm.vacationId,"user-id":_vm.userId},on:{"success":function($event){return _vm.handleSuccessDelete()},"close":function($event){_vm.isDeleteEmployeeConfirmOpen = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }