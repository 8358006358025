





























































































import round from 'lodash/round'
import {
  defineComponent,
  toRefs,
  PropType,
  computed,
} from '@vue/composition-api'
import { WeeklyTargetHours } from '../../models/employee.model'
import { getWeekdays, objectHasOwnProperty } from '../employee.utils'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseRadio from '@/app/util-modules/ui/radio/BaseRadio.vue'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'
import HelpText from './EmployeeTargetHoursHelpText.vue'
import TargetHoursDay from './EmployeeTargetHoursDay.vue'

export default defineComponent({
  name: 'EmployeeTargetHoursWeekly',
  components: {
    BaseField,
    BaseRadio,
    BaseInputNumber,
    HelpText,
    TargetHoursDay,
  },
  props: {
    preloadActionStatus: {
      type: Object,
      default: () => ({}),
    },
    totalHours: {
      type: Number,
      default: 0,
    },
    payload: {
      type: Object as PropType<WeeklyTargetHours>,
      required: true,
    },
    getWeekdayBool: {
      type: Function as PropType<(day: string) => string>,
      required: true,
    },
    isFreeWeekday: {
      type: Function as PropType<(day: string) => boolean>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { payload, totalHours } = toRefs(props)
    // eslint-disable-next-line vue/no-setup-props-destructure
    const getWeekdayBool = props.getWeekdayBool
    // eslint-disable-next-line vue/no-setup-props-destructure
    const isFreeWeekday = props.isFreeWeekday
    const WEEKDAYS = getWeekdays()
    const WEEKDAY_SHORTS = Object.keys(WEEKDAYS)
    const perDay = computed(() => props.payload.per_day)

    interface DayParams {
      weekdayShortname: string
      freeday: boolean
      hours: number
    }

    const changeDay = ({ weekdayShortname, freeday, hours }: DayParams) => {
      if (objectHasOwnProperty(payload.value, weekdayShortname)) {
        const weekdayBool = getWeekdayBool(weekdayShortname)

        payload.value[weekdayBool] = !freeday
        payload.value[weekdayShortname] = hours

        emit('weekdays-change', {
          [weekdayBool]: payload.value[weekdayBool],
          [weekdayShortname]: payload.value[weekdayShortname],
        })
      }
    }

    const handleDayChange = (params: DayParams) => {
      changeDay(params)
      handleTotalHoursChange(totalHours.value)
    }

    const handleTotalHoursChange = (total: number) => {
      const enabledDays = WEEKDAY_SHORTS.filter((day) => !isFreeWeekday(day))

      if (perDay.value) {
        const total = WEEKDAY_SHORTS.reduce((sum: number, day: string) => {
          if (objectHasOwnProperty(payload.value, day)) {
            sum += payload.value[day] as number
          }
          return sum
        }, 0)

        emit('total-hours-change', round(total, 3))
      } else {
        if (isNaN(total) || !total) {
          total = 0
        }
        // base-input emits string
        if (typeof total === 'string') {
          total = parseFloat(total)
        }

        const hourPerDay = round(total / enabledDays.length, 3)

        enabledDays.forEach((weekdayShortname) => {
          changeDay({ weekdayShortname, freeday: false, hours: hourPerDay })
        })

        emit('total-hours-change', round(total, 3))
      }
    }

    const changePerDay = (value: boolean) => {
      emit('perday-change', value)
      handleTotalHoursChange(totalHours.value)
    }

    // re-calculate on init render, e.g. switching back from monthly to weekly rhythm
    handleTotalHoursChange(totalHours.value)

    return {
      WEEKDAYS,
      perDay,

      handleDayChange,
      handleTotalHoursChange,
      changePerDay,

      // exported for unit tests
      changeDay,
    }
  },
})
