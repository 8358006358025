import store from '@/store'
import { USER_ACTION_UPDATE_USER_LOCALE } from '@/app/core/user/store/actions/user.actions.names'
import { TIME_TRACKING_METHOD_TYPE } from '../models/employee.model'
import {
  EMPLOYEE_ACTION_LIST_TRACKING_METHODS,
  EMPLOYEE_ACTION_LIST_DATA_PROFILES,
} from './actions/employee.actions.names'
import { EMPLOYEE_MUTATION_CLEAR_RECORDS } from './mutations/employee.mutations.names'

export function subscribeActions() {
  return store.subscribeAction({
    // subscribe to locale change to refetch tracking methods
    // because they are localized in backend
    after: (action) => {
      if (action.type === USER_ACTION_UPDATE_USER_LOCALE) {
        store.commit(EMPLOYEE_MUTATION_CLEAR_RECORDS, {
          type: TIME_TRACKING_METHOD_TYPE,
        })
        store.dispatch(EMPLOYEE_ACTION_LIST_TRACKING_METHODS)
        store.dispatch(EMPLOYEE_ACTION_LIST_DATA_PROFILES)
      }
    },
  })
}
