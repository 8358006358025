<template>
  <bottom-sheet-form-wrapper
    :header-right-actions="header.right"
    :header-left-actions="header.left"
    :header-title="header.title"
    :action-status="actionStatus"
    validation-messages-class="mx-4 mt-4 mb-2"
    @submit="$emit('submit')"
    @back-click="$emit('back-click')"
    @close="$emit('close')"
  >
    <div class="px-4 pt-4">
      <slot />
    </div>
  </bottom-sheet-form-wrapper>
</template>

<script>
import { computed } from '@vue/composition-api'

import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'

export default {
  name: 'EmployeeInviteBottomSheet',
  components: {
    BottomSheetFormWrapper,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    checkedItemsCount: {
      type: Number,
      default: 0,
    },
    isFormView: {
      type: Boolean,
      default: false,
    },
    buttonSubmitText: {
      type: String,
      required: true,
    },
    buttonCloseText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const header = computed(() => {
      const inviteBtn = {
        title: props.buttonSubmitText,
        event: 'submit',
        loading: props.actionStatus.isLoading,
        disabled: !props.checkedItemsCount && props.isFormView,
        class: { 'text-green': !props.checkedItemsCount && props.isFormView },
      }
      const backBtn = {
        title: props.buttonCloseText,
        class: 'text-coal-550',
        event: 'back-click',
      }

      return {
        right: [inviteBtn],
        left: [backBtn],
        title: props.title,
      }
    })

    return {
      header,
    }
  },
}
</script>
