













































import { defineComponent, ref, computed } from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n/index'
import store from '@/store'
import { Employee } from '../../models/employee.model'
import {
  EMPLOYEE_ACTION_INVITE_EMPLOYEES,
  EMPLOYEE_ACTION_LIST_UNREGISTERED,
} from '../../store/actions/employee.actions.names'
import {
  USER_GETTER_FEATURE,
  USER_GETTER_FEATURE_PERMISSIONS,
} from '@/app/core/user/store/getters/user.getters.names'
import InviteModal from './EmployeeInviteModal.vue'
import InviteBottomSheet from './EmployeeInviteBottomSheet.vue'
import InviteForm from './EmployeeInviteForm.vue'
import NoEmailList from './no-email-list/NoEmailList.vue'
import FormCheckin from './EmployeeInviteFormCheckin.vue'
import useView, { View } from './composables/useView'
import useData from './composables/useData'
import useCopyLink from './composables/useCopyLink'
import useTransaction from '@/utils/composables/use-transaction'
import useDeviceSize from '@/utils/composables/use-ui-size'
import useInviter, { CHECKBOX_ID_ALL_USERS } from './composables/useInviter'
import { CHECKIN_MODULE_NAME } from '@/constants'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'

interface UsersItems {
  username: string
  id: string
  children: Employee[]
}

export default defineComponent({
  name: 'EmployeeInvite',
  components: {
    InviteModal,
    InviteBottomSheet,
    InviteForm,
    NoEmailList,
    FormCheckin,
  },
  setup(props, { emit }) {
    const { $isSmallDevice } = useDeviceSize()
    // boolean stating whether to load employees with email or without
    const withEmail = ref(true)

    const $actions = useActionTracker({
      listEmployees: EMPLOYEE_ACTION_LIST_UNREGISTERED,
      inviteEmployees: EMPLOYEE_ACTION_INVITE_EMPLOYEES,
    })

    const workspaceAttributes = computed(() => {
      const { checkin, welcome_message } =
        store.state.workspace.workspace.attributes
      return { checkin, welcome_message }
    })
    const hasCheckinModule = computed(() =>
      store.getters[USER_GETTER_FEATURE](CHECKIN_MODULE_NAME)
    )
    const userPermission = computed(() =>
      Boolean(
        hasCheckinModule.value &&
          store.getters[USER_GETTER_FEATURE_PERMISSIONS](CHECKIN_MODULE_NAME)
            .read
      )
    )
    const welcomeMessage = ref<string | null>(
      workspaceAttributes.value.welcome_message
    )
    const userCheckin = ref(Boolean(workspaceAttributes.value.checkin))

    // list structure for treeselect component
    const checkboxList = computed<UsersItems>(() => ({
      username: i18n.t('employee.invite_modal.select_all') as string,
      id: CHECKBOX_ID_ALL_USERS,
      children: employeeList.value,
    }))
    const checkedItems = ref<Employee[]>([])

    const onViewChange = (view: View) => {
      if (view === 'invite-form') {
        loadEmployees(true)
      } else if (view === 'no-email-list') {
        loadEmployees(false)
      }
      checkedItems.value = []
    }

    const { currentView, switchView } = useView(onViewChange)
    const {
      employeeList,
      fetchedEmployeeCount,
      totalEmployeeCount,
      fetchData,
      resetData,
    } = useData()
    const { invite, onSuccess: onInviteSuccess } = useInviter({
      checkedItems,
      fetchedEmployeeCount,
      userCheckin,
      welcomeMessage,
    })

    onInviteSuccess(() => {
      emit('success')
      emit('close')
    })

    const { copyInvitationLink } = useCopyLink({ userCheckin, welcomeMessage })

    const loadEmployees = async (hasEmail: boolean) => {
      if (hasEmail !== withEmail.value) {
        resetData()
        withEmail.value = hasEmail
      }

      const fetchDataWithTracker = useTransaction({
        name: 'EmployeeInvite',
        callback: () => fetchData(hasEmail),
        op: hasEmail ? 'fetchDataWithEmail' : 'fetchDataWithoutEmail',
        data: { hasEmail },
        description: hasEmail
          ? 'loading employees with email'
          : 'loading employees without email',
      })

      fetchDataWithTracker()
    }

    loadEmployees(true)

    const inviteWithTracker = useTransaction({
      name: 'EmployeeInvite',
      op: 'invite',
      description: 'invite employee',
      callback: invite,
    })

    const modalConfigurations = computed(() => {
      if (currentView.value === 'no-email-list')
        return {
          title: i18n.t('employee.invite_modal.employees_no_email'),
          buttonSubmitText: $isSmallDevice.value
            ? i18n.t('employee.invite_modal.copy_invitation_mobile')
            : i18n.t('employee.invite_modal.copy_invitation'),
          buttonCloseText: i18n.t('employee.invite_modal.button_back'),
          handleSubmit: copyInvitationLink,
          handleClose: () => switchView('invite-form'),
        }
      else
        return {
          title: i18n.t('employee.invite_modal.title'),
          buttonSubmitText: $isSmallDevice.value
            ? i18n.t('employee.invite_modal.button_submit_mobile')
            : i18n.t('employee.invite_modal.button_submit'),
          buttonCloseText: i18n.t('employee.invite_modal.button_cancel'),
          handleSubmit: invite,
          handleClose: () => emit('close'),
        }
    })

    return {
      welcomeMessage,
      hasCheckinModule,
      userCheckin,
      userPermission,
      currentView,
      checkedItems,
      checkboxList,
      totalEmployeeCount,
      employeeList,
      modalConfigurations,
      $actions,

      switchView,
      loadEmployees,
      invite: inviteWithTracker,
      copyInvitationLink,
    }
  },
})
