import { computed, Ref } from '@vue/composition-api'
import store from '@/store'
import { MODULE_NAME } from '../../employee.module'
import {
  Employee,
  DataProfile,
  DATA_PROFILE_TYPE,
} from '../../models/employee.model'
import { addValueToCustomFields } from '../../utils/employee-data-section.utils'

export default function useDataProfileValues(employee: Ref<Employee>) {
  return computed(() =>
    store.state[MODULE_NAME].records[DATA_PROFILE_TYPE].map(
      (dataProfile: DataProfile) => ({
        ...dataProfile,
        custom_fields: addValueToCustomFields(
          dataProfile.custom_fields,
          employee.value
        ),
      })
    )
  )
}
