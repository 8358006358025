































































import {
  defineComponent,
  ref,
  computed,
  watch,
  toRefs,
} from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n'
// import store from '@/store'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import BaseDropdownItem from '@/app/util-modules/ui/dropdown/BaseDropdownItem.vue'
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox.vue'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'
import TrackingMethodIcon from '@/app/modules/employee/components/EmployeeTimeTrackingMethodIcon.vue'
interface Option {
  name: string
  value: string
  isChecked: boolean
  id: string
  isDisabled?: boolean
}

interface Method {
  id: string
  title: string
  options: Option[]
  isEnabled: boolean
}

export default defineComponent({
  name: 'EmployeeMultiSelectMethod',
  components: {
    BaseInputField,
    BaseDropdownItem,
    BaseCheckbox,
    BaseDropdown,
    BaseToggle,
    TrackingMethodIcon,
  },
  props: {
    corePlan: {
      type: Boolean,
      default: false,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      required: true,
    },
    isBpcEnabled: {
      type: Boolean,
      default: false,
    },
    isTrackingAppEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { corePlan, actionStatus, value } = toRefs(props)
    let timeTrackingMethods = ref<Method[]>([
      {
        id: 'roster_tracking',
        title: i18n.t(
          'employee.method_of_time_tracking.with_action.roster_tracking'
        ) as string,
        options: [
          {
            name: i18n.t(
              'employee.method_of_time_tracking.with_action.via_shiftplan'
            ) as string,
            value: 'via_roster',
            isChecked: false,
            id: '2',
          },
        ],
        isEnabled: false,
      },
      {
        id: 'live_tracking',
        title: i18n.t(
          'employee.method_of_time_tracking.with_action.live_tracking'
        ) as string,
        options: [
          {
            name: i18n.t(
              'employee.method_of_time_tracking.with_action.via_browser_punch_clock'
            ) as string,
            value: 'via_browser_time_clock',
            isChecked: false,
            id: '3',
            isDisabled: !props.isBpcEnabled,
          },
          {
            name: i18n.t(
              'employee.method_of_time_tracking.with_action.via_terminal_app'
            ) as string,
            value: 'via_terminal_app',
            isChecked: false,
            id: '5',
            isDisabled: !props.isTrackingAppEnabled,
          },
        ],
        isEnabled: false,
      },
    ])

    const isCorePlan = computed(() => {
      return corePlan.value
    })

    if (isCorePlan.value) {
      // if core plan return only live tracking
      timeTrackingMethods.value = [...timeTrackingMethods.value].splice(1, 1)
    } else {
      // Original timeTrackingMethods initialization
      timeTrackingMethods.value = [...timeTrackingMethods.value]
    }

    const displayValue = computed(() => {
      if (!checkedItems.value.length) {
        return i18n.t(
          'employee.method_of_time_tracking.with_action.no_tracking'
        ) as string
      }
      return checkedItems.value.map((obj) => obj.name).join(', ')
    })

    const handleToggle = (methodIndex: number, event: boolean) => {
      timeTrackingMethods.value.forEach((method, index) => {
        if (index === methodIndex) {
          method.isEnabled = event
          if (method.id === 'roster_tracking') {
            method.options.forEach((checkbox) => {
              checkbox.isChecked = event
            })
          }
        } else {
          method.isEnabled = false
          method.options.forEach((checkbox) => {
            checkbox.isChecked = false
          })
        }
      })
    }

    const handleCheckBox = (
      optionIndex: number,
      methodIndex: number,
      event: boolean
    ) => {
      timeTrackingMethods.value.forEach((method, index) => {
        if (index === methodIndex) {
          method.options.forEach((checkbox, checkboxIndex) => {
            if (checkboxIndex === optionIndex) {
              checkbox.isChecked = event
            }
          })

          const allUnchecked = method.options.every(
            (checkbox) => !checkbox.isChecked
          )

          method.isEnabled = !allUnchecked
        }
      })
    }

    let checkedItems = computed(() => {
      const checkedItemsArray: Option[] = []

      timeTrackingMethods.value.forEach((method) => {
        method.options.forEach((option) => {
          if (option.isChecked) {
            checkedItemsArray.push({
              name: option.name,
              value: option.value,
              isChecked: true,
              id: option.id,
            })
          }
        })
      })

      return checkedItemsArray
    })

    watch(
      () => checkedItems.value,
      (checkedItems) => {
        const cit = [...checkedItems]
        if (cit.length === 0) {
          emit('input', '7')
          return
        }
        if (cit.length === 2) {
          emit('input', '6')
        } else {
          emit('input', cit[0].id)
        }
      }
    )

    watch(
      () => actionStatus.value.isOk || false,
      (isRequestSuccessfull) => {
        if (isRequestSuccessfull) {
          ;[...timeTrackingMethods.value].map((method) => {
            method.options.map((option) => {
              // check if the selected method is via_browser_punch_clock_and_terminal_app
              if (
                value.value.time_tracking_method ===
                'via_browser_punch_clock_and_terminal_app'
              ) {
                if (isCorePlan.value) {
                  handleToggle(0, true)
                } else {
                  handleToggle(1, true)
                }
              }
              if (option.value === value.value.time_tracking_method) {
                option.isChecked = true
                method.isEnabled = true
              }
            })
          })
        }
      }
    )

    return {
      timeTrackingMethods,
      handleToggle,
      handleCheckBox,
      displayValue,
      checkedItems,
      isCorePlan,
    }
  },
})
