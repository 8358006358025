

















import { defineComponent, PropType } from '@vue/composition-api'
import store from '@/store'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'
import i18n from '@/utils/vendors/i18n/index'
import { EMPLOYEE_ACTION_DELETE_VACATION } from '../../store/actions/employee.actions.names'

export default defineComponent({
  name: 'EmployeeVacationsDelete',
  components: { BaseDialog },
  props: {
    userId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
    vacationId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
  },
  trackedActions: {
    deleteVacation: EMPLOYEE_ACTION_DELETE_VACATION,
  },
  setup(props, { emit }) {
    const handleDelete = async () => {
      try {
        await store.dispatch(EMPLOYEE_ACTION_DELETE_VACATION, {
          userId: props.userId,
          vacationId: props.vacationId,
        })

        Snackbar.open(
          i18n.t('employee.employee_details.vacations.delete.success_message')
        )
        emit('success')
      } catch (error) {
        Snackbar.open({
          message: i18n.t(
            'employee.employee_details.vacations.delete.error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    return { handleDelete }
  },
})
