






















import { defineComponent } from '@vue/composition-api'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'

export default defineComponent({
  name: 'EmployeeRecordsContractSettingsEditFormLabel',
  components: { BaseTooltip, BaseIcon },
  props: {
    label: { type: String, required: true },
    tooltip: { type: String, default: '' },
    tooltipPositionMobile: { type: String, default: 'is-top' },
  },
})
