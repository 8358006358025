var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-field',{staticClass:"mb-6",attrs:{"name":"start_of_accounting","hint":_vm.isStartOfAccountingDisabled
        ? _vm.$t(
            'employee.employee_details.records.contract_settings.field_hint_start_of_accounting'
          )
        : ''},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('records-settings-form-label',{attrs:{"tooltip-position-mobile":"is-bottom","label":_vm.$t(
            'employee.employee_details.records.contract_settings.field_label.start_of_accounting'
          ),"tooltip":_vm.$t(
            'employee.employee_details.records.contract_settings.field_tooltip_start_of_accounting'
          )}})]},proxy:true}])},[_c('base-date-picker',{attrs:{"disabled":_vm.isStartOfAccountingDisabled,"editable":true,"data-id-prefix":"contract_settings_field.start_of_accounting","icon-right":"calendar/calendar-32","append-to-body":""},model:{value:(_vm.startOfAccounting),callback:function ($$v) {_vm.startOfAccounting=$$v},expression:"startOfAccounting"}})],1),_c('base-field',{staticClass:"mb-6",attrs:{"name":"initial_time_account"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('records-settings-form-label',{attrs:{"tooltip-position-mobile":"is-bottom","label":_vm.$t(
            'employee.employee_details.records.contract_settings.field_label.initial_time_account'
          ),"tooltip":_vm.$t(
            'employee.employee_details.records.contract_settings.field_tooltip_initial_time_account'
          )}})]},proxy:true}])},[_c('base-input-number',{attrs:{"data-id":"contract_settings_field.initial_time_account","required":""},nativeOn:{"keydown":function($event){return _vm.preventNonNumericChar($event)}},model:{value:(_vm.passedValue.initial_time_account),callback:function ($$v) {_vm.$set(_vm.passedValue, "initial_time_account", $$v)},expression:"passedValue.initial_time_account"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }