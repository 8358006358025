<template>
  <component
    :is="visibleComponent"
    class="tracking-method"
    v-bind="$attrs"
    :value="value"
    :action-status="actionStatus"
    :core-plan="isCorePlan"
    :is-bpc-enabled="isBPCEnabled"
    :is-tracking-app-enabled="isTrackingAppEnabled"
    @input="handle($event)"
  />
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import store from '@/store'
import EmployeeMultiSelectMethod from '@/app/modules/employee/components/EmployeeMultiSelectMethod.vue'
import EmployeeSingleSelectMethod from '@/app/modules/employee/components/EmployeeSingleSelectMethod.vue'
import { USER_GETTER_USER } from '@/app/core/user/store/getters/user.getters.names'
export default defineComponent({
  name: 'EmployeeTimeTrackingMethod',
  components: {
    EmployeeMultiSelectMethod,
    EmployeeSingleSelectMethod,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    let selectedMethod = ref('action')

    const visibleComponent = computed(() =>
      isWithAction.value
        ? EmployeeMultiSelectMethod
        : EmployeeSingleSelectMethod
    )

    const isCorePlan = computed(
      () => store.getters[USER_GETTER_USER].account.attributes.core_plan
    )

    // const isCorePlan = computed(() => true)

    const isWithAction = computed(
      () => store.getters[USER_GETTER_USER].account.attributes.with_actions
    )

    const isBPCEnabled = computed(
      () =>
        store.getters[USER_GETTER_USER].account.attributes.browser_punch_clock
    )

    const isTrackingAppEnabled = computed(
      () => store.getters[USER_GETTER_USER].account.attributes.time_tracking_app
    )
    const handle = (value) => {
      emit('input', value)
    }

    return {
      isCorePlan,
      isBPCEnabled,
      isTrackingAppEnabled,
      visibleComponent,
      selectedMethod,
      handle,
    }
  },
})
</script>

<style lang="scss">
.dropdown + .tracking-method {
  margin-left: 0;
}
</style>
