import { render, staticRenderFns } from "./EmployeeVacationsHistory.vue?vue&type=template&id=50c3f2a4&scoped=true&"
import script from "./EmployeeVacationsHistory.vue?vue&type=script&lang=ts&"
export * from "./EmployeeVacationsHistory.vue?vue&type=script&lang=ts&"
import style0 from "./EmployeeVacationsHistory.vue?vue&type=style&index=0&id=50c3f2a4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_lh6vo7dceoovl5fyh3epe3vcle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c3f2a4",
  null
  
)

export default component.exports