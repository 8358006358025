import store from '@/store'
import { Component } from 'vue'
import { computed, ComputedRef, Ref } from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n/index'
import useDeviceSize from '@/utils/composables/use-ui-size'
import { Employee, EmployeeVacations } from '../../models/employee.model'
import { MODULE_NAME } from '../../employee.module'
import type { BaseTableColumn } from '@/app/util-modules/ui/table/BaseTable.types'
import TableHeaderCellDefault from '@/app/util-modules/ui/table/BaseTableHeaderCell.vue'
import TableRowCellStatus from '../../components/employee-vacations/table-row/TableRowCellStatus.vue'
import TableRowCellActions from '../../components/employee-vacations/table-row/TableRowCellActions.vue'
import EmployeeVacationsFooterCell from '../../components/employee-vacations/EmployeeVacationsFooterCell.vue'
import { DATE_FORMAT, formatDate } from '@/utils/date-time.utils'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

const HEADER_CLASS =
  'md:uppercase capitalize font-semibold md:font-sm text-coal-550 flex-1 md:mr-4 py-1'
const CELL_CLASS = 'font-medium md:text-base text-lg flex-1 md:mr-4 truncate'

interface ActionHandler {
  handler: (actionName: string, params?: any) => void
}

interface EmployeeVacationsTableFooterCell {
  props?: Record<string, unknown>
  element?: Component
}

interface EmployeeVacationsTableColumn<T = void>
  extends Omit<BaseTableColumn<T>, 'footer'> {
  footer?:
    | string
    | number
    | ((row: T) => void)
    | EmployeeVacationsTableFooterCell
}

interface EmployeeVacationsTableFooterCell {
  props?: Record<string, unknown>
  element?: Component
}

interface EmployeeVacationsTableColumn<T = void>
  extends Omit<BaseTableColumn<T>, 'footer'> {
  footer?:
    | string
    | number
    | ((row: T) => void)
    | EmployeeVacationsTableFooterCell
}

export default function useVacationsTableColumns(employee: Ref<Employee>) {
  const action: ActionHandler = {
    handler: () => {},
  }

  const { $isSmallDevice } = useDeviceSize()
  const isAdmin = computed(
    () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
  )

  const vacationsUnit = computed(() => {
    return employee.value?.hour_holidays ? 'h' : 'd'
  })

  const columns = getColumns(action, $isSmallDevice, isAdmin, vacationsUnit)

  return {
    columns,
    onAction: (callback: ActionHandler['handler']) =>
      (action.handler = callback),
  }
}

function getColumns(
  action: ActionHandler,
  $isSmallDevice: ComputedRef<boolean>,
  isAdmin: ComputedRef<boolean>,
  vacationsUnit: ComputedRef<string>
): ComputedRef<EmployeeVacationsTableColumn<EmployeeVacations>[]> {
  return computed(() => {
    const cols: EmployeeVacationsTableColumn<EmployeeVacations>[] = [
      titleColumn(),
      timePeriodColumn(),
      statusColumn(),
      plannedColumn(vacationsUnit),
      spentColumn(vacationsUnit),
      transferredColumn(vacationsUnit),
      remainingColumn(vacationsUnit),
      actionsColumn(action, isAdmin),
    ]

    return cols.map((c) => {
      c.width = $isSmallDevice.value ? '180px' : '12.8%'
      return c
    })
  })
}

function titleColumn(): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `${HEADER_CLASS} flex-2 md:pl-4 ml-4`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.title'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.title',
      },
    },
    cell: {
      class: `${CELL_CLASS} flex-2 md:pl-4 ml-4`,
      props: {
        value: (row: EmployeeVacations) => row.title,
      },
    },
    footer: {
      element: EmployeeVacationsFooterCell,
      props: {
        value: () => i18n.t('employee.employee_details.vacations.footer.total'),
      },
    },
  }
}

function timePeriodColumn(): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `${HEADER_CLASS} flex-3 md:mr-10 whitespace-nowrap`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.time_period'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.time_period',
      },
    },
    cell: {
      class: `${CELL_CLASS} flex-3 md:mr-10 whitespace-nowrap`,
      props: {
        value: (row: EmployeeVacations) => {
          const date1 = new Date(row.starts_at)
          const date2 = new Date(row.ends_at)
          return `${formatDate(date1, DATE_FORMAT)} - ${formatDate(
            date2,
            DATE_FORMAT
          )}`
        },
      },
    },
  }
}

function statusColumn(): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `${HEADER_CLASS} md:mr-6 flex-1.5`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.status'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.status',
      },
    },
    cell: {
      element: TableRowCellStatus,
      class: `${CELL_CLASS} text-left md:mr-6 flex-1.5`,
      props: {
        value: (row: EmployeeVacations) => row.status,
      },
    },
  }
}

function plannedColumn(
  vacationsUnit: ComputedRef<string>
): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `${HEADER_CLASS} justify-start md:justify-start`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.planned'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.planned',
      },
    },
    cell: {
      class: (row: EmployeeVacations) =>
        `${CELL_CLASS} text-left ${
          row.status === 'used' ? 'planned-column-used' : null
        }`,
      props: {
        value: (row: EmployeeVacations) =>
          `${row.planned_days.toFixed(1)} ${vacationsUnit.value}`,
      },
    },
    footer: {
      element: EmployeeVacationsFooterCell,
      props: {
        tooltipLabel: () =>
          i18n.t(
            'employee.employee_details.vacations.footer.planned_tooltip_label'
          ),
        value: () =>
          `${store.state[MODULE_NAME].vacationsMeta.total_planned.toFixed(1)} ${
            vacationsUnit.value
          }`,
      },
    },
  }
}

function spentColumn(
  vacationsUnit: ComputedRef<string>
): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `${HEADER_CLASS}`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.spent'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.spent',
      },
    },
    cell: {
      class: `${CELL_CLASS}`,
      props: {
        value: (row: EmployeeVacations) =>
          `${row.spent_days.toFixed(1)} ${vacationsUnit.value}`,
      },
    },
    footer: {
      element: EmployeeVacationsFooterCell,
      props: {
        value: () =>
          `${store.state[MODULE_NAME].vacationsMeta.total_spent.toFixed(1)} ${
            vacationsUnit.value
          }`,
      },
    },
  }
}

function transferredColumn(
  vacationsUnit: ComputedRef<string>
): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `${HEADER_CLASS} justify-end`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.transferred'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.transferred',
      },
    },
    cell: {
      class: `${CELL_CLASS} text-right`,
      props: {
        value: (row: EmployeeVacations) =>
          `${row.transferred_days.toFixed(1)} ${vacationsUnit.value}`,
      },
    },
    footer: {
      element: EmployeeVacationsFooterCell,
      props: {
        value: () =>
          `${store.state[MODULE_NAME].vacationsMeta.total_transferred.toFixed(
            1
          )} ${vacationsUnit.value}`,
      },
    },
  }
}

function remainingColumn(
  vacationsUnit: ComputedRef<string>
): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `${HEADER_CLASS} justify-end`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.remaining'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.remaining',
      },
    },
    cell: {
      class: `${CELL_CLASS} text-right md:text-right`,
      props: {
        value: (row: EmployeeVacations) =>
          `${row.remaining_days.toFixed(1)} ${vacationsUnit.value}`,
      },
    },
    footer: {
      element: EmployeeVacationsFooterCell,
      props: {
        value: () =>
          `${store.state[MODULE_NAME].vacationsMeta.total_remaining.toFixed(
            1
          )} ${vacationsUnit.value}`,
      },
    },
  }
}

function actionsColumn(
  action: ActionHandler,
  isAdmin: ComputedRef<boolean>
): EmployeeVacationsTableColumn<EmployeeVacations> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCellDefault,
      class: `justify-end mr-5 flex-2`,
      props: {
        value: () =>
          i18n.t('employee.employee_details.vacations.row_cells.actions'),
        dataIdAttr: 'employee.employee_details.vacations.row_cells.actions',
      },
    },
    cell: {
      element: TableRowCellActions,
      class: `justify-end mr-4 flex-2`,
      props: {
        disableTransfer: (row: EmployeeVacations) =>
          !row.expires || row.status === 'used',
        showTransfer: (row: EmployeeVacations) => row.transfer_remaining,
        isAdmin: isAdmin.value,
      },
      events: {
        'history-action-click': (row) =>
          action.handler('history', { id: row.id }),
        'edit-action-click': (row) => action.handler('edit', { id: row.id }),
        'transfer-action-click': (row) =>
          action.handler('transfer', { id: row.id }),
      },
    },
  }
}
