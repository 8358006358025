














import { defineComponent, PropType, computed } from '@vue/composition-api'

import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import EmployeeListTooltipList from './EmployeeListTooltipList.vue'
import { getBadgeTypeByRole } from './employee.utils'
import { Role } from '@/app/core/user/models/role.model'

export default defineComponent({
  name: 'EmployeeRoles',
  components: {
    BaseBadge,
    EmployeeListTooltipList,
  },
  props: {
    value: {
      type: Array as PropType<Role[]>,
      required: true,
    },
    tableView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const passedValue = computed(() =>
      props.value.map((item) => ({ label: item.localized_name, ...item }))
    )

    return {
      getBadgeTypeByRole,
      passedValue,
    }
  },
})
