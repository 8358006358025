































































import {
  defineComponent,
  computed,
  watch,
  onUnmounted,
  PropType,
} from '@vue/composition-api'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'
import BaseDatePicker from '@/app/util-modules/ui/date-picker/BaseDatePicker.vue'
import EmployeeRecordsContractSettingsEditFormLabel from './EmployeeRecordsContractSettingsEditFormLabel.vue'
import { ContractSettingsEdit } from '../../models/employee.model'
import { formatToIso } from '@/utils/date-time.utils'
import { preventNonNumericChar } from '../employee.utils'

export default defineComponent({
  name: 'EmployeeRecordsContractSettingsEditForm',
  components: {
    BaseField,
    BaseInputNumber,
    BaseDatePicker,
    RecordsSettingsFormLabel: EmployeeRecordsContractSettingsEditFormLabel,
  },
  props: {
    value: {
      type: Object as PropType<ContractSettingsEdit>,
      default: () => ({}),
    },
    isStartOfAccountingDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const passedValue = computed({
      get() {
        return props.value
      },
      set(newValue) {
        emit('input', newValue)
      },
    })

    const startOfAccounting = computed({
      get() {
        return new Date(props.value.start_of_accounting)
      },
      set(newValue: Date) {
        emit('input', {
          ...props.value,
          start_of_accounting: formatToIso(newValue),
        })
      },
    })

    const unwatch = watch(
      () => props.value,
      () => {
        emit('updated')
      },
      { deep: true }
    )

    onUnmounted(unwatch)

    return { passedValue, startOfAccounting, preventNonNumericChar }
  },
})
