<template>
  <div class="text-right">
    <div v-if="canActivateDeactivate">
      <button
        :class="isActive ? 'text-error-400' : 'text-green'"
        data-id="employee.employee_details.general.deactivate_account"
        @click="$emit('click')"
      >
        {{
          isActive
            ? $t('employee.employee_details.general.deactivate_account')
            : $t('employee.employee_details.general.activate_account')
        }}
      </button>
    </div>

    <base-tooltip
      v-else
      :label="
        isCurrentUser
          ? $t('employee.employee_details.general.cannot_deactivate_self')
          : $t('employee.employee_details.general.cannot_deactivate_employee')
      "
      position="is-top"
      multilined
    >
      <div class="text-coal-300 cursor-default">
        {{ $t('employee.employee_details.general.deactivate_account') }}
      </div>
    </base-tooltip>
  </div>
</template>

<script>
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'

export default {
  name: 'EmployeeGeneralActivateDeactivate',
  components: {
    BaseTooltip,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    canActivateDeactivate: {
      type: Boolean,
      default: false,
    },
    isCurrentUser: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
