import store from '@/store'
import { State, RecordsIndexes } from '@/utils/jsonapi/types'
import {
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import { BaseResponseMeta } from '@/utils/api/base-response'
import { AREA_PUBLIC_GETTER_AREA_LIST } from '@/app/core/area/store/getters/area.getters.names'
import { USER_PUBLIC_GETTER_ROLE_LIST } from '@/app/core/user/store/getters/user.getters.names'
import { TAG_PUBLIC_GETTER_TAG_LIST } from '@/app/core/tag/store/getters/tag.getters.names'
import { getDateRangeThisMonth } from '@/utils/date-time.utils'
import {
  Employee,
  Area,
  Tag,
  Workspace,
  Filter,
  TimeTrackingMethod,
  DataProfile,
  CustomField,
  CustomFieldValue,
  TargetHours,
  EMPLOYEE_TYPE,
  TIME_TRACKING_METHOD_TYPE,
  TARGET_HOURS_TYPE,
  DATA_PROFILE_TYPE,
  CUSTOM_FIELD_TYPE,
  CUSTOM_FIELD_VALUE_TYPE,
  RECORDS_TYPE,
  EmployeeRecords,
  VACATIONS_TYPE,
  EmployeeVacations,
} from '../models/employee.model'
import { Role } from '@/app/core/user/models/role.model'
import { TIME_FORMAT_TYPE } from '@/constants'

export interface TargetHoursResponseMeta extends BaseResponseMeta {
  last_updated_at: string
  last_updated_by: string
  can_create_target_hours: boolean
}
export interface RecordsResponseMeta extends BaseResponseMeta {
  last_records_settings_updated_at: string
  last_records_settings_updated_by: string
  last_record_updated_by: string
  last_record_updated_at: string

  total_absent: number
  total_actual: number
  total_compensation: number
  total_count: number
  total_difference: number
  total_pages: number
  total_plan: number
  total_target: number
}

export interface VacationsResponseMeta extends BaseResponseMeta {
  total_planned: number
  total_spent: number
  total_transferred: number
  total_remaining: number
}

export type EmployeeTab = 'all' | 'active' | 'inactive'
type RecordTimeFormat =
  | typeof TIME_FORMAT_TYPE.STANDARD
  | typeof TIME_FORMAT_TYPE.DECIMAL

export interface EmployeeState extends State {
  records: {
    [EMPLOYEE_TYPE]: Employee[]
    [DATA_PROFILE_TYPE]: DataProfile[]
    [CUSTOM_FIELD_TYPE]: CustomField[]
    [CUSTOM_FIELD_VALUE_TYPE]: CustomFieldValue[]
    area: () => Area[]
    role: () => Role[]
    tag: () => Tag[]
    workspace: Workspace[]
    [TIME_TRACKING_METHOD_TYPE]: TimeTrackingMethod[]
    [TARGET_HOURS_TYPE]: TargetHours[]
    [RECORDS_TYPE]: EmployeeRecords[]
    [VACATIONS_TYPE]: EmployeeVacations[]
  }
  // used for data indexing by jsonapi utils.
  recordsIndexes: RecordsIndexes

  pagination: BaseRequestPagination
  sorting: BaseRequestSorting
  filters: Record<string, Filter>
  selectedTab: EmployeeTab
  meta: BaseResponseMeta

  targetHoursFilters: Record<string, Filter>
  targetHoursPagination: BaseRequestPagination
  targetHoursMeta: TargetHoursResponseMeta

  recordsFilterDateRange: Date[]
  recordsSorting: BaseRequestSorting
  recordsPagination: BaseRequestPagination
  recordsMeta: RecordsResponseMeta
  recordTimeFormat: RecordTimeFormat
  vacationsPagination: BaseRequestPagination
  vacationsSorting: BaseRequestSorting
  vacationsMeta: VacationsResponseMeta
}

/* istanbul ignore next */
export const makeEmptyState = (): EmployeeState => ({
  records: {
    [EMPLOYEE_TYPE]: [],
    [DATA_PROFILE_TYPE]: [],
    [CUSTOM_FIELD_TYPE]: [],
    [CUSTOM_FIELD_VALUE_TYPE]: [],
    area: () => store.getters[AREA_PUBLIC_GETTER_AREA_LIST],
    role: () => store.getters[USER_PUBLIC_GETTER_ROLE_LIST],
    tag: () => store.getters[TAG_PUBLIC_GETTER_TAG_LIST],
    workspace: [],
    [TIME_TRACKING_METHOD_TYPE]: [],
    [TARGET_HOURS_TYPE]: [],
    [RECORDS_TYPE]: [],
    [VACATIONS_TYPE]: [],
  },
  recordsIndexes: {},
  pagination: { items: 30, page: 0 },
  sorting: { username: 1 },
  filters: {},
  selectedTab: 'all',
  meta: {
    current_page: 0,
    page_items: 0,
    total_pages: 1,
    total_count: 0,
  },

  targetHoursFilters: {},
  targetHoursPagination: { items: 30, page: 0 },
  targetHoursMeta: {
    current_page: 0,
    page_items: 0,
    total_pages: 1,
    total_count: 0,
    last_updated_at: '',
    last_updated_by: '',
    can_create_target_hours: false,
  },

  recordsFilterDateRange: getDateRangeThisMonth(),
  recordsSorting: { day: 1 },
  recordsPagination: { items: 30, page: 0 },
  recordsMeta: {
    current_page: 0,
    page_items: 0,
    last_records_settings_updated_at: '',
    last_records_settings_updated_by: '',
    last_record_updated_by: '',
    last_record_updated_at: '',

    total_pages: 1,
    total_absent: 0,
    total_actual: 0,
    total_compensation: 0,
    total_count: 0,
    total_difference: 0,
    total_plan: 0,
    total_target: 0,
  },
  recordTimeFormat: TIME_FORMAT_TYPE.STANDARD,

  vacationsPagination: { items: 30, page: 0 },
  vacationsSorting: { ends_at: -1 },
  vacationsMeta: {
    total_planned: 0,
    total_spent: 0,
    total_transferred: 0,
    total_remaining: 0,

    current_page: 0,
    page_items: 0,
    total_pages: 1,
    total_count: 0,
  },
})

export default makeEmptyState()
