<template>
  <bottom-sheet
    :header-right-actions="[
      {
        title: submitButtonText,
        event: 'submit',
        loading: actionStatus.isLoading,
        class: submitDisabled ? 'text-coal-550' : 'text-green',
        disabled: submitDisabled,
      },
    ]"
    :header-title="title"
    @submit="$emit('submit')"
    @cancel-click="$emit('close')"
  >
    <slot />
  </bottom-sheet>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'

export default {
  name: 'EmployeeAvatarUpdateBottomSheet',
  components: {
    BottomSheet,
  },
  props: {
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    submitDisabled: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
