

















import { defineComponent } from '@vue/composition-api'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'

export default defineComponent({
  name: 'TableRowCellDate',
  components: { BaseIcon, BaseTooltip },
  props: {
    value: {
      type: String,
      required: true,
    },
    isPublicHoliday: {
      type: Boolean,
      default: false,
    },
  },
})
