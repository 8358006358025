import { render, staticRenderFns } from "./EmployeeRecordsSwitchTime.vue?vue&type=template&id=1311b7e1&"
import script from "./EmployeeRecordsSwitchTime.vue?vue&type=script&lang=ts&"
export * from "./EmployeeRecordsSwitchTime.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_lh6vo7dceoovl5fyh3epe3vcle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports