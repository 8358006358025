























































import { defineComponent, ref } from '@vue/composition-api'
import store from '@/store'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper.vue'
import EditForm from './EmployeeRecordsContractSettingsEditForm.vue'
import { ContractSettingsEdit } from '../../models/employee.model'
import {
  EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS_ATTRIBUTES,
  EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS,
} from '../../store/actions/employee.actions.names'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'

export default defineComponent({
  name: 'EmployeeRecordsContractSettingsEdit',
  components: {
    BaseModal,
    BaseCardModalForm,
    BottomSheetFormWrapper,
    EditForm,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  trackedActions: {
    updateContractSettings: EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS,
  },
  setup(props, { emit }) {
    const contractSettings = ref<Partial<ContractSettingsEdit | null>>(null)

    const isStartOfAccountingDisabled = ref(false)

    const fetchContractSettings = async () => {
      await store
        .dispatch(
          EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS_ATTRIBUTES,
          props.userId
        )
        .then((response) => {
          contractSettings.value = response
          isStartOfAccountingDisabled.value = response.payrolls_created
        })
    }

    const handleSubmit = async () => {
      try {
        await store.dispatch(EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS, {
          userId: props.userId,
          contractSettings: contractSettings.value,
        })
        Snackbar.open(
          i18n.t(
            'employee.target_hours.contract_settings.edit_data_success_message'
          )
        )
        emit('close')
        emit('success')
      } catch (error) {
        Snackbar.open({
          message: i18n.t(
            'employee.target_hours.contract_settings.edit_data_error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    fetchContractSettings()

    return {
      isStartOfAccountingDisabled,
      contractSettings,
      handleSubmit,
    }
  },
})
