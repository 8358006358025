






































import { defineComponent } from '@vue/composition-api'
import BaseGroupSelectionToolbarActionItem from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionItem.vue'
import BaseGroupSelectionToolbarActionButton from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionButton.vue'

export default defineComponent({
  name: 'TableRowCellActions',
  components: {
    BaseGroupSelectionToolbarActionItem,
    BaseGroupSelectionToolbarActionButton,
  },
  props: {
    disableTransfer: {
      type: Boolean,
      required: true,
    },
    showTransfer: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
})
