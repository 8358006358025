import { computed, ComputedRef, ref } from '@vue/composition-api'

export default function useListScroll(
  $refs: any,
  listLength: ComputedRef<number>
) {
  const prevScrollOffset = ref(0)
  let onScrollEnd: () => void = () => {}
  const ROW_HEIGHT = 44
  // we need this value to load the next page
  // when the user is in the middle of the list
  const halfListLength = computed(() => listLength.value * 0.5)

  const handleScroll = () => {
    const currentOffset = $refs.noEmailList.scrollTop as number
    const rowsOffsetSum = ROW_HEIGHT * halfListLength.value

    if (currentOffset - prevScrollOffset.value > rowsOffsetSum) {
      prevScrollOffset.value = currentOffset
      onScrollEnd()
    }
  }

  return {
    handleScroll,
    onScrollEnd: (callback: () => void) => (onScrollEnd = callback),
  }
}
