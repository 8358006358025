













































import { defineComponent, ref, PropType } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import FormModal from './data-section-form/DataSectionFormModal.vue'
import FormBottomSheet from './data-section-form/DataSectionFormBottomSheet.vue'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'
import {
  EMPLOYEE_ACTION_GET_DATA_PROFILE,
  EMPLOYEE_ACTION_UPDATE_DATA_PROFILE,
} from '../../store/actions/employee.actions.names.js'
import { DataProfile, CustomField } from '../../models/employee.model'

export default defineComponent({
  name: 'EmployeeGeneralDataSectionEdit',
  components: {
    FormModal,
    FormBottomSheet,
    BaseDialog,
  },
  props: {
    section: {
      type: Object as PropType<DataProfile>,
      required: true,
    },
    employeeId: {
      type: String,
      required: true,
    },
  },
  trackedActions: {
    updateDataProfile: EMPLOYEE_ACTION_UPDATE_DATA_PROFILE,
  },
  setup(props, { emit }) {
    const isPayloadChanged = ref(false)
    const isDiscardChangeDialogOpen = ref(false)
    const payload = ref<DataProfile>({
      id: '',
      title: '',
      enabled: false,
      custom_fields: [],
    })

    const loadSection = () =>
      store
        .dispatch(EMPLOYEE_ACTION_GET_DATA_PROFILE, {
          dataProfileId: props.section.id,
          userId: props.employeeId,
        })
        .then(() => {
          payload.value = { ...props.section }
          payload.value.custom_fields = props.section.custom_fields.map(
            (customField) => ({
              ...customField,
            })
          )
        })

    const handleInput = (customFields: CustomField[]) => {
      payload.value.custom_fields = customFields
      isPayloadChanged.value = true
    }

    const handleEdit = async () => {
      await store.dispatch(EMPLOYEE_ACTION_UPDATE_DATA_PROFILE, {
        dataProfile: payload.value,
        userId: props.employeeId,
      })

      Snackbar.open(
        i18n.t(
          'employee.employee_details.general.edit_data_section_success_message'
        )
      )
      emit('updated')
      emit('close')
    }

    const handleCloseModal = () => {
      if (isPayloadChanged.value) isDiscardChangeDialogOpen.value = true
      else emit('close')
    }

    const handleDiscardChanges = () => {
      isDiscardChangeDialogOpen.value = false
      emit('close')
    }

    loadSection()

    return {
      handleInput,
      handleEdit,
      handleCloseModal,
      handleDiscardChanges,

      isDiscardChangeDialogOpen,
      isPayloadChanged,
      payload,
    }
  },
})
