












































































import {
  defineComponent,
  ref,
  toRefs,
  computed,
  PropType,
} from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n'
import BaseTable from '@/app/util-modules/ui/table/BaseTable.vue'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import EmployeeVacationsTableFooter from '../components/employee-vacations/EmployeeVacationsTableFooter.vue'
import useTableScroll from '@/utils/composables/use-table-scroll'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import VacationsAdd from '../components/employee-vacations/EmployeeVacationsAdd.vue'
import VacationsEdit from '../components/employee-vacations/EmployeeVacationsEdit.vue'
import useVacationsTableColumns from './composables/use-vacations-table-column'
import useUserId from './composables/use-user-id'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import {
  VACATIONS_TYPE,
  EmployeeVacations,
  Employee,
} from '../models/employee.model'
import {
  EMPLOYEE_ACTION_LIST_VACATIONS,
  EMPLOYEE_ACTION_LIST_APPEND_VACATIONS,
  EMPLOYEE_ACTION_TRANSFER_VACATION,
} from '../store/actions/employee.actions.names'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { MODULE_NAME } from '../employee.module'
import EmployeeVacationsHistory from '../components/employee-vacations/EmployeeVacationsHistory.vue'
import EmployeeVacationsViewSwitch from '../components/employee-vacations/EmployeeVacationsViewSwitch.vue'

export default defineComponent({
  name: 'EmployeeDetailsVacationsPage',
  components: {
    BaseTable,
    BaseButton,
    VacationsAdd,
    VacationsEdit,
    TableFooter: EmployeeVacationsTableFooter,
    EmployeeVacationsHistory,
    ViewSwitch: EmployeeVacationsViewSwitch,
  },

  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },

  setup(props, { refs }) {
    const ROW_HEIGHT = 64
    const ROW_NUMB = 18
    const { userId } = useUserId()
    const { employee } = toRefs(props)

    const isAdmin = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )

    const togglePopup = (type: string, value: any) => {
      popups.value[type] = value
    }

    const fetchMoreVacations = () => {
      if (
        $actions.listVacations.isLoading ||
        $actions.appendVacations.isLoading ||
        $actions.transferVacation.isLoading
      )
        return
      store.dispatch(EMPLOYEE_ACTION_LIST_APPEND_VACATIONS, userId.value)
    }

    const refetchVacations = () => {
      window.scrollTo(0, 0)
      prevScrollOffset.value = 0

      store.dispatch(EMPLOYEE_ACTION_LIST_VACATIONS, userId.value)
    }

    const refetchAndCloseVacations = () => {
      togglePopup('vacationDetails', null)
      refetchVacations()
    }

    const { columns, onAction } = useVacationsTableColumns(employee)
    const { handleScroll, prevScrollOffset } = useTableScroll(
      refs,
      ROW_HEIGHT,
      ROW_NUMB,
      fetchMoreVacations,
      true
    )

    /* eslint-disable-next-line no-undef */
    const handleTransfer = async (vacationId: RecordId) => {
      await store.dispatch(EMPLOYEE_ACTION_TRANSFER_VACATION, {
        userId: userId.value,
        vacationId,
      })
      Snackbar.open(
        i18n.t('employee.employee_details.vacations.transfer.success_message')
      )
      refetchVacations()
    }

    onAction((action: string, params: any) => {
      switch (action) {
        case 'edit':
          togglePopup('edit', params.id ?? null)
          break
        case 'history':
          togglePopup('history', params.id ?? null)
          break
        case 'transfer':
          handleTransfer(params.id ?? null)
          break
      }
    })

    const vacationsList = computed(
      () => store.state[MODULE_NAME].records[VACATIONS_TYPE]
    )

    const popups = ref<Record<string, EmployeeVacations | boolean | null>>({
      vacationDetails: null,
      edit: null,
      add: false,
      history: null,
      transfer: null,
    })

    const $actions = useActionTracker({
      listVacations: EMPLOYEE_ACTION_LIST_VACATIONS,
      appendVacations: EMPLOYEE_ACTION_LIST_APPEND_VACATIONS,
      transferVacation: EMPLOYEE_ACTION_TRANSFER_VACATION,
    })

    refetchVacations()

    return {
      ROW_HEIGHT,
      columns,
      isAdmin,
      vacationsList,
      popups,
      $actions,
      userId,

      togglePopup,
      handleScroll,
      refetchVacations,
      refetchAndCloseVacations,
    }
  },
})
