import {
  setRecords,
  appendRecords,
  replaceRecord,
  clearRecords,
  addIncluded,
} from '@/utils/jsonapi/records.mutations'
import {
  attachRelationships,
  detachRelationships,
} from '@/utils/jsonapi/relationships.mutations'
import { shortname } from '@/utils/store'
import { BaseRequestSorting } from '@/utils/api/base-request'
import { BaseResponseMeta } from '@/utils/api/base-response'
import {
  EmployeeState,
  TargetHoursResponseMeta,
  EmployeeTab,
  RecordsResponseMeta,
  VacationsResponseMeta,
} from '../employee.state'
import { Filter } from '../../models/employee.model'
import * as mutationNamesObj from './employee.mutations.names'
import { TIME_FORMAT_TYPE } from '@/constants'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.EMPLOYEE_MUTATION_SET_RECORDS]: setRecords,

  [mutationNames.EMPLOYEE_MUTATION_APPEND_RECORDS]: appendRecords,

  [mutationNames.EMPLOYEE_MUTATION_REPLACE_RECORD]: replaceRecord,

  [mutationNames.EMPLOYEE_MUTATION_CLEAR_RECORDS]: clearRecords,

  [mutationNames.EMPLOYEE_MUTATION_ATTACH_RELATIONSHIPS]: attachRelationships,

  [mutationNames.EMPLOYEE_MUTATION_DETACH_RELATIONSHIPS]: detachRelationships,

  [mutationNames.EMPLOYEE_MUTATION_ADD_INCLUDED]: addIncluded,

  [mutationNames.EMPLOYEE_MUTATION_SET_META]: (
    state: EmployeeState,
    meta: BaseResponseMeta
  ) => {
    state.meta = meta
  },

  [mutationNames.EMPLOYEE_MUTATION_INCREMENT_PAGE]: (state: EmployeeState) => {
    state.pagination.page++
  },

  [mutationNames.EMPLOYEE_MUTATION_RESET_PAGE]: (state: EmployeeState) => {
    state.pagination.page = 0
  },

  [mutationNames.EMPLOYEE_MUTATION_SET_SORTING_FIELD]: (
    state: EmployeeState,
    sorting: BaseRequestSorting
  ) => {
    state.sorting = { ...state.sorting, ...sorting }
  },

  [mutationNames.EMPLOYEE_MUTATION_ADD_FILTER]: (
    state: EmployeeState,
    filter: Filter
  ) => {
    state.filters = {
      ...state.filters,
      [filter.field]: { ...filter },
    }
  },

  [mutationNames.EMPLOYEE_MUTATION_REMOVE_FILTER]: (
    state: EmployeeState,
    filterKey: string
  ) => {
    const { [filterKey]: removed, ...rest }: any = state.filters
    state.filters = rest
  },

  [mutationNames.EMPLOYEE_MUTATION_RESET_FILTERS]: (state: EmployeeState) => {
    state.filters = {}
  },

  [mutationNames.EMPLOYEE_TARGET_HOURS_MUTATION_ADD_FILTER]: (
    state: EmployeeState,
    filter: Filter
  ) => {
    state.targetHoursFilters = {
      ...state.targetHoursFilters,
      [filter.field]: { ...filter },
    }
  },

  [mutationNames.EMPLOYEE_TARGET_HOURS_MUTATION_REMOVE_FILTER]: (
    state: EmployeeState,
    filterKey: string
  ) => {
    const { [filterKey]: removed, ...rest }: any = state.targetHoursFilters
    state.targetHoursFilters = rest
  },

  [mutationNames.EMPLOYEE_MUTATION_SET_TARGET_HOURS_META]: (
    state: EmployeeState,
    meta: TargetHoursResponseMeta
  ) => {
    state.targetHoursMeta = meta
  },

  [mutationNames.EMPLOYEE_MUTATION_INCREMENT_TARGET_HOURS_PAGE]: (
    state: EmployeeState
  ) => {
    state.targetHoursPagination.page++
  },

  [mutationNames.EMPLOYEE_MUTATION_RESET_TARGET_HOURS_PAGE]: (
    state: EmployeeState
  ) => {
    state.targetHoursPagination.page = 0
  },

  [mutationNames.EMPLOYEE_MUTATION_SET_CURRENT_TAB]: (
    state: EmployeeState,
    { tab }: { tab: EmployeeTab }
  ) => {
    state.selectedTab = tab
  },

  [mutationNames.EMPLOYEE_RECORDS_MUTATION_SET_SORTING_FIELD]: (
    state: EmployeeState,
    sorting: BaseRequestSorting
  ) => {
    state.recordsSorting = { ...state.recordsSorting, ...sorting }
  },

  [mutationNames.EMPLOYEE_RECORDS_MUTATION_SET_META]: (
    state: EmployeeState,
    meta: RecordsResponseMeta
  ) => {
    state.recordsMeta = meta
  },

  [mutationNames.EMPLOYEE_RECORDS_MUTATION_INCREMENT_PAGE]: (
    state: EmployeeState
  ) => {
    state.recordsPagination.page++
  },

  [mutationNames.EMPLOYEE_RECORDS_MUTATION_RESET_PAGE]: (
    state: EmployeeState
  ) => {
    state.recordsPagination.page = 0
  },

  [mutationNames.EMPLOYEE_RECORDS_MUTATION_SET_DATE_RANGE]: (
    state: EmployeeState,
    dateRange: Date[]
  ) => {
    state.recordsFilterDateRange = dateRange
  },

  [mutationNames.EMPLOYEE_RECORDS_MUTATION_SWITCH_TIME_FORMAT]: (
    state: EmployeeState
  ) => {
    if (state.recordTimeFormat === TIME_FORMAT_TYPE.STANDARD)
      state.recordTimeFormat = TIME_FORMAT_TYPE.DECIMAL
    else state.recordTimeFormat = TIME_FORMAT_TYPE.STANDARD
  },

  [mutationNames.EMPLOYEE_MUTATION_SET_VACATIONS_META]: (
    state: EmployeeState,
    meta: VacationsResponseMeta
  ) => {
    state.vacationsMeta = meta
  },

  [mutationNames.EMPLOYEE_MUTATION_INCREMENT_VACATIONS_PAGE]: (
    state: EmployeeState
  ) => {
    state.vacationsPagination.page++
  },

  [mutationNames.EMPLOYEE_MUTATION_RESET_VACATIONS_PAGE]: (
    state: EmployeeState
  ) => {
    state.vacationsPagination.page = 0
  },
}
