import i18n from '@/utils/vendors/i18n'
import {
  CustomField,
  CustomFieldValue,
  Employee,
} from '../models/employee.model'
import { formatDate } from '@/utils/date-time.utils'
import { objectHasOwnProperty } from '../components/employee.utils'

export function formatCustomField(customField?: CustomField) {
  const isValidValue = customField && customField.value
  const isBooleanDataType = customField && customField.data_type === 'boolean'

  return isValidValue || isBooleanDataType
    ? baseFieldFormatter(customField)
    : '--'
}

function baseFieldFormatter(customField: CustomField) {
  switch (customField.data_type) {
    case 'date':
      return formatDate(new Date(customField.value))
    case 'boolean':
      return formatCheckboxValue(customField.value)
    case 'dropdown':
      return getDropdownValue(customField)
    default:
      return customField.value
  }
}

function formatCheckboxValue(value: string) {
  return value
    ? i18n.t('employee.custom_field_values.checkbox.true')
    : i18n.t('employee.custom_field_values.checkbox.false')
}

function getDropdownValue(customField: CustomField) {
  if (customField.value?.name) {
    return customField.value.name
  } else if (objectHasOwnProperty(customField, 'multiselect')) {
    return getMultiselectDropdownValue(customField)
  } else return '--'
}

function getMultiselectDropdownValue(customField: CustomField) {
  if (customField.value.length) {
    return customField.value
      .map((field: { name: string }) => field.name)
      .join(', ')
  } else return '--'
}

export function addValueToCustomFields(
  customFields: CustomField[],
  employee: Employee
) {
  return customFields.map(({ ...field }) =>
    addValueToSingleCustomField(field, employee)
  )
}

export function addValueToSingleCustomField(
  customField: CustomField,
  employee: Employee
) {
  const field = { ...customField }
  const customFieldValue = findMatchingValue(field, employee)

  if (customFieldValue) {
    field.value = extractValueFromCustomFieldValue(customFieldValue, field)
    // We need this property in the PATCH request,
    // to update the data profile
    field.custom_field_value_id = customFieldValue.id
  }
  return field
}

function findMatchingValue(field: CustomField, employee: Employee) {
  return employee.custom_field_values?.find(
    (value) => value.custom_field_id === field.id
  )
}

function extractValueFromCustomFieldValue(
  customFieldValue: CustomFieldValue,
  customField: CustomField
) {
  if (
    customFieldValue.data_type === 'dropdown_value' &&
    customFieldValue.value
  ) {
    // The value of a dropdown is the option id
    // these function change the id to the actual option object
    if (customField.multiselect) {
      return customFieldValue.value.map((field: string) =>
        customField.dropdown_options?.find((option) => option.id === field)
      )
    } else {
      const option = customField.dropdown_options?.find(
        (option) => option.id === customFieldValue.value
      )
      return option ? option : ''
    }
  } else {
    return customFieldValue.value
  }
}
