import { MODULE_NAME } from '../../employee.module'

export const EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES = `${MODULE_NAME}/ACTION_LIST_APPEND_EMPLOYEES`
export const EMPLOYEE_ACTION_LIST_EMPLOYEES = `${MODULE_NAME}/ACTION_LIST_EMPLOYEES`
export const EMPLOYEE_ACTION_CREATE_EMPLOYEE = `${MODULE_NAME}/ACTION_CREATE_EMPLOYEE`
export const EMPLOYEE_ACTION_UPDATE_EMPLOYEE = `${MODULE_NAME}/ACTION_UPDATE_EMPLOYEE`
export const EMPLOYEE_ACTION_UPDATE_EMPLOYEE_EMAIL = `${MODULE_NAME}/ACTION_UPDATE_EMPLOYEE_EMAIL`
export const EMPLOYEE_ACTION_UPDATE_EMPLOYEE_AVATAR = `${MODULE_NAME}/ACTION_UPDATE_EMPLOYEE_AVATAR`
export const EMPLOYEE_ACTION_DELETE_EMPLOYEE_AVATAR = `${MODULE_NAME}/ACTION_DELETE_EMPLOYEE_AVATAR`
export const EMPLOYEE_ACTION_GET_EMPLOYEE = `${MODULE_NAME}/ACTION_GET_EMPLOYEE`
export const EMPLOYEE_ACTION_DELETE_EMPLOYEE = `${MODULE_NAME}/ACTION_DELETE_EMPLOYEE`
export const EMPLOYEE_ACTION_LIST_UNREGISTERED = `${MODULE_NAME}/ACTION_LIST_UNREGISTERED`
export const EMPLOYEE_ACTION_INVITE_EMPLOYEES = `${MODULE_NAME}/ACTION_INVITE_EMPLOYEES`
export const EMPLOYEE_ACTION_GET_INVITE_LINK = `${MODULE_NAME}/ACTION_GET_INVITE_LINK`
export const EMPLOYEE_ACTION_LIST_TRACKING_METHODS = `${MODULE_NAME}/ACTION_LIST_TRACKING_METHODS`
export const EMPLOYEE_PUBLIC_ACTION_ATTACH_AREA = `${MODULE_NAME}/PUBLIC_ACTION_ATTACH_AREA`
export const EMPLOYEE_PUBLIC_ACTION_DETACH_AREA = `${MODULE_NAME}/PUBLIC_ACTION_DETACH_AREA`

export const EMPLOYEE_ACTION_GET_TARGET_HOURS = `${MODULE_NAME}/ACTION_GET_TARGET_HOURS`
export const EMPLOYEE_ACTION_CREATE_TARGET_HOURS = `${MODULE_NAME}/ACTION_CREATE_TARGET_HOURS`
export const EMPLOYEE_ACTION_LIST_TARGET_HOURS = `${MODULE_NAME}/ACTION_LIST_TARGET_HOURS`
export const EMPLOYEE_ACTION_LIST_APPEND_TARGET_HOURS = `${MODULE_NAME}/ACTION_LIST_APPEND_TARGET_HOURS`
export const EMPLOYEE_ACTION_LIST_ABSENCES = `${MODULE_NAME}/ACTION_LIST_ABSENCES`
export const EMPLOYEE_ACTION_UPDATE_TARGET_HOURS = `${MODULE_NAME}/ACTION_UPDATE_TARGET_HOURS`
export const EMPLOYEE_ACTION_DELETE_TARGET_HOURS = `${MODULE_NAME}/ACTION_DELETE_TARGET_HOURS`
export const EMPLOYEE_ACTION_UPDATE_EMPLOYEE_STATUS = `${MODULE_NAME}/ACTION_UPDATE_EMPLOYEE_STATUS`
export const EMPLOYEE_ACTION_LIST_DATA_PROFILES = `${MODULE_NAME}/ACTION_GET_DATA_PROFILE_LIST`
export const EMPLOYEE_ACTION_SELECT_COLUMNS_TO_SHOW = `${MODULE_NAME}/ACTION_SELECT_COLUMNS_TO_SHOW`
export const EMPLOYEE_ACTION_RESET_DEFAULT_COLUMNS_TO_SHOW = `${MODULE_NAME}/ACTION_RESET_DEFAULT_COLUMNS_TO_SHOW`
export const EMPLOYEE_ACTION_GET_DATA_PROFILE = `${MODULE_NAME}/ACTION_GET_DATA_PROFILE`
export const EMPLOYEE_ACTION_UPDATE_DATA_PROFILE = `${MODULE_NAME}/ACTION_UPDATE_DATA_PROFILE`
export const EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS = `${MODULE_NAME}/ACTION_GET_CONTRACT_SETTINGS`
export const EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS_ATTRIBUTES = `${MODULE_NAME}/ACTION_GET_CONTRACT_SETTINGS_ATTRIBUTES`
export const EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS = `${MODULE_NAME}/ACTION_UPDATE_CONTRACT_SETTINGS`
export const EMPLOYEE_ACTION_GET_RECORDS_SUMMARY = `${MODULE_NAME}/ACTION_GET_RECORDS_SUMMARY`
export const EMPLOYEE_ACTION_GET_VACATIONS_SUMMARY = `${MODULE_NAME}/ACTION_GET_VACATIONS_SUMMARY`

export const EMPLOYEE_ACTION_LIST_RECORDS = `${MODULE_NAME}/ACTION_LIST_RECORDS`
export const EMPLOYEE_ACTION_LIST_APPEND_RECORDS = `${MODULE_NAME}/ACTION_LIST_APPEND_RECORDS`
export const EMPLOYEE_ACTION_GET_RECORD = `${MODULE_NAME}/ACTION_GET_RECORD`
export const EMPLOYEE_ACTION_UPDATE_RECORD = `${MODULE_NAME}/ACTION_UPDATE_RECORD`

export const EMPLOYEE_ACTION_CREATE_VACATION = `${MODULE_NAME}/ACTION_CREATE_VACATION`
export const EMPLOYEE_ACTION_LIST_VACATIONS = `${MODULE_NAME}/ACTION_LIST_VACATIONS`
export const EMPLOYEE_ACTION_LIST_APPEND_VACATIONS = `${MODULE_NAME}/ACTION_LIST_APPEND_VACATIONS`
export const EMPLOYEE_ACTION_GET_VACATION_HISTORY = `${MODULE_NAME}/ACTION_GET_VACATION_HISTORY`
export const EMPLOYEE_ACTION_GET_VACATION = `${MODULE_NAME}/ACTION_GET_VACATION`
export const EMPLOYEE_ACTION_UPDATE_VACATION = `${MODULE_NAME}/ACTION_UPDATE_VACATION`
export const EMPLOYEE_ACTION_DELETE_VACATION = `${MODULE_NAME}/ACTION_DELETE_VACATION`
export const EMPLOYEE_ACTION_TRANSFER_VACATION = `${MODULE_NAME}/ACTION_TRANSFER_VACATION`
export const EMPLOYEE_ACTION_UPDATE_HOUR_HOLIDAYS = `${MODULE_NAME}/ACTION_UPDATE_HOUR_HOLIDAYS`
