











































import { defineComponent, PropType } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { EMPLOYEE_ACTION_CREATE_TARGET_HOURS } from '../store/actions/employee.actions.names'
import TargetHoursModal from './employee-target-hours/EmployeeTargetHoursModal.vue'
import TargetHoursBottomSheet from './employee-target-hours/EmployeeTargetHoursBottomSheet.vue'
import TargetHoursAbsenceDialog from './employee-target-hours/EmployeeTargetHoursAbsenceDialog.vue'
import usePayload from './composables/use-payload'
import useRhythm from './composables/use-rhythm'
import useAbsenceConfirmation from './composables/use-absence-confirmation'

export default defineComponent({
  name: 'EmployeeTargetHoursAdd',
  components: {
    TargetHoursModal,
    TargetHoursBottomSheet,
    TargetHoursAbsenceDialog,
  },
  props: {
    employeeId: {
      /* eslint-disable-next-line no-undef */
      type: String as PropType<RecordId>,
      required: true,
    },
  },
  trackedActions: {
    createTargetHours: EMPLOYEE_ACTION_CREATE_TARGET_HOURS,
  },

  setup(props, { emit }) {
    const { basePayload, weeklyPayload, customPayload } = usePayload()
    const { rhythm } = useRhythm(basePayload, weeklyPayload)
    const { isAbsenceDialogOpen, checkAbsences } = useAbsenceConfirmation(
      basePayload,
      props.employeeId,
      handleCreate
    )

    async function handleCreate(
      { reset_confirmed_absences } = { reset_confirmed_absences: false }
    ) {
      const bodyPayload =
        basePayload.value.rhythm_type === 'free' ? customPayload : weeklyPayload
      const payload = {
        ...basePayload.value,
        ...bodyPayload.value,
        reset_confirmed_absences,
      }

      const action = await store.dispatch(EMPLOYEE_ACTION_CREATE_TARGET_HOURS, {
        employeeId: props.employeeId,
        targetHours: payload,
      })

      Snackbar.open(i18n.t('employee.target_hours.form.success_message'))
      emit('success')
      emit('close')

      return action
    }

    return {
      rhythm,
      basePayload,
      weeklyPayload,
      customPayload,
      isAbsenceDialogOpen,

      handleCreate,
      checkAbsences,
    }
  },
})
