import { computed } from '@vue/composition-api'
import { getDurationBasedOnUserSetting } from '@/utils/date-time.utils'
import store from '@/store'
import { MODULE_NAME } from '../employee.module'

export function getFormattedTime(value: number, hasSign: boolean = false) {
  const formattedTime = computed(() => {
    const time = getDurationBasedOnUserSetting(
      Math.abs(value),
      store.state[MODULE_NAME].recordTimeFormat
    )

    if (value >= 0 && hasSign) return `+ ${time} h`
    return value < 0 ? `- ${time} h` : `${time} h`
  })

  return formattedTime.value
}

export function getFormattedCompensationTime(value: number) {
  const formattedTime = computed(() => {
    const time = getDurationBasedOnUserSetting(
      Math.abs(value),
      store.state[MODULE_NAME].recordTimeFormat
    )

    if (value > 0) return `- ${time} h`
    else if (value < 0) return `+ ${time} h`
    else return `${time} h`
  })

  return formattedTime.value
}

export const calculateEmployeeOvertime = (
  total_overtime_in_sec: number | undefined
) => {
  const overtimeInSec = total_overtime_in_sec ?? 0

  const overTimeInHours = overtimeInSec / 3600

  if (overTimeInHours > 0) return `+ ${overTimeInHours?.toFixed(2)} h`
  else if (overTimeInHours < 0)
    return `- ${Math.abs(overTimeInHours)?.toFixed(2)} h`
  else return `${overTimeInHours?.toFixed(2)} h`
}
