















import { defineComponent } from '@vue/composition-api'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'

export default defineComponent({
  name: 'EmployeeEditEmailForm',
  components: {
    BaseInputField,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const handleInput = (event: string) => {
      emit('input', event)
      emit('updated')
    }

    return { handleInput }
  },
})
