










import { defineComponent, computed } from '@vue/composition-api'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import i18n from '@/utils/vendors/i18n'

export default defineComponent({
  name: 'TableRowCellStatus',
  components: { BaseBadge },
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const vacationStatus = computed(() => {
      if (props.value === 'expires') {
        return {
          name: i18n.t('employee.employee_details.vacations.status.expires'),
          type: 'is-warning',
        }
      }
      if (props.value === 'used') {
        return {
          name: i18n.t('employee.employee_details.vacations.status.used'),
          type: 'is-info',
        }
      }
      if (props.value === `doesn't expire`) {
        return {
          name: i18n.t(
            'employee.employee_details.vacations.status.doesnt_expire'
          ),
          type: 'is-success',
        }
      }
      return {
        name: i18n.t('employee.employee_details.vacations.status.expired'),
        type: 'is-danger',
      }
    })

    return {
      vacationStatus,
    }
  },
})
