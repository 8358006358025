<template>
  <base-modal has-modal-card>
    <base-card-modal-form
      :title="title"
      :button-submit-text="submitButtonText"
      :action-status="actionStatus"
      :loading="actionStatus.isLoading"
      @submit="$emit('submit')"
      @close="$emit('close')"
    >
      <slot />

      <template v-slot:footer>
        <base-card-modal-footer
          class="h-24"
          data-id-prefix="employee.invite_modal"
          :button-submit-text="submitButtonText"
          :loading="actionStatus.isLoading"
          :disabled="submitDisabled"
          @done="$emit('submit')"
        >
          <button
            type="button"
            class="rounded cursor-pointer text-coal-550 hover:text-coal-750"
            @click="$emit('close')"
          >
            {{ $t('employee.employee_details.general.update_avatar_cancel') }}
          </button>
        </base-card-modal-footer>
      </template>
    </base-card-modal-form>
  </base-modal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import BaseCardModalFooter from '@/app/util-modules/ui/card-modal/BaseCardModalFooter.vue'

export default {
  name: 'EmployeeAvatarUpdateModal',
  components: {
    BaseModal,
    BaseCardModalForm,
    BaseCardModalFooter,
  },
  props: {
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    submitDisabled: {
      type: Boolean,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
  },
}
</script>
