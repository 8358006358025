<template>
  <p class="mb-4 text-lg md:text-base">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'EmployeeInviteInfo',
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    withEmail: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    text() {
      return this.withEmail
        ? this.$t('employee.invite_modal.invite_text', {
            employee_count: this.totalCount,
          })
        : this.$t('employee.invite_modal.invite_text_no_email', {
            employee_count: this.totalCount,
          })
    },
  },
}
</script>
