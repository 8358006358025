import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import {
  ContractSettingsCalcHolidayOptions,
  ContractSettingsEdit,
  ContractSettingsSaldoType,
} from '../../../models/employee.model'
import { EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS_ATTRIBUTES } from '../../../store/actions/employee.actions.names'

type SaldoTypeOptionsArray = [string, number]

export default function useData() {
  const contractSettings = ref<ContractSettingsEdit | null>(null)
  const isStartOfAccountingDisabled = ref(false)
  const isCustomHolidayHoursDisabled = computed(
    () =>
      contractSettings.value &&
      contractSettings.value.calc_holiday_options.key !== 'custom'
  )

  const fetchContractSettings = async (userId: RecordId) => {
    await store
      .dispatch(EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS_ATTRIBUTES, userId)
      .then((response) => {
        const saldo_type_options = getSaldoTypeOptions(
          response.saldo_type_options
        )
        const saldo_type = saldo_type_options.find(
          (option) => option.key === response.saldo_type
        )
        const calc_holiday_options = response.calc_holiday_options_data.find(
          (option: ContractSettingsCalcHolidayOptions) =>
            option.key === response.calc_holiday_options
        )
        isStartOfAccountingDisabled.value = response.payrolls_created

        contractSettings.value = {
          ...response,
          saldo_type,
          saldo_type_options,
          calc_holiday_options,
        }
      })
  }

  return {
    contractSettings,
    isStartOfAccountingDisabled,
    isCustomHolidayHoursDisabled,
    fetchContractSettings,
  }
}

function getSaldoTypeOptions(
  saldo_type_options: (ContractSettingsSaldoType | SaldoTypeOptionsArray)[]
): ContractSettingsSaldoType[] {
  if (saldo_type_options.every((option) => Array.isArray(option)))
    return (saldo_type_options as SaldoTypeOptionsArray[]).map(
      ([value, key]) => ({
        key,
        value,
      })
    )
  else return saldo_type_options as ContractSettingsSaldoType[]
}
