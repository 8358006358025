<template>
  <base-dropdown
    :position="$isSmallDevice ? 'is-top-left' : 'is-bottom-right'"
    :mobile-modal="false"
    aria-role="list"
    class="employee-time-tracking-select"
    multiple
  >
    <template v-slot:trigger>
      <base-input-field
        :value="selectedOption.name"
        :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
        icon-right="arrow"
        class="mb-6"
        :label="$t('employee.add_form.field_label_time_tracking_method')"
        name="single-select-method"
        readonly
      />
    </template>

    <base-dropdown-item :custom="true" class="z-10">
      <div class="flex items-center">
        <base-radio
          v-model="selectedOption"
          :native-value="timeTrackingMethods[0]"
          size="is-small"
          class="p-2 block"
          @input="$emit('input', timeTrackingMethods[0].id)"
        >
          <span class="">{{ timeTrackingMethods[0].name }}</span>
        </base-radio>
        <tracking-method-icon
          :method-name="timeTrackingMethods[0].value"
          class="-ml-2"
        />
      </div>
      <div class="flex items-center">
        <base-radio
          v-model="selectedOption"
          :native-value="timeTrackingMethods[1]"
          size="is-small"
          class="p-2 block"
          :disabled="!isBpcEnabled"
          @input="$emit('input', timeTrackingMethods[1].id)"
        >
          <span class="">{{ timeTrackingMethods[1].name }}</span>
        </base-radio>
        <tracking-method-icon
          :method-name="timeTrackingMethods[1].value"
          class="-ml-2"
        />
      </div>
      <div class="flex items-center">
        <base-radio
          v-model="selectedOption"
          :native-value="timeTrackingMethods[2]"
          size="is-small"
          class="p-2 block"
          :disabled="value.time_tracking_method !== 'via_app'"
          @input="$emit('input', timeTrackingMethods[2].id)"
        >
          <span class="">{{ timeTrackingMethods[2].name }}</span>
        </base-radio>
        <tracking-method-icon
          :method-name="timeTrackingMethods[2].value"
          class="-ml-2"
        />
      </div>
      <div class="flex items-center">
        <base-radio
          v-model="selectedOption"
          :native-value="timeTrackingMethods[3]"
          size="is-small"
          class="p-2 block"
          @input="$emit('input', timeTrackingMethods[3].id)"
        >
          <span class="">{{ timeTrackingMethods[3].name }}</span>
        </base-radio>
        <tracking-method-icon
          :method-name="timeTrackingMethods[3].value"
          class="-ml-2"
        />
      </div>
    </base-dropdown-item>
  </base-dropdown>
</template>

<script>
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import BaseDropdownItem from '@/app/util-modules/ui/dropdown/BaseDropdownItem.vue'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import { ref, defineComponent, toRefs, watch } from '@vue/composition-api'
import TrackingMethodIcon from '@/app/modules/employee/components/EmployeeTimeTrackingMethodIcon.vue'
import BaseRadio from '@/app/util-modules/ui/radio/BaseRadio.vue'
import i18n from '@/utils/vendors/i18n'

export default defineComponent({
  name: 'EmployeeSingleSelectMethod',
  components: {
    BaseRadio,
    TrackingMethodIcon,
    BaseDropdownItem,
    BaseDropdown,
    BaseInputField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    isBpcEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { value, actionStatus } = toRefs(props)
    let selectedOption = ref({})
    const timeTrackingMethods = [
      {
        name: i18n.t(
          'employee.method_of_time_tracking.without_action.via_shiftplan'
        ),
        value: 'via_roster',
        id: '2',
      },
      {
        name: i18n.t(
          'employee.method_of_time_tracking.without_action.via_browser_punch_clock'
        ),
        value: 'via_browser_time_clock',
        id: '3',
      },
      {
        name: i18n.t(
          'employee.method_of_time_tracking.without_action.via_station_app'
        ),
        value: 'via_app',
        id: '4',
      },
      {
        name: i18n.t(
          'employee.method_of_time_tracking.without_action.no_tracking'
        ),
        value: 'no_tracking',
        id: '7',
      },
    ]

    const setSelectedMethod = (method) => {
      selectedOption.value = method
      emit('input', method.id)
    }

    watch(
      () => actionStatus.value.isOk || false,
      (isRequestSuccessfull) => {
        if (isRequestSuccessfull) {
          //Check if no time tracking is selected
          if (value.value.time_tracking_method === 'no_time_tracking') {
            setSelectedMethod(timeTrackingMethods[3])
            return
          }

          //check for other time tracking options
          const ttm = timeTrackingMethods.find((m) => {
            return m.value === value.value.time_tracking_method
          })

          if (!ttm) return
          setSelectedMethod(ttm)
        }
      }
    )

    return {
      timeTrackingMethods,
      selectedOption,
      setSelectedMethod,
    }
  },
})
</script>

<style scoped>
.employee-time-tracking-select {
  @apply block;
}
.employee-time-tracking-select.dropdown >>> .dropdown-menu {
  @apply w-full bottom-12;
}

.employee-time-tracking-select >>> .control svg {
  @apply transform rotate-90 !important;
}

.employee-time-tracking-select.is-active >>> .control svg {
  @apply transform -rotate-90 !important;
}
</style>
