import { MODULE_NAME } from '../employee.module'
import EmployeeTopbarTabs from '../components/EmployeeTopbarTabs'
import { checkUserRecordPermission } from './employee.interceptors'

const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout'
  )

export default [
  {
    path: '/workspaces/:workspaceId/employees',
    name: `${MODULE_NAME}.index`,
    meta: {
      feature: MODULE_NAME,
      layout: DefaultLayout,
    },
    components: {
      'topbar-left': EmployeeTopbarTabs,
      default: () => import('../pages/EmployeePage'),
    },
  },
  {
    path: '/workspaces/:workspaceId/employees/:userId/details',
    components: {
      'topbar-left': EmployeeTopbarTabs,
      default: () => import('../pages/EmployeeDetailsPage'),
    },
    children: [
      {
        path: '',
        name: `${MODULE_NAME}.details`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () => import('../pages/EmployeeDetailsGeneralPage'),
      },
      {
        path: 'target-hours',
        name: `${MODULE_NAME}.details.targetHours`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () => import('../pages/EmployeeDetailsTargetHoursPage'),
      },
      {
        path: 'records',
        name: `${MODULE_NAME}.details.records`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () =>
          import('@/app/modules/employee/pages/EmployeeDetailsRecordsPage.vue'),
        beforeEnter: checkUserRecordPermission(MODULE_NAME),
      },
      {
        path: 'vacations',
        name: `${MODULE_NAME}.details.vacations`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () =>
          import(
            '@/app/modules/employee/pages/EmployeeDetailsVacationsPage.vue'
          ),
      },
    ],
  },
]
