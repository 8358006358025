var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$isSmallDevice)?_c('portal',{attrs:{"to":"modal"}},[_c('base-modal',{attrs:{"has-modal-card":""}},[_c('base-card-modal-form',{attrs:{"title":_vm.$t('employee.employee_details.vacations.add.title'),"button-submit-text":_vm.$t('employee.employee_details.vacations.add.submit_button'),"action-status":_vm.$actions.createVacation,"loading":_vm.$actions.createVacation.isLoading,"data-id-prefix":"employee.employee_details.vacations.add"},on:{"submit":function($event){return _vm.handleCreate()},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleInputChange = ref.handleInputChange;
return [_c('vacations-form',{attrs:{"value":_vm.payload,"hide-hint":true,"disabled-fields":_vm.disabledFields},on:{"input":function($event){return _vm.handleInput($event)},"updated":function($event){return handleInputChange(true)}}})]}}],null,false,697358928)})],1)],1):_c('portal',{attrs:{"to":"bottom-sheet"}},[_c('bottom-sheet-form-wrapper',{attrs:{"header-right-actions":[
      {
        title: _vm.$t('employee.employee_details.vacations.add.submit_button'),
        event: 'submit',
        loading: _vm.$actions.createVacation.isLoading,
        class: 'justify-self-end text-green',
      } ],"header-title":_vm.$t('employee.employee_details.vacations.add.title'),"action-status":_vm.$actions.createVacation,"validation-messages-class":"mx-4 mt-4 mb-2"},on:{"close":function($event){return _vm.$emit('close')},"done":function($event){return _vm.handleCreate()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleInputChange = ref.handleInputChange;
return [_c('vacations-form',{attrs:{"value":_vm.payload,"hide-hint":true,"disabled-fields":_vm.disabledFields},on:{"input":function($event){return _vm.handleInput($event)},"updated":function($event){return handleInputChange(true)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }