import { ComputedRef } from '@vue/composition-api'
import store from '@/store'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { EMPLOYEE_ACTION_GET_INVITE_LINK } from '../../../store/actions/employee.actions.names'
import i18n from '@/utils/vendors/i18n/index'

export default function useCopyLink({
  userCheckin,
  welcomeMessage,
}: {
  userCheckin: ComputedRef<boolean>
  welcomeMessage: ComputedRef<string | null>
}) {
  const copyInvitationLink = async () => {
    const { message } = await store.dispatch(EMPLOYEE_ACTION_GET_INVITE_LINK, {
      checkin: userCheckin.value,
      welcomeMessage: welcomeMessage?.value,
    })

    navigator.clipboard.writeText(message).then(
      () => {
        Snackbar.open(i18n.t('employee.invite_modal.copied_message'))
      },
      () => {
        Snackbar.open({
          type: 'is-danger',
          message: i18n.t('employee.invite_modal.copied_message_error'),
        })
      }
    )
  }

  return { copyInvitationLink }
}
