


























































import { computed, defineComponent, ref } from '@vue/composition-api'
import store from '@/store'
import BaseTable from '@/app/util-modules/ui/table/BaseTable.vue'
import EmployeeLastUpdateText from '../components/EmployeeLastUpdateText.vue'
import EmployeeRecordsDateRangePicker from '../components/employee-records/EmployeeRecordsDateRangePicker.vue'
import EmployeeRecordsContractSettings from '../components/employee-records/EmployeeRecordsContractSettings.vue'
import EmployeeRecordsEdit from '../components/employee-records/EmployeeRecordsEdit.vue'
import EmployeeRecordsSwitchTime from '../components/employee-records/EmployeeRecordsSwitchTime.vue'
import useTableScroll from '@/utils/composables/use-table-scroll'
import useUserId from './composables/use-user-id'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import useRecordsTableColumns from './composables/use-records-table-column'
import {
  EMPLOYEE_ACTION_LIST_RECORDS,
  EMPLOYEE_ACTION_LIST_APPEND_RECORDS,
} from '../store/actions/employee.actions.names'
import {
  EMPLOYEE_RECORDS_MUTATION_SET_DATE_RANGE,
  EMPLOYEE_RECORDS_MUTATION_SET_SORTING_FIELD,
  EMPLOYEE_RECORDS_MUTATION_SWITCH_TIME_FORMAT,
} from '../store/mutations/employee.mutations.names'
import { RECORDS_TYPE } from '../models/employee.model'
import { MODULE_NAME } from '../employee.module'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
} from '@/app/core/user/store/getters/user.getters.names'

export default defineComponent({
  name: 'EmployeeDetailsRecordsPage',
  components: {
    BaseTable,
    LastUpdate: EmployeeLastUpdateText,
    RecordsDatePicker: EmployeeRecordsDateRangePicker,
    RecordsContractSettings: EmployeeRecordsContractSettings,
    RecordsEdit: EmployeeRecordsEdit,
    SwitchTime: EmployeeRecordsSwitchTime,
  },

  trackedActions: {
    appendRecords: EMPLOYEE_ACTION_LIST_APPEND_RECORDS,
    listRecords: EMPLOYEE_ACTION_LIST_RECORDS,
  },

  setup(_props, { refs }) {
    const ROW_HEIGHT = 64
    const ROW_NUMB = 18
    const records = computed(
      () => store.state[MODULE_NAME].records[RECORDS_TYPE]
    )
    const filters = computed(
      () => store.state[MODULE_NAME].recordsFilterDateRange
    )
    const firstRecordDate = computed<Date>(
      () => filters.value && new Date(records.value[0]?.day)
    )
    const endOfRangeDate = computed<Date>(
      () => filters.value && new Date(filters.value[1])
    )
    const startOfRangeDate = computed<Date>(
      () => filters.value && new Date(filters.value[0])
    )
    // TODO: remove this once backend return empty list
    const recordsList = computed(() => {
      if (firstRecordDate.value > endOfRangeDate.value) return []
      return records.value
    })

    const recordsMeta = computed(() => {
      const {
        last_records_settings_updated_at,
        last_records_settings_updated_by,
        last_record_updated_by,
        last_record_updated_at,
      } = store.state[MODULE_NAME].recordsMeta

      return {
        lastUpdatedAtSettings: last_records_settings_updated_at,
        lastUpdatedBySettings: last_records_settings_updated_by,

        lastUpdatedAt: last_record_updated_at,
        lastUpdatedBy: last_record_updated_by,
      }
    })

    const { userId } = useUserId()
    const $actions = useActionTracker({
      appendRecords: EMPLOYEE_ACTION_LIST_APPEND_RECORDS,
      listRecords: EMPLOYEE_ACTION_LIST_RECORDS,
    })

    const popups = ref<Record<string, null>>({
      contractSettingsEdit: null,
      recordEdit: null,
    })

    const togglePopup = (type: string, value: any) => {
      popups.value[type] = value
    }

    const handleSorting = (field: string, direction: number) => {
      store.commit(EMPLOYEE_RECORDS_MUTATION_SET_SORTING_FIELD, {
        [field]: direction,
      })
      refechRecords()
    }

    /* eslint-disable-next-line no-undef */
    const handleRecordEdit = (id: RecordId | null) => {
      togglePopup('recordEdit', id)
    }

    const { columns, onAction } = useRecordsTableColumns()
    const { handleScroll, prevScrollOffset } = useTableScroll(
      refs,
      ROW_HEIGHT,
      ROW_NUMB,
      fetchMoreRecords,
      true
    )

    onAction((action: string, params: any) => {
      switch (action) {
        case 'sort':
          handleSorting(params.field, params.direction)
          break
        case 'edit':
          handleRecordEdit(params.id ?? null)
          break
      }
    })

    function fetchMoreRecords() {
      if ($actions.appendRecords.isLoading || $actions.listRecords.isLoading)
        return

      store.dispatch(EMPLOYEE_ACTION_LIST_APPEND_RECORDS, userId.value)
    }
    async function refechRecords() {
      window.scrollTo(0, 0)
      prevScrollOffset.value = 0

      await store.dispatch(EMPLOYEE_ACTION_LIST_RECORDS, userId.value)

      if (
        startOfRangeDate.value < firstRecordDate.value &&
        recordsList.value.length > 0
      )
        setStartOfRangeFilter(new Date(firstRecordDate.value))
    }

    const hideRecordsForEmployees = computed(
      () =>
        store.getters[USER_GETTER_FEATURE_PERMISSIONS]('General')
          .hide_records_for_employees &&
        !store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )

    const switchTimeFormat = () =>
      store.commit(EMPLOYEE_RECORDS_MUTATION_SWITCH_TIME_FORMAT)

    function setStartOfRangeFilter(startDate: Date) {
      store.commit(EMPLOYEE_RECORDS_MUTATION_SET_DATE_RANGE, [
        startDate,
        filters.value[1],
      ])
    }

    refechRecords()

    return {
      ROW_NUMB,
      ROW_HEIGHT,
      recordsMeta,
      columns,
      records,
      recordsList,
      hideRecordsForEmployees,
      userId,
      popups,

      togglePopup,
      handleScroll,
      handleRecordEdit,
      refechRecords,
      switchTimeFormat,
    }
  },
})
