<template>
  <p
    class="text-sm text-coal-550"
    :class="{ truncate: truncated }"
    :title="truncated ? text : null"
  >
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'EmployeeTargetHoursHelpText',
  props: {
    text: {
      type: String,
      required: true,
    },
    truncated: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
