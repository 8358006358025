





















































import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import store from '@/store'
import { MODULE_NAME } from '../../employee.module'

import BaseFilterbar from '@/app/util-modules/ui/filterbar/BaseFilterbar.vue'
import TableColumnsCustomizeDropdown from '@/app/util-modules/ui/table-columns-customize-dropdown/TableColumnsCustomizeDropdown.vue'
import HeaderActions from './EmployeeHeaderActions.vue'
import TabsHeader from './EmployeeTabsHeader.vue'
import useDeviceSize from '@/utils/composables/use-ui-size'
import i18n from '@/utils/vendors/i18n/index'
import {
  EMPLOYEE_MUTATION_ADD_FILTER,
  EMPLOYEE_MUTATION_REMOVE_FILTER,
} from '../../store/mutations/employee.mutations.names'
import {
  EMPLOYEE_ACTION_SELECT_COLUMNS_TO_SHOW,
  EMPLOYEE_ACTION_RESET_DEFAULT_COLUMNS_TO_SHOW,
} from '../../store/actions/employee.actions.names'
import {
  EMPLOYEE_GETTER_ALL_COLUMNS_SORTED,
  EMPLOYEE_GETTER_LIMITED_COLUMNS,
} from '../../store/getters/employee.getters.names'
import type { Role } from '@/app/core/user/models/role.model'
import { Filter } from '../../models/employee.model'
import { MAX_COLUMNS_LENGTH } from '../../constants/index'

export default defineComponent({
  name: 'EmployeeHeader',
  components: {
    BaseFilterbar,
    TableColumnsCustomizeDropdown,
    HeaderActions,
    TabsHeader,
  },
  props: {
    canCreateEmployee: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Array as PropType<Role[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { $isSmallDevice } = useDeviceSize()

    const sortedTableColumns = computed(
      () => store.getters[EMPLOYEE_GETTER_ALL_COLUMNS_SORTED]
    )
    const isMobileFilterbarShown = ref(false)
    const selectedColumns = computed(
      () => store.getters[EMPLOYEE_GETTER_LIMITED_COLUMNS].value
    )
    const selectedFilters = computed(() => store.state[MODULE_NAME].filters)
    const selectedFilterNames = computed(() =>
      Object.keys(store.state[MODULE_NAME].filters)
    )
    const filterbarShown = computed(() => {
      if (!$isSmallDevice.value) {
        return true
      }
      return isMobileFilterbarShown.value || selectedFilterNames.value.length
    })

    const addFilter = (filter: Filter) =>
      store.commit(EMPLOYEE_MUTATION_ADD_FILTER, filter)
    const removeFilter = (filter: Filter) =>
      store.commit(EMPLOYEE_MUTATION_REMOVE_FILTER, filter)

    const handleInput = ({
      selected,
      column,
    }: {
      selected: boolean
      column: { unique_key: string }
    }) => {
      store.dispatch(EMPLOYEE_ACTION_SELECT_COLUMNS_TO_SHOW, {
        selected,
        column: column.unique_key,
      })
    }

    const resetDefault = () => {
      store.dispatch(EMPLOYEE_ACTION_RESET_DEFAULT_COLUMNS_TO_SHOW)
    }

    const filters = computed(() => {
      return [
        {
          field: 'username',
          label: () => i18n.t('employee.filterbar.name.label'),
          type: 'string',
          params: {
            valueInputProps: {
              placeholder: () => i18n.t('employee.filterbar.name.placeholder'),
              label: () => i18n.t('employee.filterbar.name.label'),
            },
          },
        },
        {
          field: 'email',
          label: () => i18n.t('employee.filterbar.email.label'),
          type: 'string',
          params: {
            valueInputProps: {
              placeholder: () => i18n.t('employee.filterbar.email.placeholder'),
              label: () => i18n.t('employee.filterbar.email.label'),
            },
          },
        },
        {
          field: 'company_user_relations_roles_id',
          label: () => i18n.t('employee.filterbar.role'),
          type: 'select',
          params: {
            options: props.roles.map((r) => ({
              label: r.localized_name,
              value: r.id,
            })),
          },
        },
      ]
    })

    return {
      sortedTableColumns,
      MODULE_NAME,
      MAX_COLUMNS_LENGTH,
      isMobileFilterbarShown,
      selectedColumns,
      selectedFilters,
      selectedFilterNames,
      filterbarShown,
      filters,

      addFilter,
      removeFilter,
      handleInput,
      resetDefault,
    }
  },
})
