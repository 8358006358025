<template>
  <div ref="actionsContent" class="h-full flex items-center justify-end">
    <base-group-selection-toolbar-action-item
      data-id="employee.employee_details.edit_target_hour"
      :tooltip="$t('employee.employee_details.target_hours.edit_target_hour')"
      tooltip-position="is-left"
    >
      <base-group-selection-toolbar-action-button
        v-if="canUpdate"
        type="is-filled-grey"
        class="my-1.5 hover:text-coal-850"
        icon="settings"
        role="button"
        :aria-label="
          $t('employee.employee_details.target_hours.edit_target_hour')
        "
        @click="$emit('edit-target-hour-action-click')"
      />
    </base-group-selection-toolbar-action-item>
  </div>
</template>

<script>
import BaseGroupSelectionToolbarActionItem from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionItem'
import BaseGroupSelectionToolbarActionButton from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionButton'

export default {
  name: 'TableRowCellActions',
  components: {
    BaseGroupSelectionToolbarActionItem,
    BaseGroupSelectionToolbarActionButton,
  },
  props: {
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
