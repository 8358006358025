import store from '@/store'
import { ref, WritableComputedRef } from '@vue/composition-api'
import { BaseTargetHours } from '../../models/employee.model'
import { EMPLOYEE_ACTION_LIST_ABSENCES } from '../../store/actions/employee.actions.names'

type Callback = (options?: {
  reset_confirmed_absences: boolean
}) => Promise<void>

export default function useAbsenceConfirmation(
  basePayload: WritableComputedRef<Partial<BaseTargetHours>>,
  employeeId: RecordId,
  callback: Callback
) {
  const isAbsenceDialogOpen = ref(false)

  async function checkAbsences() {
    const { meta } = await store.dispatch(EMPLOYEE_ACTION_LIST_ABSENCES, {
      userId: employeeId,
      startsAt: basePayload.value.start_date,
      endsAt: basePayload.value.end_date,
    })

    if (meta.total_count) {
      isAbsenceDialogOpen.value = meta.total_count > 0
    } else {
      callback().then(() => {
        isAbsenceDialogOpen.value = false
      })
    }
  }

  return {
    checkAbsences,
    isAbsenceDialogOpen,
  }
}
