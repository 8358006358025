

















































import { defineComponent, ref } from '@vue/composition-api'
import store from '@/store'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import EditEmailForm from './EmployeeEditEmailForm.vue'
import { EMPLOYEE_ACTION_UPDATE_EMPLOYEE_EMAIL } from '../../store/actions/employee.actions.names'

export default defineComponent({
  name: 'EmployeeEditEmailModal',
  components: {
    BottomSheet,
    BaseModal,
    BaseCardModalForm,
    EditEmailForm,
  },
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
  },
  trackedActions: {
    updateEmail: EMPLOYEE_ACTION_UPDATE_EMPLOYEE_EMAIL,
  },
  setup(props, { emit }) {
    const email = ref(props.employee.email)

    const updateEmployeeEmail = async () => {
      await store.dispatch(EMPLOYEE_ACTION_UPDATE_EMPLOYEE_EMAIL, {
        employeeId: props.employee.id,
        email: email.value,
      })

      emit('updated')
      emit('close')
    }

    return { email, updateEmployeeEmail }
  },
})
