



















































import { defineComponent } from '@vue/composition-api'
import store from '@/store'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm.vue'
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper.vue'
import EditForm from './EmployeeContractSettingsEditForm.vue'
import { EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS } from '../../store/actions/employee.actions.names'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import useData from './composables/use-data'

export default defineComponent({
  name: 'EmployeeContractSettingsEdit',
  components: {
    BaseModal,
    BaseCardModalForm,
    BottomSheetFormWrapper,
    EditForm,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  trackedActions: {
    updateContractSettings: 'EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS',
  },
  setup(props, { emit }) {
    const {
      contractSettings,
      fetchContractSettings,
      isStartOfAccountingDisabled,
      isCustomHolidayHoursDisabled,
    } = useData()

    const handleSubmit = async () => {
      try {
        await store.dispatch(EMPLOYEE_ACTION_UPDATE_CONTRACT_SETTINGS, {
          userId: props.userId,
          contractSettings: contractSettings.value,
        })
        Snackbar.open(
          i18n.t(
            'employee.target_hours.contract_settings.edit_data_success_message'
          )
        )
        emit('close')
        emit('success')
      } catch (error) {
        Snackbar.open({
          message: i18n.t(
            'employee.target_hours.contract_settings.edit_data_error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    fetchContractSettings(props.userId)

    return {
      contractSettings,
      isStartOfAccountingDisabled,
      isCustomHolidayHoursDisabled,
      handleSubmit,
    }
  },
})
