












import { defineComponent, computed } from '@vue/composition-api'
import store from '@/store'
import { getShortcuts } from '@/utils/date-time.utils'
import { MODULE_NAME as EMPLOYEE_MODULE_NAME } from '../../employee.module'
import BaseDateRangePicker from '@/app/util-modules/ui/date-range-picker/BaseDateRangePicker.vue'
import { EMPLOYEE_RECORDS_MUTATION_SET_DATE_RANGE } from '../../store/mutations/employee.mutations.names'

export default defineComponent({
  name: 'EmployeeRecordsDateRangePicker',
  components: {
    BaseDateRangePicker,
  },
  setup(_props, { emit }) {
    const dateRange = computed(
      () => store.state[EMPLOYEE_MODULE_NAME].recordsFilterDateRange
    )

    const updateDateRange = (dateRange: Date[]) => {
      store.commit(EMPLOYEE_RECORDS_MUTATION_SET_DATE_RANGE, dateRange)
      emit('updated')
    }

    const startDateKey = computed(() => {
      return dateRange.value ? dateRange.value.toString() : ''
    })

    return {
      dateRange,
      startDateKey,
      getShortcuts,
      updateDateRange,
    }
  },
})
