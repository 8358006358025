<template>
  <base-modal has-modal-card @close="$emit('close')">
    <base-card-modal
      :title="$t('employee.index.header.more_actions.upgrade_modal_title')"
      @close="$emit('close')"
    >
      <p class="mb-4">
        {{ $t('employee.index.header.more_actions.upgrade_modal_text') }}
      </p>
      <a :href="BASE_WEB_URL" target="_blank">
        {{ $t('employee.index.header.more_actions.upgrade_modal_link') }}
        <base-icon icon="link-hyperlink/link-hyperlink-16" />
      </a>
      <div slot="footer" />
    </base-card-modal>
  </base-modal>
</template>

<script>
import { BASE_WEB_URL } from '@/constants'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModal from '@/app/util-modules/ui/card-modal/BaseCardModal.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'EmployeeHeaderActionsUpgradeDialog',
  components: {
    BaseModal,
    BaseCardModal,
    BaseIcon,
  },
  data() {
    return { BASE_WEB_URL }
  },
}
</script>
