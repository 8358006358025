










import { defineComponent, ref, PropType } from '@vue/composition-api'
import store from '@/store'
import EmployeeVacationsViewSwitchBottomSheet from './EmployeeVacationsViewSwitchBottomSheet.vue'
import EmployeeVacationsViewSwitchDropdown from './EmployeeVacationsViewSwitchDropdown.vue'
import { EMPLOYEE_ACTION_UPDATE_HOUR_HOLIDAYS } from '../../store/actions/employee.actions.names'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import i18n from '@/utils/vendors/i18n'

export default defineComponent({
  name: 'EmployeeVacationsViewSwitch',
  components: {
    SwitchBottomSheet: EmployeeVacationsViewSwitchBottomSheet,
    SwitchDropdown: EmployeeVacationsViewSwitchDropdown,
  },

  props: {
    employeeId: {
      // eslint-disable-next-line no-undef
      type: String as PropType<RecordId>,
      required: true,
    },
    isEmployeeHolidayInHours: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    let isHourHolidays = ref(props.isEmployeeHolidayInHours)

    const updateVacationsView = async () => {
      try {
        await store.dispatch(EMPLOYEE_ACTION_UPDATE_HOUR_HOLIDAYS, {
          employeeId: props.employeeId,
          hour_holidays: isHourHolidays.value,
        })

        emit('update-employee-info')

        Snackbar.open(
          i18n.t('employee.employee_details.vacations.views.success_message')
        )
      } catch (err) {
        Snackbar.open({
          message: i18n.t(
            'employee.employee_details.vacations.views.error_message'
          ),
          type: 'is-danger',
        })
      }
    }

    const toggleViewSwitch = (data: { value: boolean; type?: string }) => {
      isHourHolidays.value = data.value

      if (data.type === 'dropdown') {
        updateVacationsView()
      }
    }

    const resetVacationsView = () => {
      toggleViewSwitch({
        value: false, // The default value is days(false)
      })
      updateVacationsView()
    }

    return {
      isHourHolidays,

      resetVacationsView,
      toggleViewSwitch,
      updateVacationsView,
    }
  },
})
