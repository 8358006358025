<template>
  <p
    class="flex gap-x-2 items-start mb-2 col-start-1 col-end-3 md:col-start-2 md:flex-row md:mb-0"
  >
    <span class="font-bold text-sm"> {{ title }} </span>

    <slot />
  </p>
</template>

<script>
export default {
  name: 'EmployeeInfoLists',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
