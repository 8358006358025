export const EMPLOYEE_ENDPOINT_CREATE_EMPLOYEE = (workspaceId) =>
  `/workspaces/${workspaceId}/users`
export const EMPLOYEE_ENDPOINT_LIST_EMPLOYEES = (workspaceId) =>
  `/workspaces/${workspaceId}/users`
export const EMPLOYEE_ENDPOINT_UPDATE_EMPLOYEE = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}`
export const EMPLOYEE_ENDPOINT_SHOW_EMPLOYEE = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}`
export const EMPLOYEE_ENDPOINT_DELETE_EMPLOYEE = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}`
export const EMPLOYEE_ENDPOINT_LIST_UNREGISTERED = (workspaceId) =>
  `/workspaces/${workspaceId}/users`
export const EMPLOYEE_ENDPOINT_BATCH_INVITE = (workspaceId) =>
  `/workspaces/${workspaceId}/users/batch_invitations`
export const EMPLOYEE_ENDPOINT_GET_INVITE_LINK = (workspaceId) =>
  `/workspaces/${workspaceId}/invitations`
export const EMPLOYEE_ENDPOINT_TRACKING_METHOD_LIST = (workspaceId) =>
  `/workspaces/${workspaceId}/time_tracking_methods`

export const EMPLOYEE_ENDPOINT_TARGET_HOURS_CREATE = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/target_hours`
export const EMPLOYEE_ENDPOINT_TARGET_HOURS_LIST = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/target_hours`
export const EMPLOYEE_ENDPOINT_ABSENCES_LIST = (workspaceId) =>
  `/workspaces/${workspaceId}/absences`
export const EMPLOYEE_ENDPOINT_TARGET_HOURS_SHOW = (
  workspaceId,
  userId,
  targetHourId
) => `/workspaces/${workspaceId}/users/${userId}/target_hours/${targetHourId}`
export const EMPLOYEE_ENDPOINT_TARGET_HOURS_UPDATE = (
  workspaceId,
  userId,
  targetHourId
) => `/workspaces/${workspaceId}/users/${userId}/target_hours/${targetHourId}`
export const EMPLOYEE_ENDPOINT_TARGET_HOURS_DELETE = (
  workspaceId,
  userId,
  targetHoursId
) => `/workspaces/${workspaceId}/users/${userId}/target_hours/${targetHoursId}`
export const EMPLOYEE_ENDPOINT_UPDATE_STATUS = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/user_status`
export const EMPLOYEE_ENDPOINT_GET_DATA_PROFILE_LIST = (
  accountId,
  workspaceId
) => `/accounts/${accountId}/workspaces/${workspaceId}/data_profiles`
export const EMPLOYEE_ENDPOINT_GET_DATA_PROFILE = (
  workspaceId,
  userId,
  dataProfileId
) => `/workspaces/${workspaceId}/users/${userId}/data_profiles/${dataProfileId}`
export const EMPLOYEE_ENDPOINT_UPDATE_DATA_PROFILE = (
  workspaceId,
  userId,
  dataProfileId
) => `/workspaces/${workspaceId}/users/${userId}/data_profiles/${dataProfileId}`
export const EMPLOYEE_ENDPOINT_GET_CONTRACT_SETTINGS = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/contract_settings`
export const EMPLOYEE_ENDPOINT_GET_CONTRACT_SETTINGS_ATTRIBUTES = (
  workspaceId,
  userId
) => `/workspaces/${workspaceId}/users/${userId}/contract_settings/attributes`
export const EMPLOYEE_ENDPOINT_UPDATE_CONTRACT_SETTINGS = (
  workspaceId,
  userId
) => `/workspaces/${workspaceId}/users/${userId}/contract_settings`
export const EMPLOYEE_ENDPOINT_GET_RECORDS_SUMMARY = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/records/summary`
export const EMPLOYEE_ENDPOINT_GET_VACATIONS_SUMMARY = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/vacations/summary`
export const EMPLOYEE_ENDPOINT_GET_RECORDS = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/evaluations`
export const EMPLOYEE_ENDPOINT_GET_RECORD = (workspaceId, userId, recordId) =>
  `/workspaces/${workspaceId}/users/${userId}/evaluations/${recordId}`
export const EMPLOYEE_ENDPOINT_UPDATE_RECORD = (
  workspaceId,
  userId,
  recordId
) => `/workspaces/${workspaceId}/users/${userId}/evaluations/${recordId}`
export const EMPLOYEE_ENDPOINT_CREATE_VACATION = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/vacation_times`
export const EMPLOYEE_ENDPOINT_LIST_VACATIONS = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/vacation_times`
export const EMPLOYEE_ENDPOINT_GET_VACATION = (
  workspaceId,
  userId,
  vacationId
) => `/workspaces/${workspaceId}/users/${userId}/vacation_times/${vacationId}`
export const EMPLOYEE_ENDPOINT_UPDATE_VACATION = (
  workspaceId,
  userId,
  vacationId
) => `/workspaces/${workspaceId}/users/${userId}/vacation_times/${vacationId}`
export const EMPLOYEE_ENDPOINT_DELETE_VACATION = (
  workspaceId,
  userId,
  vacationId
) => `/workspaces/${workspaceId}/users/${userId}/vacation_times/${vacationId}`
export const EMPLOYEE_ENDPOINT_TRANSFER_VACATION = (
  workspaceId,
  userId,
  vacationId
) =>
  `/workspaces/${workspaceId}/users/${userId}/vacation_times/${vacationId}/transfer`
export const EMPLOYEE_ENDPOINT_GET_VACATION_HISTORY = (
  workspaceId,
  userId,
  vacationId
) =>
  `/workspaces/${workspaceId}/users/${userId}/vacation_times/${vacationId}/history`
