

























































import { defineComponent, PropType } from '@vue/composition-api'
import { formatDate, formatTime } from '@/utils/date-time.utils'
import { EmployeeVacationsHistory } from '@/app/modules/employee/models/employee.model'

const DATE_FORMAT = 'EEEEEE, dd.MM.yyyy'

export default defineComponent({
  name: 'EmployeeVacationsHistoryList',
  props: {
    historyData: {
      type: Array as PropType<EmployeeVacationsHistory[]>,
      required: true,
    },
    employeeHourHolidays: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const formatConfirmedByTime = (dateTime: string) => {
      return `${formatDate(
        new Date(dateTime),
        DATE_FORMAT,
        true
      )} | ${formatTime(new Date(dateTime))}`
    }

    const formatCalculationTime = (dateTime: string, spentDays: number) => {
      return `${formatDate(
        new Date(dateTime),
        DATE_FORMAT,
        true
      )} | ${spentDays.toFixed(1)} ${props.employeeHourHolidays ? 'h' : 'd'}`
    }

    return {
      formatConfirmedByTime,
      formatCalculationTime,
    }
  },
})
