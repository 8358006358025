import { getNextYearDate } from '@/utils/date-time.utils'
import i18n from '@/utils/vendors/i18n'
import { Role } from '@/app/core/user/models/role.model'

export function getBadgeTypeByRole(roleName: Role['localized_name']) {
  if (roleName === 'employee') return 'is-danger'
  else if (roleName === 'account_admin') return 'is-info'
  else if (roleName === 'workspace_admin') return 'is-success'
  else if (roleName === 'working_area_admin') return 'is-warning'
  else return 'is-default'
}

export function getIconByMethodName(name: string) {
  switch (name) {
    case 'via_time_tracking':
      return 'browser/browser-16'
    case 'via_roster':
      return 'calendar/calendar-16'
    case 'via_browser_time_clock':
      return 'time-tracking/time-tracking-16'
    case 'via_app':
      return 'phone-mobile/phone-mobile-16'
    case 'via_terminal_app':
      return 'actions/actions-16'
    case 'no_tracking':
      return 'no-tracking/no-tracking-16'
    default:
      return 'calendar/calendar-16'
  }
}

export function getWeekdays() {
  return {
    mon: i18n.t('weekdays.mon'),
    tue: i18n.t('weekdays.tue'),
    wed: i18n.t('weekdays.wed'),
    thu: i18n.t('weekdays.thu'),
    fri: i18n.t('weekdays.fri'),
    sat: i18n.t('weekdays.sat'),
    sun: i18n.t('weekdays.sun'),
  }
}

export function getEndOfTransferDate() {
  const currentYear = new Date().getFullYear()
  const date = new Date(currentYear, 2, 31)
  return getNextYearDate(date)
}
// typescript friendly Object.prototype.hasOwnProperty() util
// https://fettblog.eu/typescript-hasownproperty/
// eslint-disable-next-line @typescript-eslint/ban-types
export function objectHasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export const preventNonNumericChar = (e: {
  key: string
  preventDefault: () => void
}) => {
  if (['e', 'E', '+'].includes(e.key)) {
    e.preventDefault()
  }
}
