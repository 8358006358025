























































import {
  defineComponent,
  ref,
  computed,
  watch,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import { USER_GETTER_FEATURE_PERMISSIONS } from '@/app/core/user/store/getters/user.getters.names'
import {
  EMPLOYEE_ACTION_LIST_EMPLOYEES,
  EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES,
} from '../store/actions/employee.actions.names'
import { USER_PUBLIC_ACTION_LIST_PREFERENCES } from '@/app/core/user/store/actions/user.actions.names'
import { EMPLOYEE_MUTATION_SET_SORTING_FIELD } from '../store/mutations/employee.mutations.names.js'
import { EMPLOYEE_GETTER_ACTIVE_FILTERS } from '../store/getters/employee.getters.names'
import { MODULE_NAME } from '../employee.module'
import { EMPLOYEE_TYPE, Employee } from '../models/employee.model'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import emitter from '@/app/util-modules/emitter'
import BaseTable from '@/app/util-modules/ui/table/BaseTable.vue'
import EmployeeAdd from '../components/EmployeeAdd.vue'
import EmployeeEdit from '../components/EmployeeEdit.vue'
import EmployeeInvite from '../components/employee-invite/EmployeeInvite.vue'
import RowBottomSheet from '../components/employee-list/EmployeeListRowBottomSheet.vue'
import useTableColumns from './composables/use-table-columns'
import useTableScroll from '@/utils/composables/use-table-scroll'
import useTransaction from '@/utils/composables/use-transaction'
import EmployeeHeader from '../components/employee-header/EmployeeHeader.vue'

export default defineComponent({
  name: 'EmployeePage',
  components: {
    BaseTable,
    EmployeeAdd,
    EmployeeInvite,
    EmployeeEdit,
    RowBottomSheet,
    EmployeeHeader,
  },

  setup(props, { refs }) {
    const ROW_HEIGHT = 24
    const ROW_NUMB = 16

    const $actions = useActionTracker({
      appendEmployees: EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES,
      listEmployees: EMPLOYEE_ACTION_LIST_EMPLOYEES,
    })

    const refetchEmployeesAction = () =>
      store.dispatch(EMPLOYEE_ACTION_LIST_EMPLOYEES)
    const fetchMoreEmployeesAction = () =>
      store.dispatch(EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES)
    const fetchUserPreferences = () =>
      store.dispatch(USER_PUBLIC_ACTION_LIST_PREFERENCES)
    const rolesList = computed(() => store.state.user.roles)

    const popups = ref<Record<string, boolean | number | Employee | null>>({
      add: false,
      edit: null,
      invite: false,
      employeeInfo: null,
    })
    const togglePopup = (type: string, value: any) => {
      popups.value[type] = value
    }

    const handleSorting = (field: string, direction: number) => {
      store.commit(EMPLOYEE_MUTATION_SET_SORTING_FIELD, {
        [field]: direction,
      })
      refetchEmployees()
    }

    // TODO: find out why eslint doesn't recognize typescript globals
    /* eslint-disable-next-line no-undef */
    const handleEditAction = (id: RecordId | null) => {
      togglePopup('edit', id)
    }

    const { columns, onAction } = useTableColumns()
    const { handleScroll, prevScrollOffset } = useTableScroll(
      refs,
      ROW_HEIGHT,
      ROW_NUMB,
      fetchMoreEmployees
    )

    onAction((action: string, params: any) => {
      switch (action) {
        case 'sort':
          handleSorting(params.field, params.direction)
          break
        case 'edit':
          handleEditAction(params.id ?? null)
          break
      }
    })

    const closeDeleted = () => {
      togglePopup('employeeInfo', null)
      togglePopup('edit', null)
    }

    function fetchMoreEmployees() {
      if (
        $actions.appendEmployees.isLoading ||
        $actions.listEmployees.isLoading
      )
        return
      fetchMoreEmployeesAction()
    }

    function refetchEmployees() {
      window.scrollTo(0, 0)
      prevScrollOffset.value = 0
      return refetchEmployeesAction()
    }

    const employees = computed(
      () => store.state[MODULE_NAME].records[EMPLOYEE_TYPE]
    )
    const itemsPerPage = computed(
      () => store.state[MODULE_NAME].pagination.items
    )
    const canCreateEmployee = computed(() => {
      const permissions =
        store.getters[USER_GETTER_FEATURE_PERMISSIONS](MODULE_NAME)
      return Boolean(permissions.create_workspace_users)
    })

    const refetchEmployeesWithTracker = useTransaction({
      name: 'EmployeeList',
      callback: refetchEmployees,
      op: 'refetchEmployees',
      description: 'loading first employee list',
    })

    watch(() => store.getters[EMPLOYEE_GETTER_ACTIVE_FILTERS], refetchEmployees)

    refetchEmployeesWithTracker()

    const onModuleNavigation = (moduleName: string) => {
      if (moduleName === MODULE_NAME) refetchEmployees()
    }

    emitter.on('app-sidebar.module-navigation', onModuleNavigation)
    onUnmounted(() =>
      emitter.off('app-sidebar.module-navigation', onModuleNavigation)
    )

    fetchUserPreferences()

    return {
      ROW_HEIGHT,
      $actions,
      columns,
      employees,
      itemsPerPage,
      canCreateEmployee,
      rolesList,
      popups,
      togglePopup,
      prevScrollOffset,

      closeDeleted,
      refetchEmployees,
      handleScroll,
      handleEditAction,
    }
  },
})
