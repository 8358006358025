<template>
  <base-modal has-modal-card>
    <base-card-modal-form
      v-slot="{ handleInputChange }"
      :title="title"
      :button-submit-text="submitButtonLabel"
      :action-status="actionStatus"
      :loading="actionStatus.isLoading"
      :footer-more-options="moreOptions"
      @selected="$emit('option-selected', $event)"
      @submit="$emit('submit')"
      @close="$emit('close')"
    >
      <target-hours-form
        v-bind="$attrs"
        v-on="$listeners"
        @input="handleInputChange(true)"
      />
    </base-card-modal-form>
  </base-modal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import TargetHoursForm from './EmployeeTargetHoursForm.vue'

export default {
  name: 'EmployeeTargetHoursModal',
  components: {
    BaseModal,
    BaseCardModalForm,
    TargetHoursForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonLabel: {
      type: String,
      required: true,
    },
    actionStatus: {
      type: Object,
      required: true,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
