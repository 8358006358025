


















import { defineComponent, PropType, computed } from '@vue/composition-api'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import EmployeeListTooltipList from './EmployeeListTooltipList.vue'

interface PropValue {
  title: string
  name: string
}

export default defineComponent({
  name: 'EmployeeTooltipBadge',
  components: { BaseBadge, EmployeeListTooltipList },
  props: {
    value: {
      type: Array as PropType<PropValue[]>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    tableView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const passedValue = computed(() =>
      props.value.map((item) => ({ label: item.title || item.name, ...item }))
    )

    return {
      passedValue,
    }
  },
})
