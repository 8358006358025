
























import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EmployeeVacationsFooterCell',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
  },
})
