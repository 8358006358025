



























































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import i18n from '@/utils/vendors/i18n/index'
import { Employee } from '../../models/employee.model'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import Row from '@/app/util-modules/ui/bottom-sheet/BottomSheetRow.vue'
import ListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem.vue'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import { getBadgeTypeByRole } from '../employee.utils'
import { calculateEmployeeOvertime } from '@/app/modules/employee/utils/employee.utils'

export default defineComponent({
  name: 'EmployeeListRowBottomSheet',
  components: {
    BottomSheet,
    Row,
    ListItem,
    BaseBadge,
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },

  setup(props) {
    const closeButton: Record<string, TranslateResult> = {
      title: i18n.t('employee.index.bottom_sheet.close'),
      class: 'text-coal-550',
      event: 'cancel-click',
    }

    const formatList = (
      list: Array<Record<string, any>>,
      titleKey = 'name'
    ) => {
      return list?.length ? list.map((a) => a[titleKey]).join(', ') : '--'
    }

    const employeeStatus = computed(() =>
      props.employee.status === 'active'
        ? i18n.t('employee.index.body.active')
        : i18n.t('employee.index.body.inactive')
    )

    const vacationSummary = computed(() =>
      props.employee.hour_holidays
        ? `${props.employee.remaining_vacation_days_this_year?.toFixed(2)} h`
        : `${props.employee.remaining_vacation_days_this_year?.toFixed(2)} d`
    )

    return {
      closeButton,
      employeeStatus,
      getBadgeTypeByRole,
      formatList,
      calculateEmployeeOvertime,
      vacationSummary,
    }
  },
})
