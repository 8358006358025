



















































import { defineComponent, computed } from '@vue/composition-api'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import useDeviceSize from '@/utils/composables/use-ui-size'
import i18n from '@/utils/vendors/i18n'

export default defineComponent({
  name: 'EmployeeTargetHoursAbsenceDialogFooter',
  components: {
    BaseButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideButtonCancel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { $isSmallDevice } = useDeviceSize()

    const primaryButtonText = computed(() => {
      return $isSmallDevice
        ? i18n.t(
            'employee.target_hours.form.confirm_absences.reset_button_mobile'
          )
        : i18n.t('employee.target_hours.form.confirm_absences.reset_button')
    })

    const secondaryButtonText = computed(() => {
      return $isSmallDevice
        ? i18n.t(
            'employee.target_hours.form.confirm_absences.keep_absences_button_mobile'
          )
        : i18n.t(
            'employee.target_hours.form.confirm_absences.keep_absences_button'
          )
    })

    return {
      primaryButtonText,
      secondaryButtonText,
    }
  },
})
