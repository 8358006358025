





















































import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import { MODULE_NAME } from '../employee.module'
import { TARGET_HOURS_TYPE } from '../models/employee.model'
import BaseTable from '@/app/util-modules/ui/table/BaseTable.vue'
import LastUpdate from '../components/EmployeeLastUpdateText.vue'
import ListHeader from '../components/employee-target-hours/EmployeeTargetHoursListHeader.vue'
import EmployeeTargetHoursAdd from '../components/EmployeeTargetHoursAdd.vue'
import EmployeeTargetHoursEdit from '../components/EmployeeTargetHoursEdit.vue'
import EmployeeContractSettings from '../components/employee-contract-settings/EmployeeContractSettings.vue'
import useTableDetailsColumns from './composables/use-table-details-column'
import useTableScroll from '@/utils/composables/use-table-scroll'
import useUserId from './composables/use-user-id'
import { EMPLOYEE_TARGET_HOURS_GETTER_ACTIVE_FILTERS } from '../store/getters/employee.getters.names'
import {
  EMPLOYEE_ACTION_LIST_TARGET_HOURS,
  EMPLOYEE_ACTION_LIST_APPEND_TARGET_HOURS,
} from '../store/actions/employee.actions.names'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
} from '@/app/core/user/store/getters/user.getters.names'

export default defineComponent({
  name: 'EmployeeDetailsTargetHoursPage',
  components: {
    BaseTable,
    ListHeader,
    LastUpdate,
    EmployeeTargetHoursAdd,
    EmployeeTargetHoursEdit,
    EmployeeContractSettings,
  },
  trackedActions: {
    listTargetHours: EMPLOYEE_ACTION_LIST_APPEND_TARGET_HOURS,
  },
  setup(_props, { refs }) {
    const ROW_HEIGHT = 24
    const ROW_NUMB = 16

    const { userId } = useUserId()
    const itemsPerPage = computed(
      () => store.state[MODULE_NAME].targetHoursMeta.total_count
    )
    const canCreateTargetHours = computed(
      () => store.state[MODULE_NAME].targetHoursMeta.can_create_target_hours
    )
    const hideRecordsForEmployees = computed(
      () =>
        store.getters[USER_GETTER_FEATURE_PERMISSIONS]('General')
          .hide_records_for_employees &&
        !store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )
    const targetHours = computed(
      () => store.state[MODULE_NAME].records[TARGET_HOURS_TYPE]
    )
    const popups = ref<Record<string, null>>({
      targetHoursAdd: null,
      targetHourEdit: null,
      contractSettingsEdit: null,
    })
    const togglePopup = (type: string, value: any) => {
      popups.value[type] = value
    }
    const updatedBy = computed(() => {
      const { last_updated_by, last_updated_at } =
        store.state[MODULE_NAME].targetHoursMeta

      return { last_updated_by, last_updated_at }
    })

    const refetchTargetHours = () =>
      store.dispatch(EMPLOYEE_ACTION_LIST_TARGET_HOURS, userId.value)

    const fetchMoreTargetHours = () =>
      store.dispatch(EMPLOYEE_ACTION_LIST_APPEND_TARGET_HOURS, userId.value)

    const { columns, onAction } = useTableDetailsColumns()
    const { handleScroll } = useTableScroll(
      refs,
      ROW_HEIGHT,
      ROW_NUMB,
      fetchMoreTargetHours
    )

    onAction((action: string, params: any) => {
      switch (action) {
        case 'add-target-hours':
          togglePopup('targetHoursAdd', params.id)
          break
        case 'edit-target-hour':
          togglePopup('targetHourEdit', params.id)
          break
      }
    })

    refetchTargetHours()

    watch(
      () => store.getters[EMPLOYEE_TARGET_HOURS_GETTER_ACTIVE_FILTERS],
      refetchTargetHours
    )

    return {
      ROW_HEIGHT,
      userId,
      itemsPerPage,
      popups,
      columns,
      targetHours,
      updatedBy,
      canCreateTargetHours,

      hideRecordsForEmployees,
      refetchTargetHours,
      handleScroll,
      togglePopup,
    }
  },
})
