































import { defineComponent } from '@vue/composition-api'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
export default defineComponent({
  name: 'EmployeeRecordsTableHeader',
  components: {
    BaseTooltip,
    BaseIcon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    subText: {
      type: String,
      default: '',
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
  },
})
