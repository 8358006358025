















































import { defineComponent, computed, ref } from '@vue/composition-api'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem.vue'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import BottomSheetListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem.vue'
import store from '@/store'
import i18n from '@/utils/vendors/i18n'
import { MODULE_NAME } from '../../employee.module'
import { TIME_FORMAT_TYPE } from '@/constants'

export default defineComponent({
  name: 'EmployeeRecordsSwitchTime',
  components: {
    BaseButton,
    BaseIcon,
    BaseDropdown,
    BaseDropdownTextItem,
    BottomSheet,
    BottomSheetListItem,
  },
  setup(_props, { emit }) {
    const isBottomSheetOpen = ref(false)
    const switchTimeFormatLabel = computed(() =>
      store.state[MODULE_NAME].recordTimeFormat === TIME_FORMAT_TYPE.STANDARD
        ? i18n.t('employee.employee_details.records.switch_to_decimal_time')
        : i18n.t('employee.employee_details.records.switch_to_standard_time')
    )

    const handleSwitch = () => {
      emit('switch-time-format')
      isBottomSheetOpen.value = false
    }
    return { switchTimeFormatLabel, isBottomSheetOpen, handleSwitch }
  },
})
