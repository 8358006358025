




























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { Employee } from '../../../models/employee.model'
import InviteInfo from '../EmployeeInviteInfo.vue'
import useListScroll from './composables/use-list-scroll'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'

export default defineComponent({
  name: 'NoEmailList',
  components: {
    InviteInfo,
    BaseLoading,
  },
  props: {
    employeeList: {
      type: Array as PropType<Employee[]>,
      default: /* istanbul ignore next */ () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { refs, emit }) {
    const listLength = computed(() => props.employeeList.length)

    const { handleScroll, onScrollEnd } = useListScroll(refs, listLength)

    onScrollEnd(() => emit('scroll-end'))

    return { handleScroll }
  },
})
