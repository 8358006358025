<template>
  <div>
    <base-dropdown position="is-bottom-left">
      <template v-slot:trigger>
        <base-button
          role="button"
          :aria-label="
            $t('employee.index.header.more_actions.toggle_more_options')
          "
          data-id="employee.index.header.more_actions.toggle_more_options"
          icon-right="ellipsis"
          icon-type="is-filled"
          size="is-small"
          type="is-filled-grey"
        />
      </template>

      <div class="mb-2 text-coal-550 text-sm font-semibold">
        {{ $t('employee.index.header.more_actions.account_settings') }}
      </div>

      <base-dropdown-text-item
        data-id="employee.index.header.more_actions.profile_configuration"
      >
        <config-link @upgrade-dialog="$emit('upgrade-dialog')" />
      </base-dropdown-text-item>
    </base-dropdown>
  </div>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem.vue'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import ConfigLink from './EmployeeHeaderActionsConfigLink.vue'

export default {
  name: 'EmployeeHeaderActionsDropdown',
  components: {
    BaseDropdown,
    BaseDropdownTextItem,
    BaseButton,
    ConfigLink,
  },
}
</script>
