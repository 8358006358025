




































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge.vue'
import ContractSettingsEdit from './EmployeeContractSettingsEdit.vue'
import useUserId from '../../pages/composables/use-user-id'
import { formatDate } from '@/utils/date-time.utils'
import type { ContractSettings } from '../../models/employee.model'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import { EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS } from '../../store/actions/employee.actions.names'

export default defineComponent({
  name: 'EmployeeContractSettings',
  components: {
    BaseButton,
    BaseBadge,
    ContractSettingsEdit,
  },
  setup() {
    const currency = computed(() => (i18n.locale === 'ch' ? 'CHF' : '€'))
    const { userId } = useUserId()
    const isOpen = ref(false)
    const isAdmin = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )
    const contractSettings = ref<ContractSettings | null>(null)

    const contractSettingList = computed(() => {
      if (!contractSettings.value) return []

      return [
        {
          id: 'start_of_accounting',
          label: i18n.t(
            'employee.target_hours.contract_settings.start_of_accounting'
          ),
          value: formatDate(
            new Date(contractSettings.value?.start_of_accounting)
          ),
        },
        {
          id: 'initial_time_account',
          label: i18n.t(
            'employee.target_hours.contract_settings.initial_time_account'
          ),
          value: contractSettings.value?.initial_time_account,
        },
        {
          id: 'saldo_type',
          label: i18n.t('employee.target_hours.contract_settings.saldo_type'),
          value: contractSettings.value?.saldo_type,
        },
        {
          id: 'salary',
          label: i18n.t('employee.target_hours.contract_settings.salary'),
          value:
            contractSettings.value?.salary?.toFixed(1) + ` ${currency.value}`,
        },
        {
          id: 'calc_holiday_options',
          label: i18n.t(
            'employee.target_hours.contract_settings.calc_holiday_options'
          ),
          value: contractSettings.value?.calc_holiday_options,
        },
        {
          id: 'hours_on_vacation_day',
          label: i18n.t(
            'employee.target_hours.contract_settings.hours_on_vacation_day'
          ),
          value: contractSettings.value?.hours_on_vacation_day,
        },
      ]
    })

    const fetchContractSettings = () => {
      store
        .dispatch(EMPLOYEE_ACTION_GET_CONTRACT_SETTINGS, userId.value)
        .then((response) => {
          contractSettings.value = response
        })
    }

    fetchContractSettings()

    watch(() => i18n.locale, fetchContractSettings, { deep: true })

    return {
      userId,
      isOpen,
      isAdmin,
      currency,
      contractSettingList,
      fetchContractSettings,
    }
  },
})
