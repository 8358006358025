import { computed, ComputedRef } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import useDeviceSize from '@/utils/composables/use-ui-size'
import { MODULE_NAME } from '../../employee.module'
import {
  EMPLOYEE_GETTER_CUSTOM_COLUMNS,
  EMPLOYEE_GETTER_LIMITED_COLUMNS,
} from '../../store/getters/employee.getters.names'
import {
  Employee,
  TimeTrackingMethod,
  CustomField,
  CUSTOM_FIELD_TYPE,
} from '../../models/employee.model'
import type { BaseTableColumn } from '@/app/util-modules/ui/table/BaseTable.types'
import {
  formatCustomField,
  addValueToSingleCustomField,
} from '../../utils/employee-data-section.utils'
import { calculateEmployeeOvertime } from '@/app/modules/employee/utils/employee.utils'

import TableHeaderCell from '@/app/util-modules/ui/table/BaseTableHeaderCell.vue'
import TableRowCellName from '../../components/employee-list/table-row/TableRowCellName.vue'
import EmployeeRoles from '../../components/EmployeeRoles.vue'
import EmployeeTooltipBadge from '../../components/EmployeeTooltipBadge.vue'
import TableRowCellStatus from '../../components/employee-list/table-row/TableRowCellStatus.vue'
import TableRowCellActions from '../../components/employee-list/table-row/TableRowCellActions.vue'

interface AreaMeta {
  total_count: number
}

interface TagMeta {
  total_count: number
}

interface ActionHandler {
  handler: (actionName: string, params?: any) => void // eslint-disable-line no-unused-vars
}

export interface TrackingMethodsObject {
  [key: string]: TimeTrackingMethod
}

interface CustomColumn {
  id: string
  label: string
  unique_key: string
}

export default function useTableColumns() {
  const { $isSmallDevice } = useDeviceSize()
  const action: ActionHandler = {
    handler: () => {},
  }

  const areaMeta = computed(() => store.state.area.meta)
  const tagMeta = computed(() => store.state.tag.meta)
  const customFields = computed(
    () => store.state[MODULE_NAME].records[CUSTOM_FIELD_TYPE]
  )

  const columns = getColumns(
    $isSmallDevice,
    areaMeta,
    tagMeta,
    customFields,
    action
  )

  return {
    columns,
    onAction: (callback: ActionHandler['handler']) =>
      (action.handler = callback),
  }
}

function getColumns(
  $isSmallDevice: ComputedRef<boolean>,
  areaMeta: ComputedRef<AreaMeta>,
  tagMeta: ComputedRef<TagMeta>,
  customFields: ComputedRef<CustomField[]>,
  action: ActionHandler
): ComputedRef<BaseTableColumn<Employee>[]> {
  const colStates = computed(
    () => store.getters[EMPLOYEE_GETTER_LIMITED_COLUMNS].value || {}
  )

  const customColumns = computed(
    () => store.getters[EMPLOYEE_GETTER_CUSTOM_COLUMNS]
  )

  return computed(() => {
    const cols = [
      nameColumn(action),
      Boolean(colStates.value.email) && emailColumn(),
      Boolean(colStates.value.workspace) && workspaceColumn(),
      Boolean(colStates.value.areas) && areasColumn(areaMeta),
      Boolean(colStates.value.tags) && tagsColumn(tagMeta),
      Boolean(colStates.value.roles) && rolesColumn(),
      Boolean(colStates.value.status) && statusColumn(),
      workingHoursColumn(),
      vacationSummaryColumn(),
    ]

    for (const col of customColumns.value) {
      cols.push(makeCustomColumn(col, customFields))
    }

    cols.push(actionsColumn(action))

    const visibleColumns = cols.filter((c) => {
      if (typeof c === 'boolean') {
        return false
      }
      return $isSmallDevice.value ? c.smallDevice : true
    })

    const columnWidth = `${100 / visibleColumns.length}%`

    return visibleColumns.map((c) => {
      if (typeof c !== 'boolean') {
        c.width = $isSmallDevice.value && c.smallDevice ? '33%' : columnWidth
      }
      return c
    }) as BaseTableColumn<Employee>[]
  })
}

function makeCustomColumn(
  column: CustomColumn,
  customFields: ComputedRef<CustomField[]>
): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      element: TableHeaderCell,
      class: 'md:pr-4 uppercase font-semibold md:font-sm',
      props: {
        value: () => column.label,
        dataIdAttr: `employee.table.${column.unique_key}`,
        truncateValue: true,
      },
    },
    cell: {
      class: 'truncate pr-2 md:pr-4 font-medium md:text-base text-lg',
      props: {
        value: (row: Employee) => {
          let customFieldWithValue
          const customField = customFields.value.find(
            (customField) => customField.id === column.id
          )
          if (customField)
            customFieldWithValue = addValueToSingleCustomField(customField, row)

          return formatCustomField(customFieldWithValue)
        },
      },
    },
  }
}

function nameColumn(action: ActionHandler): BaseTableColumn<Employee> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCell,
      class: 'md:pr-4 uppercase font-semibold md:font-sm',
      props: {
        value: () => i18n.t('employee.index.name'),
        sorting: () => store.state[MODULE_NAME].sorting.username,
        dataIdAttr: 'employee.table.name',
      },
      events: {
        sort: (direction: number) =>
          action.handler('sort', { field: 'username', direction }),
      },
    },
    cell: {
      element: TableRowCellName,
      class: 'truncate pr-2 md:pr-4 font-bold md:text-base text-lg',
      props: {
        value: (row: Employee) => row,
      },
    },
  }
}

function emailColumn(): BaseTableColumn<Employee> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCell,
      class: 'md:pr-4 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.email'),
        dataIdAttr: 'employee.table.email',
      },
    },
    cell: {
      class: 'truncate pr-2 md:pr-4 font-medium md:text-base text-lg',
      props: {
        value: (row: Employee) => row.email || '--',
      },
    },
  }
}

function workspaceColumn(): BaseTableColumn<Employee> {
  return {
    smallDevice: true,
    header: {
      element: TableHeaderCell,
      class: 'md:pr-4 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.workspaces'),
        dataIdAttr: 'employee.table.workspace',
      },
    },
    cell: {
      element: EmployeeTooltipBadge,
      class: 'pr-2 font-medium text-lg md:pr-4 md:text-base',
      props: {
        value: (row: Employee) => row.workspaces,
        tableView: () => true,
      },
    },
  }
}

function areasColumn(
  areaMeta: ComputedRef<AreaMeta>
): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      class: 'md:pr-16 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.areas'),
        dataIdAttr: 'employee.table.areas.',
      },
    },
    cell: {
      element: EmployeeTooltipBadge,
      class: 'pr-2 text-lg font-medium md:pr-4 md:text-base',
      props: {
        value: (row: Employee) => row.areas,
        totalCount: () => areaMeta.value.total_count,
        tableView: () => true,
      },
    },
  }
}

function tagsColumn(tagMeta: ComputedRef<TagMeta>): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      element: TableHeaderCell,
      class: 'md:pr-4 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.tags'),
        dataIdAttr: 'employee.table.tags',
      },
    },
    cell: {
      element: EmployeeTooltipBadge,
      class: 'pr-2 font-medium text-lg md:pr-4 md:text-base',
      props: {
        value: (row: Employee) => row.tags,
        totalCount: () => tagMeta.value.total_count,
        tableView: () => true,
      },
    },
  }
}

function rolesColumn(): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      class: 'md:pr-4 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.roles'),
        dataIdAttr: 'employee.table.roles',
      },
    },
    cell: {
      element: EmployeeRoles,
      class: 'md:pr-4 ',
      props: {
        value: /* istanbul ignore next */ (row: Employee) => row.roles,
        tableView: () => true,
      },
    },
  }
}

function workingHoursColumn(): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      class: 'md:pr-4 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.current_balance'),
        dataIdAttr: 'employee.table.current_balance',
      },
    },
    cell: {
      class: 'truncate md:pr-4',
      props: {
        value: (row: Employee) =>
          calculateEmployeeOvertime(row.total_overtime_in_sec),
      },
    },
  }
}

function vacationSummaryColumn(): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      class: 'md:pr-4 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.vacation_summary'),
        dataIdAttr: 'employee.table.vacation_summary',
      },
    },
    cell: {
      class: 'truncate md:pr-4',
      props: {
        value: (row: Employee) =>
          row.hour_holidays
            ? `${row.remaining_vacation_days_this_year?.toFixed(2)} h`
            : `${row.remaining_vacation_days_this_year?.toFixed(2)} d`,
      },
    },
  }
}

function statusColumn(): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      class: 'md:pr-4 uppercase font-semibold md:font-sm text-coal-550',
      props: {
        value: () => i18n.t('employee.index.status'),
        dataIdAttr: 'employee.table.status',
      },
    },
    cell: {
      element: TableRowCellStatus,
      props: {
        value: (row: Employee) => row.status,
      },
    },
  }
}

function actionsColumn(action: ActionHandler): BaseTableColumn<Employee> {
  return {
    smallDevice: false,
    header: {
      class:
        'uppercase font-semibold md:font-sm text-coal-550 text-right justify-end',
      props: {
        value: () => i18n.t('employee.index.actions'),
        dataIdAttr: 'employee.table.actions',
      },
    },
    cell: {
      element: TableRowCellActions,
      props: {
        canUpdate: (row: Employee) => row.permissions?.update,
        userId: (row: Employee) => row.id,
      },
      events: {
        'edit-action-click': (row: Employee) =>
          action.handler('edit', { id: row.id }),
      },
    },
  }
}

export const TABLE_COLUMNS = [
  {
    unique_key: 'email',
    label: () => i18n.t('employee.index.email'),
  },
  {
    unique_key: 'workspace',
    label: () => i18n.t('employee.index.workspaces'),
  },
  {
    unique_key: 'areas',
    label: () => i18n.t('employee.index.areas'),
  },
  {
    unique_key: 'tags',
    label: () => i18n.t('employee.index.tags'),
  },
  {
    unique_key: 'roles',
    label: () => i18n.t('employee.index.roles'),
  },
  {
    unique_key: 'status',
    label: () => i18n.t('employee.index.status'),
  },
]
