












































import { defineComponent, toRefs, PropType } from '@vue/composition-api'
import { getWeekdays, objectHasOwnProperty } from '../employee.utils'
import { WeeklyTargetHours } from '../../models/employee.model'
import BaseField from '@/app/util-modules/ui/field/BaseField.vue'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'
import HelpText from './EmployeeTargetHoursHelpText.vue'
import TargetHoursDay from './EmployeeTargetHoursDay.vue'

export default defineComponent({
  name: 'EmployeeTargetHoursMonthly',
  components: {
    BaseField,
    BaseInputNumber,
    HelpText,
    TargetHoursDay,
  },
  props: {
    totalHours: {
      type: Number,
      default: 0,
    },
    payload: {
      type: Object as PropType<WeeklyTargetHours>,
      required: true,
    },
    getWeekdayBool: {
      type: Function as PropType<(day: string) => string>,
      required: true,
    },
    isFreeWeekday: {
      type: Function as PropType<(day: string) => boolean>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { payload } = toRefs(props)
    // eslint-disable-next-line vue/no-setup-props-destructure
    const getWeekdayBool = props.getWeekdayBool

    const handleDayChange = (
      weekdayShortname: string,
      { freeday }: { freeday: boolean }
    ) => {
      const weekdayBool = getWeekdayBool(weekdayShortname)

      if (objectHasOwnProperty(payload.value, weekdayBool)) {
        payload.value[weekdayBool] = !freeday
      }
    }

    const handleTotalHoursChange = (total: number) => {
      if (isNaN(total) || !total) {
        total = 0
      }
      // base-input emits string
      if (typeof total === 'string') {
        total = parseFloat(total)
      }
      emit('total-hours-change', total)
    }

    return {
      WEEK_DAYS: getWeekdays(),
      handleDayChange,
      handleTotalHoursChange,
    }
  },
})
