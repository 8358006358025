import { Route } from 'vue-router'
import store from '@/store'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
} from '@/app/core/user/store/getters/user.getters.names'

type NextFunction = (route?: any) => void

export const checkUserRecordPermission =
  (MODULE_NAME: string) => (_to: Route, _from: Route, next: NextFunction) => {
    const isAdmin = store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]

    const hideEvaluations =
      store.getters[USER_GETTER_FEATURE_PERMISSIONS](
        'General'
      ).hide_records_for_employees

    if (isAdmin || !hideEvaluations) return next()
    else return next({ name: `${MODULE_NAME}.details` })
  }
